import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  UncontrolledAlert,
  Card,
  CardHeader,
  CardBody,
  Spinner,
  Table,
} from "reactstrap";
import {
  magicnum,
  ProductSambarPriceUpdateReq,
  ProductSambarPriceAddReq,
  ProductSambarPriceListReq,
  ProductSambarPriceDeleteOneReq,
} from "../../../services/Request";
import {
  ProductSambarPriceUpdate,
  ProductSambarPriceAdd,
  ProductSambarPriceList,
  ProductSambarPriceDeleteOne,
} from "../../../services/main";
import { withTranslation } from "react-i18next";
import { isNullOrEmpty, moneyFormat } from "../../../components/Utils/Utils.js";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
class SambarUneNemeh extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      prod: this.props.prod,
      material_id: "",
      hemjee_id: "",
      price: "",
      torol: "",
      validate: [],
      loading: false,
      data: [],
      addOrUpdate: true,
    };
    this.onSuccess = this.onSuccess.bind(this);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    const { prod } = this.state;
    if (isNullOrEmpty(prod)) {
      this.getPrice();
    } else {
      this.setState(
        {
          torol: prod.torol
        },
        function () {
          this.getPrice();
        }
      );
    }
  }

  getPrice = () => {
    const { hemjee_id, material_id, torol } = this.state;
    if (!this.state.loading) {
      this.setState({ loading: true });
    }
    var req = ProductSambarPriceListReq;
    req.filter.hemjee_id = hemjee_id;
    req.filter.material_id = material_id;
    req.filter.torol = torol;
    ProductSambarPriceList(req, this.onListSuccess, this.onFailed, "POST");
  };

  onListSuccess = (response) => {
    this.setState({
      loading: false,
      data: response.data,
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, function () {
      if (name == "torol" || name == "hemjee_id" || name == "material_id") {
        this.getPrice();
      }
    });
  };

  save() {
    const { material_id, hemjee_id, price, torol } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      this.setState({ isSave: true });
      var req = ProductSambarPriceAddReq;
      req.torol = torol;
      req.material_id = parseInt(material_id);
      req.hemjee_id = parseInt(hemjee_id);
      req.price = price;
      ProductSambarPriceAdd(req, this.onSuccess, this.onFailed, "POST");
    }
  }

  onSuccess = (response) => {
    const { t } = this.state;
    this.setState(
      {
        loading: false,
        isSave: false,
        hemjee_id: "",
        material_id: "",
        torol: "",
      },
      function () {
        this.getPrice();
      }
    );
    return toast.success(t("common:SuccessSave"));
  };

  Update = () => {
    const { price, id, hemjee_id, material_id, torol } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      this.setState({ isSave: true });
      var req = ProductSambarPriceUpdateReq;
      req.torol = torol;
      req.hemjee_id = parseInt(hemjee_id);
      req.material_id = parseInt(material_id);
      req.price = price;
      req.id = parseInt(id);
      ProductSambarPriceUpdate(req, this.onUpdatSuccess, this.onFailed, "PUT");
    }
  };

  onUpdatSuccess = (response) => {
    const { t } = this.state;
    this.setState(
      {
        loading: false,
        isSave: false,
        torol: "",
        material_id: "",
        hemjee_id: "",
        price: "",
      },
      function () {
        this.getPrice();
      }
    );
    return toast.success(t("common:SuccessEdit"));
  };

  onFailed = (error) => {
    this.setState({
      loading: false,
      apierror: true,
      Industryloading: false,
      apierrormsg: error,
      isSave: false,
    });
  };

  setValidate = (id, e) => {
    var val = "";
    e.target === undefined ? (val = e) : (val = e.target.value);
    const { validate } = this.state;
    if (val !== "") {
      validate[id] = false;
    } else {
      validate[id] = true;
    }
    this.setState({ validate });
  };

  validate = () => {
    var res = { retType: 0 };
    const { validate, hemjee_id, material_id, price, torol } = this.state;
    if (hemjee_id == "") {
      res.retType = 1;
      validate["hemjee_id"] = true;
    }
    if (material_id == "") {
      res.retType = 1;
      validate["material_id"] = true;
    }
    if (price == "") {
      res.retType = 1;
      validate["price"] = true;
    }
    if (torol == "") {
      res.retType = 1;
      validate["torol"] = true;
    }
    this.setState({ validate });
    return res;
  };

  setUpdateData = (row) => {
    this.setState({
      material_id: row.material_id,
      hemjee_id: row.hemjee_id,
      price: row.price,
      torol: row.torol,
      id: row.id,
      addOrUpdate: false,
    });
  };

  actionRowFormat = (row) => {
    const { t } = this.state;
    let edit = (
      <div className="text-center" style={{ marginLeft: -12 }}>
        <Button
          onClick={() => {
            this.setUpdateData(row);
          }}
          className="button edit-button"
          title={t("common:edit")}
          color="success"
          outline
        >
          <i className="fa fa-edit" />
        </Button>
        <Button
          className="button delete-button"
          onClick={() => {
            this.submit(row, "deleteMain");
          }}
          title={t("common:delete")}
          color="success"
          outline
        >
          <i className="fa fa-trash" />
        </Button>
      </div>
    );
    return edit;
  };

  submit = (cell, type) => {
    const { t } = this.state;
    var title =
      t(`common:magicnum.ProductSambarHemjee.${cell.hemjee_id}`) +
      " " +
      t("product.sambar.price.delete");
    var message = t("common:confirm");

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-confirm-ui">
            <h1>{title.substr(0, 30)}...</h1>
            <p>{message}</p>
            <Button outline onClick={onClose}>
              {t("common:no")}
            </Button>
            <Button
              color="danger"
              onClick={() => {
                type == "deleteMain" ? this.delete(cell) : this.Confirm(cell);
                onClose();
              }}
            >
              {t("common:yes")}
            </Button>
          </div>
        );
      },
    });
  };

  delete = (row) => {
    var req = ProductSambarPriceDeleteOneReq;
    req.id = row.id;
    ProductSambarPriceDeleteOne(
      req,
      this.onDeleteSuccess,
      this.onFailed,
      "POST"
    ); //serverluu huselt ilgeej bn  !!!!!
  };

  onDeleteSuccess = () => {
    const { t } = this.state;
    this.getPrice();
    return toast.error(t("common:SuccessDelete"));
  };

  render() {
    const {
      t,
      validate,
      apierror,
      apierrormsg,
      torol,
      material_id,
      hemjee_id,
      price,
      loading,
      data,
      addOrUpdate,
      prod,
    } = this.state;
    return (
      <div className="animated fadeIn">
        {apierror && (
          <UncontrolledAlert color="danger" className="alertDiv">
            {apierrormsg}
          </UncontrolledAlert>
        )}
        <div>
          {!isNullOrEmpty(prod) ? null : (
            <Card>
              <CardHeader>{t("product.sambar.price.Title")}</CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>{t("product.sambar.torol")}</Label>
                      <Input
                        type="select"
                        name="torol"
                        value={torol}
                        onChange={(event) => {
                          this.handleChange(event);
                          this.setValidate("torol", event);
                        }}
                        invalid={validate["torol"]}
                      >
                        <option value="">{t("common:choose")}</option>
                        {magicnum.ProductSambarTorol.map((item, index) => {
                          return (
                            <option value={item} key={index}>
                              {t(`common:magicnum.ProductSambarTorol.${item}`)}
                            </option>
                          );
                        })}
                      </Input>
                      <FormFeedback>{t("common:SelectValid")}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>{t("product.sambar.price.hemjee_id")}</Label>
                      <Input
                        type="select"
                        name="hemjee_id"
                        value={hemjee_id}
                        onChange={(event) => {
                          this.handleChange(event);
                          this.setValidate("hemjee_id", event);
                        }}
                        invalid={validate["hemjee_id"]}
                      >
                        <option value="">{t("common:choose")}</option>
                        {magicnum.ProductSambarHemjee.map((item, index) => {
                          return (
                            <option value={item} key={index}>
                              {t(`common:magicnum.ProductSambarHemjee.${item}`)}
                            </option>
                          );
                        })}
                      </Input>
                      <FormFeedback>{t("common:SelectValid")}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>{t("product.sambar.price.material_id")}</Label>
                      <Input
                        type="select"
                        name="material_id"
                        value={material_id}
                        onChange={(event) => {
                          this.handleChange(event);
                          this.setValidate("material_id", event);
                        }}
                        invalid={validate["material_id"]}
                      >
                        <option value="">{t("common:choose")}</option>
                        {magicnum.ProductSambarMaterial.map((item, index) => {
                          return (
                            <option value={item} key={index}>
                              {t(
                                `common:magicnum.ProductSambarMaterial.${item}`
                              )}
                            </option>
                          );
                        })}
                      </Input>
                      <FormFeedback>{t("common:SelectValid")}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>{t(`product.sambar.price.price`)}</Label>
                      <Input
                        type="text"
                        name="price"
                        value={price}
                        onChange={(event) => {
                          this.handleChange(event);
                          this.setValidate("price", event);
                        }}
                        invalid={validate["price"]}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup
                      className="saveBtn"
                      style={{ marginTop: 25, width: "100%" }}
                    >
                      <Button
                        color="primary"
                        onClick={!addOrUpdate ? this.Update : this.save}
                        disabled={this.state.isSave}
                      >
                        {t("common:save")}
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )}
          <div style={{overflowY:"scroll", height:"70vh"}}>
          <Card>
            <CardHeader>{t("common:list")}</CardHeader>
            <CardBody>
              <div className="table-responsive">
                <div className="scroll">
                  <Table>
                    <thead>
                      <tr>
                        <th>№</th>
                        <th>{t("product.sambar.torol")}</th>
                        <th>{t("product.sambar.price.hemjee_id")}</th>
                        <th>{t("product.sambar.price.material_id")}</th>
                        <th>{t(`product.sambar.price.price`)}</th>
                        {!isNullOrEmpty(prod) ? null : <th></th>}
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan="7">
                            <Spinner />
                          </td>
                        </tr>
                      ) : (
                        data.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {t(
                                  `common:magicnum.ProductSambarTorol.${item.torol}`
                                )}
                              </td>
                              <td>
                                {t(
                                  `common:magicnum.ProductSambarHemjee.${item.hemjee_id}`
                                )}
                              </td>
                              <td>
                                {t(
                                  `common:magicnum.ProductSambarMaterial.${item.material_id}`
                                )}
                              </td>
                              <td>{moneyFormat(item.price, "MNT")}</td>
                              {!isNullOrEmpty(prod) ? null : (
                                <td>{this.actionRowFormat(item)}</td>
                              )}
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </CardBody>
          </Card>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation("main")(SambarUneNemeh);
