import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {
  CranOrderDelete,
  CranOrderList,
  setPageTitle,
  CDN_URL,
  CranOrderChangeStatus,
  listEmployee,
} from "../../services/main";
import {
  CranOrderListReq,
  BaseRequest,
  magicnum,
  CranOrderChangeStatusReq,
  listEmployeeReq,
} from "../../services/Request";
import {
  Card,
  CardHeader,
  CardBody,
  UncontrolledAlert,
  Button,
  Label,
  Input,
  FormGroup,
  Row,
  Col,
} from "reactstrap";
import CustomBootsrapTable from "../../components/CustomBootsrapTable/CustomBootsrapTable";
import $ from "jquery";
import { toast } from "react-toastify";
import {
  dateFormat,
  dateTimeFormat,
  getUser,
  isNullOrEmpty,
  LastAndFirstName,
  moneyFormat,
} from "../../components/Utils/Utils";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
class CranOrderListFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sizePerPage: 25, //neg huudsand haruulah too
      PID: 1,
      PTotal: 0,
      loading: false,
      data: [],
      t: this.props.t,
      id: "",
      action_type: "",
      apierrormsg: "",
      apierror: false,
      status:
        this.props.match.params.status == "all"
          ? ""
          : this.props.match.params.status,
      customer_id: "",
    };
  }

  componentDidMount() {
    setPageTitle(this.props.t("Order.Title"));
    this.getList();
  }

  getList = () => {
    if (!this.state.loading) {
      this.setState({ loading: true });
    }

    const { PID, sizePerPage, status, customer_id } = this.state;
    var req = CranOrderListReq;
    req.pid = PID;
    req.pSize = sizePerPage;
    req.filter.status = status;
    req.filter.customer_id = customer_id;
    CranOrderList(req, this.onSuccess, this.onFailed, "POST");
  };

  onSuccess = (response) => {
    this.setState({
      loading: false,
      PTotal: response.pagination.ptotal,
      data: response.data,
    });
  };

  onFailed = (error) => {
    this.setState({ loading: false, apierror: true, apierrormsg: error });
  };

  onChangePage = (pid) => {
    if (pid >= 1) {
      this.setState({ PID: pid, loading: true }, function () {
        this.getList();
      });
    }
  };

  filterToggle() {
    $("#filterTogle").toggle();
    var otherHeight = 160;
    var filterHeight = 0,
      othersapec = 65;
    if ($(".grid-filter").height() !== undefined) {
      filterHeight = $(".grid-filter").height() + othersapec;
    }
    $(".scroll").height(
      $(window).height() -
        $(".app-header").height() -
        otherHeight -
        filterHeight
    );
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  search = () => {
    this.setState({ PID: 1 }, function () {
      this.getList();
    });
  };

  tolovRowFormat = (cell, row) => {
    const { t } = this.state;
    var cl = "";
    var cn = "";
    if (cell == 1) {
      cl = "btn-secondary";
      cn = t("Order.cran.status.1");
    } else if (cell == 2) {
      cl = "btn-info";
      cn = t("Order.cran.status.2");
    }

    let edit = (
      <div className="text-center" style={{ marginLeft: -6 }}>
        <Button
          style={{ paddingLeft: 5, paddingRight: 5, borderRadius: 5 }}
          className={`button delete-button ${cl}`}
          onClick={() => {
            this.submit(row, "confirm");
          }}
        >
          {cn}
        </Button>
      </div>
    );
    return edit;
  };

  actionRowFormat = (cell, row) => {
    const { t } = this.state;
    let edit = (
      <div className="text-center" style={{ marginLeft: -12 }}>
        <Button
          className="button delete-button"
          onClick={() => {
            this.submit(row, "deleteMain");
          }}
          title={t("common:delete")}
          color="success"
          outline
        >
          <i className="fa fa-trash" />
        </Button>
      </div>
    );
    return row.status == 1 ? edit : null;
  };

  priceRowFormat = (cell, row) => {
    let edit = <div className="text-center">{moneyFormat(cell, "MNT")}</div>;
    return edit;
  };

  submit = (row, type) => {
    const { t, dataEmployee } = this.state;
    var title = row.id + " " + t("common:delete");
    var message = t("common:confirmdelete");
    if (type == "confirm") {
      var title = row.id + " " + t("main:Order.confirm");
      var message = t("common:confirm");
    } else if (type == "delete") {
      var title = row.id + " " + t("main:Order.delete");
      var message = t("common:confirm");
    }

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-confirm-ui">
            <h1>{title.substr(0, 30)}...</h1>
            <p>{message}</p>
            {type == "confirm" ? (
              <>
                <Input
                  type="select"
                  name="action_type"
                  onChange={(event) => {
                    this.handleChange(event);
                  }}
                  style={{ marginTop: 20, marginBottom: 40 }}
                >
                  <option value="">{t("common:choose")}</option>
                  <option value="1">{t("Order.cran.status.1")}</option>
                  <option value="2">{t("Order.cran.status.2")}</option>
                </Input>
              </>
            ) : null}

            <Button outline onClick={onClose}>
              {t("common:no")}
            </Button>
            <Button
              color="danger"
              onClick={() => {
                type == "deleteMain" ? this.delete(row) : this.Confirm(row);
                onClose();
              }}
            >
              {t("common:yes")}
            </Button>
          </div>
        );
      },
    });
  };

  delete = (row) => {
    CranOrderDelete(
      row.id,
      BaseRequest,
      this.onDeleteSuccess,
      this.onFailed,
      "DELETE"
    ); //serverluu huselt ilgeej bn  !!!!!
  };

  onDeleteSuccess = (response) => {
    const { t } = this.state;
    this.getList();
    return toast.error(t("common:SuccessDelete"));
  };

  Confirm = (row, status) => {
    const { t, action_type } = this.state;
    if (action_type == "") {
      return toast.error(t("main:Order.SelectActionType"));
    } else {
      var req = CranOrderChangeStatusReq;
      req.Order.status = action_type;
      req.Order.id = row.id;
      CranOrderChangeStatus(req, this.onConfirmSuccess, this.onFailed, "PUT");
    }
  };

  onConfirmSuccess = (response) => {
    const { t } = this.state;
    this.getList();
    return toast.success(t("main:Order.SuccessChangeStatus"));
  };

  render() {
    const { t, apierror, apierrormsg, status, id } = this.state;
    var table = {
      sizePerPage: this.state.sizePerPage,
      headerKeyColumn: {
        title: "id",
        dataField: "id",
        dataSort: true,
        dataFormat: this.defaultRowFormat,
        hidden: true,
        filter: {
          type: "TextFilter",
          placeholder: "...",
        },
      },
      headerOtherColumn: [
        
        {
          title: t("Customer.rd"),
          dataField: "rd",
          dataSort: false,
          dataFormat: this.defaultRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("Customer.name"),
          dataField: "name",
          dataSort: false,
          dataFormat: this.defaultRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("Order.cran.type"),
          dataField: "type",
          dataSort: false,
          dataFormat: this.defaultRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("Order.cran.daats"),
          dataField: "daats",
          dataSort: false,
          dataFormat: this.defaultRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("Order.cran.alslal"),
          dataField: "alslal",
          dataSort: false,
          dataFormat: this.defaultRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("Order.cran.ondor"),
          dataField: "ondor",
          dataSort: false,
          dataFormat: this.defaultRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("Order.cran.orchin"),
          dataField: "orchin",
          dataSort: false,
          dataFormat: this.defaultRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("main:Order.tolov"),
          dataField: "status",
          dataSort: false,
          dataFormat: this.tolovRowFormat,
          hidden: false,
          width: "210px",
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: "",
          dataField: "id",
          dataSort: false,
          dataFormat: this.actionRowFormat,
          hidden: false,
          width: "60px",
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
      ],
    };
    return (
      <div className="animated fadeIn">
        <Card className="grid-filter">
          <CardHeader>
            <span className="filterToggle" onClick={() => this.filterToggle()}>
              <i className="fa fa-filter" />
              {t("common:filter")}
            </span>
            <div className="float-right">
              <span className="btn btn-sm btn-primary" onClick={this.search}>
                {t("common:search")}
              </span>
            </div>
          </CardHeader>
          <CardBody id="filterTogle" style={{ display: "none" }}>
            <div className="filter">
              <Row>                
                <Col>
                  <FormGroup>
                    <Label>{t("Order.tolov")}</Label>
                    <Input
                      type="select"
                      name="status"
                      value={status}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    >
                      <option value="">{t("common:choose")}</option>
                      <option value="1">{t("Order.cran.status.1")}</option>
                      <option value="2">{t("Order.cran.status.2")}</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>

        <Card className="nopadding">
          <CardHeader>{t("main:Order.cran.title")}</CardHeader>
          <CardBody>
            <div>
              {apierror && (
                <UncontrolledAlert color="danger">
                  {apierrormsg}
                </UncontrolledAlert>
              )}
              <div>
                <CustomBootsrapTable
                  data={this.state.data}
                  table={table}
                  PTotal={this.state.PTotal}
                  pageChange={this.onChangePage}
                  PID={this.state.PID}
                  loading={this.state.loading}
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withTranslation("main")(CranOrderListFunc);
