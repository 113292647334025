import React, { Component } from "react";
import {
  Nav,
  NavItem,
  NavbarToggler,
  Badge,
  DropdownItem,
  Progress,
  DropdownToggle,
  Dropdown,
  DropdownMenu,
} from "reactstrap";
import { Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { getRealData, isNullOrEmpty } from "../Utils/Utils";
//import { DashboardNotifiWorkProjectReq } from "../../services/Request";
import { socket } from "../../services/main";
import { ToastContainer, toast } from "react-toastify";
import "./Header.scss";
import icon from "./icon.png";
import sound from "./sound.mp3";
import Chat from "../Chat/Chat";
import { Link } from "react-router-dom";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      notification: [],
      dropdownOpen: false,
      loading: false,
    };
    this.audio = new Audio(sound);
    this.Logout = this.Logout.bind(this);
  }

  componentDidMount() {
    var ua = navigator.userAgent;
    var M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
    if (M[1] != "Safari") {
      if (!window.Notification) {
        console.log("This browser does not support desktop notification");
      } else {
        Notification.requestPermission().then(function (permission) {
          if (permission === "denied") {
            console.log("You Have Denied Notification!");
          } else if (permission === "granted") {
            console.log("You Have Granted notification.");
          }
        });
      }

      socket.on("notification", (msg) => {
        if (msg.type !== "disconnect") {
          this.notifyMe(msg);
          this.audio.play();
        }
        if (msg.type == "project" && msg.actiontype == "changestatus") {
          this.getNotifi();
        }

        socket.on("notificationDisconnect", (msg) => {
          socket.off("notification");
          socket.removeListener("notification");
        });
      });

      if (!isNullOrEmpty(localStorage.getItem("EmployeeID"))) {
        this.getNotifi();
      }
    }
  }

  sidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle("sidebar-hidden");
  }

  sidebarMinimize(e) {
    e.preventDefault();
    document.body.classList.toggle("sidebar-minimized");
  }

  mobileSidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle("sidebar-mobile-show");
  }

  asideToggle(e) {
    e.preventDefault();
    document.body.classList.toggle("aside-menu-hidden");
  }

  Logout() {
    localStorage.clear();
    this.setState({
      redirect: true,
    });
  }

  renderRedirect() {
    if (this.state.redirect) {
      return <Redirect to="/login" />;
    }
  }

  setClass() {
    var x = document.getElementById("myLinks");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  }

  setSidebarClass() {
    var x = document.getElementById("header");
    if (x.style.marginLeft === "0px") {
      x.style.marginLeft = "-200px";
    } else {
      x.style.marginLeft = "0px";
    }
  }

  notifyMe(msg) {
    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      var notification = new Notification(msg.title, {
        icon: icon,
        silent: false,
        body: msg.body,
      });
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(function (permission) {
        if (permission === "granted") {
          var notification = new Notification(msg.title, {
            icon: icon,
            silent: false,
            body: msg.body,
          });
        }
      });
    }
  }

  dropNotif() {
    const { notification } = this.state;
    const itemsCount = notification.length;
    return (
      <Dropdown
        nav
        className="d-md-down-none"
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
      >
        <DropdownToggle nav>
          <i className="fa fa-bell" />
          {itemsCount ? (
            <Badge pill color="danger">
              {itemsCount}
            </Badge>
          ) : null}
        </DropdownToggle>
        {itemsCount > 0 ? (
          <DropdownMenu right style={{ width: 180 }}>
            <DropdownItem header tag="div" className="text-center">
              Таньд {itemsCount} мэдэгдэл байна
            </DropdownItem>
            {notification.map((item, index) => {
              return (
                <DropdownItem key={index}>
                  <Link to={`/project/${item.id}`}>{item.cname}</Link>
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        ) : null}
      </Dropdown>
    );
  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  getNotifi() {
    // this.setState({ loading: true });
    // var req = DashboardNotifiWorkProjectReq;
    // req.userid = localStorage.getItem("EmployeeID");
    // DashboardNotifiWorkProject(req, this.onSuccess, this.onFailed, "POST");
  }

  onSuccess = (response) => {
    const { t } = this.state;
    this.setState({ loading: false, notification: response.data });
  };

  onFailed = (err, text) => {
    this.setState({
      loading: false,
    });
  };

  render() {
    const { t, i18n } = this.props;
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
      localStorage.setItem("Lang", lng);
    };

    return (
      <header className="app-header navbar">
        <Chat t={t} />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          style={{ zIndex: 1999, top: 40 }}
        />
        {this.renderRedirect()}
        <NavbarToggler className="d-lg-none">
          <span className="navbar-toggler-icon" />
        </NavbarToggler>
        <div className="mobileMenu" onClick={this.setSidebarClass}>
          <span className="navbar-toggler-icon" />
        </div>
        {/* <NavbarBrand href="#" /> */}
        <img className="icon logo-header-minimized" src={icon} height={30} />
        <a href="/" className="logo-header">
          <img className="icon" src={icon} height={30} />
          <div className="text">
            <div className="sitename">
              <span className="tavaar">MKL</span>
              <span className="mn">.mn</span>
            </div>
          </div>
        </a>

        <div className="itemMobileName">
          <span>
            {getRealData("data2") !== ""
              ? t("common:welcomeTo") +
                " " +
                JSON.parse(getRealData("data2")).firstname
              : null}
          </span>
        </div>

        <NavbarToggler className="d-md-down-none mr-auto headerDiv">
          <div className="menuDiv">
            <span
              className="navbar-toggler-icon mmenu"
              onClick={this.sidebarToggle}
            />
            <span className="menu phone nolink">
              <span className="text nolink">{t("header.title")}</span>
            </span>
            {/* <span className="menu help"><a href="#" target="_blank"><i className="fa fa-facebook-official" /><span className="text"></span></a></span> */}
            <span className="menu help">
              <a href="#" target="_blank">
                <i className="fa fa-youtube-play" />
                <span className="text">{t("header.help")}</span>
              </a>
            </span>
          </div>
        </NavbarToggler>
        <div className="mobileSubMenu" onClick={this.setClass}>
          <span className="navbar-toggler-icon" />
        </div>
        <Nav className="ml-auto headerDiv" navbar id="myLinks">
          {this.dropNotif()}
          <NavItem className="langDiv">
            <div className="item">
              <Link to={`/userdetail`}>
                <span>
                  {getRealData("data2") !== ""
                    ? t("common:welcomeTo") +
                      " " +
                      JSON.parse(getRealData("data2")).firstname
                    : null}
                </span>
              </Link>
            </div>
            <div className="item">
              <span onClick={() => changeLanguage("mn")}>MN</span>
              <span onClick={() => changeLanguage("en")}>EN</span>
            </div>
            <div className="item">
              <span onClick={this.Logout}>
                <i className="icon-logout" /> {t("header.logout")}
              </span>
            </div>
          </NavItem>
        </Nav>
        {/* <NavbarToggler className="d-md-down-none" onClick={this.asideToggle}>
          <span className="navbar-toggler-icon" />
        </NavbarToggler>  */}
      </header>
    );
  }
}
export default withTranslation("common")(Header);
