import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import Pagination from "./Pagination";
import "./index.scss";
import $ from "jquery";
import CustomContentLoader from "../CustomContentLoader/CustomContentLoader";

class CustomBootsrapTable extends Component {
  constructor(props) {
    super(props);
    this.options = {
      sortIndicator: true,
      hideSizePerPage: false,
      paginationSize: 4,
      hidePageListOnlyOnePage: false,
      clearSearch: true,
      alwaysShowAllBtns: true,
      withFirstAndLast: true,
      sizePerPage: props.table.sizePerPage,
    };
    this.onChangePage = this.onChangePage.bind(this);
    this.trStyle = this.trStyle.bind(this);
    this.indexN = this.indexN.bind(this);
    this.state = {
      data: props.data,
      headerColumn: props.table.headerOtherColumn,
      keyColumn: props.table.headerKeyColumn,
      PTotal: props.PTotal,
    };
  }

  componentDidUpdate() {
    if ($(window).height() !== undefined) {
      var otherHeight = 160;
      var filterHeight = 0,
        othersapec = 65;
      if ($(".grid-filter").height() !== undefined) {
        filterHeight = $(".grid-filter").height() + othersapec;
      }
      $(".scroll").height(
        $(window).height() -
          $(".app-header").height() -
          otherHeight -
          filterHeight
      );
    }
  }
  getCustomFilter() {
    return <div />;
  }
  componentWillReceiveProps(nextProps) {
    if (this.state.data !== nextProps.data) {
      this.setState({ data: nextProps.data });
    }
    if (this.state.PTotal !== nextProps.PTotal) {
      this.setState({ PTotal: nextProps.PTotal });
    }
  }
  onChangePage(page) {
    this.props.pageChange(page);
  }
  trStyle() {
    return { backgroundColor: this.props.trStyle };
  }
  indexN(cell, row, enumObject, index) {
    return this.props.PID == 1 ? (
      <div>{index + 1}</div>
    ) : (
      <div>
        {this.props.table.sizePerPage * (this.props.PID - 1) + index + 1}
      </div>
    );
  }
  render() {
    var load = false;
    load = this.props.loading;
    if (load !== undefined) {
      var body = (
        <div className="customBootstrapTable">
          {" "}
          <div className="loading">
            <CustomContentLoader type="table" />
          </div>
        </div>
      );
    }
    if (!load) {
      body = (
        <div className="customBootstrapTable">
          <div className="table-responsive">
            <div className="scroll">
              <BootstrapTable
                data={this.state.data}
                version="4"
                striped
                hover
                options={this.options}
                trStyle={{ backgroundColor: this.props.trStyle }}
              >
                <TableHeaderColumn
                  dataField={this.state.keyColumn.dataField}
                  isKey
                  dataSort={this.state.keyColumn.dataSort}
                  hidden={this.state.keyColumn.hidden}
                  width={this.state.keyColumn.width}
                  dataFormat={this.state.keyColumn.dataFormat}
                  filter={
                    this.state.keyColumn.filter.type === "TextFilter"
                      ? {
                          type: this.state.keyColumn.filter.type,
                          placeholder: this.state.keyColumn.filter.placeholder,
                        }
                      : {
                          type: this.state.keyColumn.filter.type,
                          options: this.state.keyColumn.filter.options,
                        }
                  }
                >
                  {this.state.keyColumn.title}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="any"
                  width="50"
                  dataFormat={this.indexN}
                >
                  #
                </TableHeaderColumn>
                {this.state.headerColumn.map((col) => (
                  <TableHeaderColumn
                    dataField={col.dataField}
                    key={col.dataField}
                    dataSort={col.dataSort}
                    dataFormat={col.dataFormat}
                    hidden={col.hidden}
                    width={col.width}
                    filter={
                      col.filter.type === "TextFilter"
                        ? {
                            type: col.filter.type,
                            placeholder: col.filter.placeholder,
                          }
                        : col.filter.type === "CustomFilter"
                        ? {
                            type: col.filter.type,
                            getElement: this.getCustomFilter,
                          }
                        : {
                            type: col.filter.type,
                            options: col.filter.options,
                          }
                    }
                  >
                    {col.title}
                  </TableHeaderColumn>
                ))}
              </BootstrapTable>
            </div>
            {this.state.PTotal > 1 ? (
              <div className="fixed-footer">
                <Pagination
                  totalData={this.state.PTotal}
                  pageSize={this.props.table.sizePerPage}
                  onChangePage={this.onChangePage}
                  PID={this.props.PID}
                />
              </div>
            ) : null}
          </div>
        </div>
      );
    }

    return <div>{body}</div>;
  }
}

export default CustomBootsrapTable;
