import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { Container } from "reactstrap";
import Header from "../components/Header/";
import Sidebar from "../components/Sidebar/";
import Aside from "../components/Aside/";
import Logout from "../views/Login/Logout";

import PrivList from "../views/Privilege/Priv/PrivList";
import Employee from "../views/Privilege/Employee/Employee";
import PrivGroupList from "../views/Privilege/PrivGroup/PrivGroupList";
import MorePrivGroup from "../views/Privilege/PrivGroup/MorePrivGroup";
import EmployeeDetail from "../views/Privilege/Employee/EmployeeDetail";
import Action from "../views/Log/action";
import Error from "../views/Log/error";
import Dashboard from "../views/Dashboard/Dashboard";
import UserDetail from "../views/Privilege/Employee/UserDetail";
import TatlagaList from "../views/Product/TatlagaList";
import TatlagaGet from "../views/Product/TatlagaGet";
import SambarList from "../views/Product/SambarList";
import SambarGet from "../views/Product/SambarGet";
import SambarPrice from "../views/Product/Price/SambarPriceList";
import BagtsList from "../views/Bagts/BagtsList";
import BagtsGet from "../views/Bagts/BagtsGet";
import BannerList from "../views/Banner/BannerList";
import CranList from "../views/Product/CranList";
import CranGet from "../views/Product/CranGet";
import CustomerList from "../views/Customer/CustomerList";
import CustomerGet from "../views/Customer/CustomerGet";
import OrderList from "../views/Order/OrderList";
import OrderCranList from "../views/Order/OrderCranList";
import OrderGet from "../views/Order/OrderGet";
import HuudasList from "../views/Huudas/HuudasList";
import HuudasGet from "../views/Huudas/HuudasGet";
import NewsList from "../views/News/NewsList";
import NewsGet from "../views/News/NewsGet";
class Routing extends Component {
  componentDidMount() {
    var getToken = localStorage.getItem("SystemToken");
    if (getToken === null) {
      this.props.history.push("/login");
    }
  }

  render() {
    return (
      <div className="app">
        <Header />
        <div className="app-body">
          <Sidebar {...this.props} />
          <main className="main">
            {/* <Breadcrumb /> */}
            <Container fluid>
              <Switch>
                <Route exact path="/Logout" name="Logout" component={Logout} />
                <Route
                  exact
                  path="/privilege/privgrouplist"
                  name="PrivGroupList"
                  component={PrivGroupList}
                />
                <Route
                  exact
                  path="/privilege/privlist"
                  name="PrivList"
                  component={PrivList}
                />
                <Route
                  exact
                  path="/privilege/employeelist"
                  name="Employee"
                  component={Employee}
                />
                <Route
                  path="/privilege/privgroupmore/:id"
                  name="Privilege More And Relation"
                  component={MorePrivGroup}
                />
                <Route
                  path="/privilege/EmployeeDetial/:id"
                  name="Employee More And Relation"
                  component={EmployeeDetail}
                />

                <Route
                  exact
                  path="/log/success"
                  name="Action"
                  component={Action}
                />

                <Route
                  exact
                  path="/product-tatlaga"
                  name="TatlagaList"
                  component={TatlagaList}
                />
                <Route
                  path="/product-tatlaga/:id"
                  name="TatlagaGet"
                  component={TatlagaGet}
                />
                <Route
                  exact
                  path="/page"
                  name="HuudasList"
                  component={HuudasList}
                />
                <Route
                  path="/page/:id"
                  name="HuudasGet"
                  component={HuudasGet}
                />
                <Route
                  exact
                  path="/news"
                  name="NewsList"
                  component={NewsList}
                />
                <Route path="/news/:id" name="NewsGet" component={NewsGet} />
                <Route
                  exact
                  path="/orders/:status"
                  name="OrderList"
                  component={OrderList}
                />
                <Route
                  exact
                  path="/cranorders/:status"
                  name="OrderCranList"
                  component={OrderCranList}
                />
                <Route path="/order/:id" name="OrderGet" component={OrderGet} />
                <Route
                  exact
                  path="/product-cran"
                  name="CranList"
                  component={CranList}
                />
                <Route
                  path="/product-cran/:id"
                  name="CranGet"
                  component={CranGet}
                />

                <Route
                  exact
                  path="/banner"
                  name="BannerList"
                  component={BannerList}
                />
                <Route
                  exact
                  path="/bagts"
                  name="BagtsList"
                  component={BagtsList}
                />
                <Route
                  exact
                  path="/bagts/:id"
                  name="BagtsGet"
                  component={BagtsGet}
                />
                <Route
                  exact
                  path="/customer"
                  name="CustomerList"
                  component={CustomerList}
                />
                <Route
                  exact
                  path="/customer/:id"
                  name="CustomerGet"
                  component={CustomerGet}
                />
                <Route
                  exact
                  path="/product-sambar"
                  name="SambarList"
                  component={SambarList}
                />
                <Route
                  exact
                  path="/product-sambar-price"
                  name="SambarPrice"
                  component={SambarPrice}
                />
                <Route
                  path="/product-sambar/:id"
                  name="SambarGet"
                  component={SambarGet}
                />

                <Route exact path="/log/error" name="Error" component={Error} />
                <Route
                  exact
                  path="/userdetail"
                  name="UserDetail"
                  component={UserDetail}
                />
                <Route path="/" name="Dashboard" component={Dashboard} />
              </Switch>
            </Container>
          </main>
          <Aside />
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Routing;
