import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  UncontrolledAlert,
  Card,
  CardHeader,
  CardBody,
  Spinner,
  Table,
} from "reactstrap";
import {
  magicnum,
  ProductSambarMaterialHemjeeAddReq,
  ProductSambarMaterialHemjeeListReq,
  ProductSambarMaterialHemjeeDeleteReq,
  ProductSambarPriceListReq,
} from "../../../services/Request";
import {
  ProductSambarMaterialHemjeeAdd,
  ProductSambarMaterialHemjeeList,
  ProductSambarMaterialHemjeeDelete,
  ProductSambarPriceList,
} from "../../../services/main";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { isNullOrEmpty, moneyFormat } from "../../../components/Utils/Utils";
class TatlagaUneNemeh extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      prod: this.props.prod,
      salaa_id: "",
      hemjee_id: "",
      material_id: "",
      price: "",
      tolov: "",
      validate: [],
      loading: false,
      data: [],
      allPriceData: [],
      addOrUpdate: true,
    };
    this.onSuccess = this.onSuccess.bind(this);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    const { prod } = this.state;
    if (prod !== null) {
      this.setState({ sambar_id: prod.id }, function () {
        this.getPrice();
      });
    }
  }

  getAllPrice = () => {
    const { prod } = this.state;
    var req = ProductSambarPriceListReq;
    req.filter.torol = prod.torol;
    ProductSambarPriceList(req, this.onAllListSuccess, this.onFailed, "POST");
  };

  onAllListSuccess = (response) => {
    this.setState({
      allPriceData: response.data,
    });
  };

  getPrice = () => {
    const { prod, hemjee_id, material_id } = this.state;
    if (!this.state.loading) {
      this.setState({ loading: true });
    }
    var req = ProductSambarMaterialHemjeeListReq;
    req.filter.sambar_id = prod.id;
    req.filter.material_id = material_id;
    req.filter.hemjee_id = hemjee_id;
    ProductSambarMaterialHemjeeList(
      req,
      this.onListSuccess,
      this.onFailed,
      "POST"
    );
  };

  onListSuccess = (response) => {
    this.setState(
      {
        loading: false,
        data: response.data,
      },
      function () {
        if (this.state.data.length > 0) {
          this.getAllPrice();
        }
      }
    );
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, function () {
      if (name == "hemjee_id") {
        this.setState({
          material_id: "",
        });
      }
      this.getPrice();
    });
  };

  save() {
    const { hemjee_id, prod, material_id } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      this.setState({ isSave: true });
      var req = ProductSambarMaterialHemjeeAddReq;
      req.sambar_id = prod.id;
      req.hemjee_id = parseInt(hemjee_id);
      req.material_id = parseInt(material_id);
      ProductSambarMaterialHemjeeAdd(
        req,
        this.onSuccess,
        this.onFailed,
        "POST"
      );
    }
  }

  onSuccess = (response) => {
    const { t } = this.state;
    this.setState(
      { loading: false, isSave: false, hemjee_id: "", material_id: "" },
      function () {
        this.getPrice();
      }
    );
    return toast.success(t("common:SuccessSave"));
  };

  onFailed = (error) => {
    this.setState({
      loading: false,
      apierror: true,
      Industryloading: false,
      apierrormsg: error,
      isSave: false,
    });
  };

  setValidate = (id, e) => {
    var val = "";
    e.target === undefined ? (val = e) : (val = e.target.value);
    const { validate } = this.state;
    if (val !== "") {
      validate[id] = false;
    } else {
      validate[id] = true;
    }
    this.setState({ validate });
  };

  validate = () => {
    var res = { retType: 0 };
    const { validate, hemjee_id, material_id } = this.state;
    if (hemjee_id == "") {
      res.retType = 1;
      validate["hemjee_id"] = true;
    }
    if (material_id == "") {
      res.retType = 1;
      validate["material_id"] = true;
    }
    this.setState({ validate });
    return res;
  };

  actionRowFormat = (row) => {
    const { t } = this.state;
    let edit = (
      <div className="text-center" style={{ marginLeft: -12 }}>
        <Button
          className="button delete-button"
          onClick={() => {
            this.submit(row, "deleteMain");
          }}
          title={t("common:delete")}
          color="success"
          outline
        >
          <i className="fa fa-trash" />
        </Button>
      </div>
    );
    return edit;
  };

  submit = (cell, type) => {
    const { t } = this.state;
    var title =
      t(`common:magicnum.ProductSambarHemjee.${cell.hemjee_id}`) +
      " " +
      t("product.sambar.price.delete");
    var message = t("common:confirm");

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-confirm-ui">
            <h1>{title.substr(0, 30)}...</h1>
            <p>{message}</p>
            <Button outline onClick={onClose}>
              {t("common:no")}
            </Button>
            <Button
              color="danger"
              onClick={() => {
                type == "deleteMain" ? this.delete(cell) : this.Confirm(cell);
                onClose();
              }}
            >
              {t("common:yes")}
            </Button>
          </div>
        );
      },
    });
  };

  delete = (row) => {
    var req = ProductSambarMaterialHemjeeDeleteReq;
    req.id = row.id;
    ProductSambarMaterialHemjeeDelete(
      req,
      this.onDeleteSuccess,
      this.onFailed,
      "POST"
    ); //serverluu huselt ilgeej bn  !!!!!
  };

  onDeleteSuccess = () => {
    const { t } = this.state;
    this.getPrice();
    return toast.error(t("common:SuccessDelete"));
  };

  getHemjeeMaterialPrice = (hemjee_id, material_id) => {
    const { allPriceData,t } = this.state;
    var une = "";
    allPriceData.map((item) => {
      if (item.hemjee_id == hemjee_id && item.material_id == material_id) {
        une = item.price;
      }
    });
    return isNullOrEmpty(une) ? t('common:no-price') : moneyFormat(une,'MNT');
  };

  render() {
    const {
      t,
      validate,
      apierror,
      apierrormsg,
      hemjee_id,
      material_id,
      loading,
      data,
      addOrUpdate,
    } = this.state;
    return (
      <div className="animated fadeIn">
        {apierror && (
          <UncontrolledAlert color="danger" className="alertDiv">
            {apierrormsg}
          </UncontrolledAlert>
        )}
        <div>
          <Row>
            <Col>
              <FormGroup>
                <Label>{t("product.sambar.price.hemjee_id")}</Label>
                <Input
                  type="select"
                  name="hemjee_id"
                  value={hemjee_id}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("hemjee_id", event);
                  }}
                  invalid={validate["hemjee_id"]}
                >
                  <option value="">{t("common:choose")}</option>
                  {magicnum.ProductSambarHemjee.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {t(`common:magicnum.ProductSambarHemjee.${item}`)}
                      </option>
                    );
                  })}
                </Input>
                <FormFeedback>{t("common:SelectValid")}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t("product.sambar.price.material_id")}</Label>
                <Input
                  type="select"
                  name="material_id"
                  value={material_id}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("material_id", event);
                  }}
                  invalid={validate["material_id"]}
                >
                  <option value="">{t("common:choose")}</option>
                  {magicnum.ProductSambarMaterial.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {t(`common:magicnum.ProductSambarMaterial.${item}`)}
                      </option>
                    );
                  })}
                </Input>
                <FormFeedback>{t("common:SelectValid")}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup
                className="saveBtn"
                style={{ marginTop: 25, width: "100%" }}
              >
                <Button
                  color="primary"
                  onClick={!addOrUpdate ? this.Update : this.save}
                  disabled={this.state.isSave}
                >
                  {t("common:save")}
                </Button>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardHeader>{t("product.sambar.price.Title")}</CardHeader>
                <CardBody>
                  <div className="table-responsive">
                    <div className="scroll">
                      <Table>
                        <thead>
                          <tr>
                            <th>№</th>
                            <th>{t("product.sambar.price.hemjee_id")}</th>
                            <th>{t("product.sambar.price.material_id")}</th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            <tr>
                              <td colSpan="7">
                                <Spinner />
                              </td>
                            </tr>
                          ) : (
                            data.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    {t(
                                      `common:magicnum.ProductSambarHemjee.${item.hemjee_id}`
                                    )}
                                  </td>
                                  <td>
                                    {t(
                                      `common:magicnum.ProductSambarMaterial.${item.material_id}`
                                    )}
                                  </td>
                                  <td>{this.getHemjeeMaterialPrice(item.hemjee_id,item.material_id)}</td>
                                  <td>{this.actionRowFormat(item)}</td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default TatlagaUneNemeh;
