import React, { Component } from "react";

import ContentLoader from "react-content-loader";
class CustomContentLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: this.props.type
    };
  }

  render() {
    const { type } = this.state;
    return (
       type === "table" ? 
        <ContentLoader
            height={100}
            width={700}
            speed={1}
            primarycolor="#f3f3f3"
            secondarycolor="#ecebeb"
        >
            <rect x="0" y="0" rx="3" ry="3" width="200" height="10" />
            <rect x="210" y="0" rx="3" ry="3" width="490" height="10" />
            <rect x="15" y="20" rx="3" ry="3" width="385" height="10" />
            <rect x="415" y="20" rx="3" ry="3" width="285" height="10" />
            <rect x="15" y="40" rx="3" ry="3" width="385" height="10" />
            <rect x="415" y="40" rx="3" ry="3" width="285" height="10" />
            <rect x="0" y="60" rx="3" ry="3" width="200" height="10" />
            <rect x="210" y="60" rx="3" ry="3" width="490" height="10" />
            <rect x="15" y="80" rx="3" ry="3" width="385" height="10" />
            <rect x="415" y="80" rx="3" ry="3" width="285" height="10" />
        </ContentLoader> : type === "sidebarDetail" ?
        	<ContentLoader 
          rtl
          height={400}
          width={400}
          speed={1}
          primarycolor="#f3f3f3"
          secondarycolor="#ecebeb"
        >
          <rect x="10" y="170" rx="3" ry="3" width="360" height="8" /> 
          <rect x="10" y="200" rx="3" ry="3" width="360" height="8" /> 
          <rect x="10" y="230" rx="3" ry="3" width="360" height="8" /> 
          <rect x="10" y="260" rx="3" ry="3" width="360" height="8" /> 
          <rect x="10" y="290" rx="3" ry="3" width="360" height="8" /> 
          <circle cx="185.6" cy="84.6" r="66.6" />
        </ContentLoader> : null
    );
  }
}
export default CustomContentLoader;
