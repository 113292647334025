import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  UncontrolledAlert,
} from "reactstrap";
import {
  magicnum,
  CustomerUpdateReq,
  CustomerCheckReq,
  CustomerAddReq,
} from "../../services/Request";
import {
  CustomerUpdate,
  CustomerAdd,
  CustomerCheck,
} from "../../services/main";
import { ModalManager } from "react-dynamic-modal";
import { isNullOrEmpty } from "../../components/Utils/Utils.js";
class BurtgelAddFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      item: this.props.item,
      rd: "",
      password: "",
      name: "",
      email: "",
      mobile: "",
      telephone: "",
      holbogdoh_hun: "",
      alban_tushaal: "",
      status: magicnum.Status.InActive,
      validate: [],
      loadingCheck: false,
    };
  }

  componentDidMount() {
    const { item } = this.state;
    if (item !== null) {
      this.setState({
        name: item.name,
        rd: item.rd,
        email: item.email,
        mobile: item.mobile,
        telephone: item.telephone,
        holbogdoh_hun: item.holbogdoh_hun,
        alban_tushaal: item.alban_tushaal,
        status: item.status,
        id: item.id,
      });
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, function () {
      if (name == "rd" && value.length > 6) {
        var req = CustomerCheckReq;
        req.filter.rd = value;
        this.setState({ loadingCheck: true });
        CustomerCheck(req, this.onCheckSuccess, this.onFailed, "POST");
      }
    });
  };

  onCheckSuccess = (response) => {
    this.setState({ loadingCheck: false });
    this.setState({ name: response.data.name });
  };

  save = () => {
    const {
      name,
      rd,
      password,
      email,
      mobile,
      telephone,
      holbogdoh_hun,
      alban_tushaal,
      status,
    } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      this.setState({ isSave: true });
      var req = CustomerAddReq;
      req.Customer.name = name;
      req.Customer.rd = rd;
      req.Customer.password = password;
      req.Customer.email = email;
      req.Customer.mobile = mobile;
      req.Customer.telephone = telephone;
      req.Customer.holbogdoh_hun = holbogdoh_hun;
      req.Customer.alban_tushaal = alban_tushaal;
      req.Customer.status = status;
      CustomerAdd(req, this.onSuccess, this.onFailed, "POST");
    }
  };

  onSuccess = (response) => {
    const { t } = this.state;
    this.props.addSuccess(t("common:SuccessSave"));
    ModalManager.close();
  };

  Update = () => {
    const {
      name,
      rd,
      password,
      email,
      mobile,
      telephone,
      holbogdoh_hun,
      alban_tushaal,
      id,
      status,
    } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      this.setState({ isSave: true });
      var req = CustomerUpdateReq;
      req.Customer.name = name;
      req.Customer.rd = rd;
      req.Customer.password = password;
      req.Customer.email = email;
      req.Customer.mobile = mobile;
      req.Customer.telephone = telephone;
      req.Customer.holbogdoh_hun = holbogdoh_hun;
      req.Customer.alban_tushaal = alban_tushaal;
      req.Customer.status = status;
      req.Customer.id = id;
      CustomerUpdate(req, this.onUpdatSuccess, this.onFailed, "PUT");
    }
  };

  onUpdatSuccess = (response) => {
    const { t } = this.state;
    this.props.addSuccess(t("common:SuccessEdit"));
    ModalManager.close();
  };

  onFailed = (error) => {
    this.setState({
      loading: false,
      apierror: true,
      Industryloading: false,
      apierrormsg: error,
      isSave: false,
      loadingCheck: false,
    });
  };

  setValidate = (id, e) => {
    var val = "";
    e.target === undefined ? (val = e) : (val = e.target.value);
    const { validate } = this.state;
    if (val !== "") {
      validate[id] = false;
    } else {
      validate[id] = true;
    }
    this.setState({ validate });
  };

  validate = () => {
    var res = { retType: 0 };
    const {
      validate,
      name,
      rd,
      mobile,
      holbogdoh_hun,
      alban_tushaal,
      status,
    } = this.state;
    if (status == "") {
      res.retType = 1;
      validate["status"] = true;
    }
    if (name == "") {
      res.retType = 1;
      validate["name"] = true;
    }
    if (rd == "") {
      res.retType = 1;
      validate["rd"] = true;
    }    
    if (mobile == "") {
      res.retType = 1;
      validate["mobile"] = true;
    }
    if (holbogdoh_hun == "") {
      res.retType = 1;
      validate["holbogdoh_hun"] = true;
    }
    if (alban_tushaal == "") {
      res.retType = 1;
      validate["alban_tushaal"] = true;
    }

    this.setState({ validate });
    return res;
  };

  render() {
    const {
      t,
      validate,
      apierror,
      apierrormsg,
      item,
      name,
      rd,
      loadingCheck,
      password,
      email,
      mobile,
      telephone,
      holbogdoh_hun,
      alban_tushaal,
      status,
    } = this.state;
    return (
      <div className="animated fadeIn">
        {apierror && (
          <UncontrolledAlert color="danger" className="alertDiv">
            {apierrormsg}
          </UncontrolledAlert>
        )}
        <div>
          <Row>
            <Col>
              <FormGroup>
                <Label>{t("Customer.rd")}</Label>
                <Input
                  type="text"
                  name="rd"
                  value={rd}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("rd", event);
                  }}
                  invalid={validate["rd"]}
                />
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t("Customer.name")}</Label>
                <Input
                  type="text"
                  name="name"
                  value={loadingCheck ? t("common:loading") : name}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("name", event);
                  }}
                  invalid={validate["name"]}
                  disabled={loadingCheck}
                />
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
            {!isNullOrEmpty(item) ? null : (
              <Col>
                <FormGroup>
                  <Label>{t("Customer.password")}</Label>
                  <Input
                    type="text"
                    name="password"
                    value={password}
                    onChange={(event) => {
                      this.handleChange(event);
                      this.setValidate("password", event);
                    }}
                    invalid={validate["password"]}
                  />
                  <FormFeedback>{t("common:InputValid")}</FormFeedback>
                </FormGroup>
              </Col>
            )}
            <Col>
              <FormGroup>
                <Label>{t("Customer.email")}</Label>
                <Input
                  type="text"
                  name="email"
                  value={email}
                  onChange={(event) => {
                    this.handleChange(event);
                  }}
                />
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t("common:status")}</Label>
                <Input
                  type="select"
                  name="status"
                  value={status}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("status", event);
                  }}
                  invalid={validate["status"]}
                >
                  <option value="">{t("common:choose")}</option>
                  <option value={magicnum.Status.Active}>
                    {t("common:magicnum.Status.Active")}
                  </option>
                  <option value={magicnum.Status.InActive}>
                    {t("common:magicnum.Status.InActive")}
                  </option>
                </Input>
                <FormFeedback>{t("common:SelectValid")}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>{t("Customer.mobile")}</Label>
                <Input
                  type="text"
                  name="mobile"
                  value={mobile}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("mobile", event);
                  }}
                  invalid={validate["mobile"]}
                />
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t("Customer.telephone")}</Label>
                <Input
                  type="text"
                  name="telephone"
                  value={telephone}
                  onChange={(event) => {
                    this.handleChange(event);
                  }}
                />
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t("Customer.holbogdoh_hun")}</Label>
                <Input
                  type="text"
                  name="holbogdoh_hun"
                  value={holbogdoh_hun}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("holbogdoh_hun", event);
                  }}
                  invalid={validate["holbogdoh_hun"]}
                />
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t("Customer.alban_tushaal")}</Label>
                <Input
                  type="text"
                  name="alban_tushaal"
                  value={alban_tushaal}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("alban_tushaal", event);
                  }}
                  invalid={validate["alban_tushaal"]}
                />
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row className="action-row">
            <FormGroup className="saveBtn">
              <Button
                color="primary"
                onClick={item != null ? this.Update : this.save}
                disabled={this.state.isSave}
              >
                {t("common:save")}
              </Button>
            </FormGroup>
          </Row>
        </div>
      </div>
    );
  }
}

export default BurtgelAddFunc;
