import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  UncontrolledAlert,
} from "reactstrap";
import { magicnum, BannerUpdateReq } from "../../services/Request";
import { BannerAdd, BannerUpdate } from "../../services/main";
import { ModalManager } from "react-dynamic-modal";
import { isNullOrEmpty } from "../../components/Utils/Utils.js";
class BurtgelAddFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item,
      t: this.props.t,
      image: "",
      button_name: "",
      position: "",
      button_link: "",
      tolov: magicnum.Status.Active,
      validate: [],
    };
  }

  componentDidMount() {
    const { item } = this.state;
    if (item !== null) {
      this.setState({
        button_name: item.button_name,
        button_link: item.button_link,
        position: item.position,
        tolov: item.tolov,
        id: item.id,
      });
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  save = () => {
    const { position, tolov, button_name, button_link } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      this.setState({ isSave: true });
      const image = document.querySelector("input[type=file]").files[0];
      var formdata = new FormData();
      formdata.append("image", image);
      var banner = {
        position: position,
        button_name: button_name,
        button_link: button_link,
        tolov: tolov,
      };
      formdata.append("banner", JSON.stringify(banner));
      BannerAdd(formdata, this.onSuccess, this.onFailed, "POST", "file");
    }
  };

  onSuccess = (response) => {
    const { t } = this.state;
    this.props.addSuccess(t("common:SuccessSave"));
    ModalManager.close();
  };

  onFailed = (error) => {
    this.setState({
      loading: false,
      apierror: true,
      apierrormsg: error,
      isSave: false,
    });
  };

  setValidate = (id, e) => {
    var val = "";
    e.target === undefined ? (val = e) : (val = e.target.value);
    const { validate } = this.state;
    if (val !== "") {
      validate[id] = false;
    } else {
      validate[id] = true;
    }
    this.setState({ validate });
  };

  validate = () => {
    var res = { retType: 0 };
    const { validate, position, tolov, item } = this.state;
    if (isNullOrEmpty(item)) {
      const myFile = document.querySelector("input[type=file]").files[0];
      if (isNullOrEmpty(myFile)) {
        res.retType = 1;
        validate["image"] = true;
      }
    }

    if (position == "") {
      res.retType = 1;
      validate["position"] = true;
    }
    if (tolov == "") {
      res.retType = 1;
      validate["tolov"] = true;
    }

    this.setState({ validate });
    return res;
  };

  Update = () => {
    const { t, position, tolov, id, button_link, button_name } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      this.setState({ isSave: true });

      var req = BannerUpdateReq;
      req.banner.position = position;
      req.banner.tolov = tolov;
      req.banner.button_link = button_link;
      req.banner.button_name = button_name;
      req.banner.id = id;
      BannerUpdate(req, this.onUpdatSuccess, this.onFailed, "PUT");
    }
  };

  onUpdatSuccess = (response) => {
    const { t } = this.state;
    this.props.addSuccess(t("common:SuccessEdit"));
    ModalManager.close();
  };

  render() {
    const {
      t,
      validate,
      apierror,
      apierrormsg,
      button_link,
      button_name,
      image,
      tolov,
      position,
      item,
    } = this.state;
    return (
      <div className="animated fadeIn">
        {apierror && (
          <UncontrolledAlert color="danger" className="alertDiv">
            {apierrormsg}
          </UncontrolledAlert>
        )}
        <div>
          <Row>
            <Col>
              <FormGroup>
                <Label>{t("banner.image")}</Label>
                <div>
                  <label
                    htmlFor="files"
                    className="btn btn-secondary"
                    style={{ width: "100%" }}
                  >
                    {t("common:choose")}
                  </label>
                  <Input
                    type="file"
                    name="image"
                    value={image}
                    id="files"
                    style={{ display: "none" }}
                    accept=".pdf,.jpg,.png,.jpeg"
                    onChange={this.handleChange}
                    alt={t("banner.file")}
                  />
                </div>
                {validate["image"] ? (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {t("common:SelectValid")}
                  </div>
                ) : null}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t("banner.position")}</Label>
                <Input
                  type="select"
                  name="position"
                  value={position}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("position", event);
                  }}
                  invalid={validate["position"]}
                >
                  <option value="">{t("common:choose")}</option>
                  {magicnum.BannerPosition.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {t(`common:magicnum.BannerPosition.${item}`)}
                      </option>
                    );
                  })}
                </Input>
                <FormFeedback>{t("common:SelectValid")}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t("banner.tolov")}</Label>
                <Input
                  type="select"
                  name="tolov"
                  value={tolov}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("tolov", event);
                  }}
                  invalid={validate["tolov"]}
                >
                  <option value="">{t("common:choose")}</option>
                  <option value={magicnum.Status.Active}>
                    {t("common:magicnum.Status.Active")}
                  </option>
                  <option value={magicnum.Status.InActive}>
                    {t("common:magicnum.Status.InActive")}
                  </option>
                </Input>
                <FormFeedback>{t("common:SelectValid")}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>{t("banner.button_name")}</Label>
                <Input
                  type="text"
                  name="button_name"
                  value={button_name}
                  onChange={(event) => {
                    this.handleChange(event);
                  }}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t("banner.button_link")}</Label>
                <Input
                  type="text"
                  name="button_link"
                  value={button_link}
                  onChange={(event) => {
                    this.handleChange(event);
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="action-row">
            <FormGroup className="saveBtn">
              <Button
                color="primary"
                onClick={item != null ? this.Update : this.save}
                disabled={this.state.isSave}
              >
                {t("common:save")}
              </Button>
            </FormGroup>
          </Row>
        </div>
      </div>
    );
  }
}

export default BurtgelAddFunc;
