import React, { Component } from "react";
import "./LoginOrCreateBanner.scss";
class LoginOrCreateBanner extends Component {
  render() {
    return (
      <div className="LoginOrCreateBanner-container">
        <div className="body" />
        {/* <div className="footer">
          <div className="footer-link">
            <div className="left">
              <Link to={`/public`}>Өргөдөл шалгах</Link>
            </div>
            <div className="right">
              <div id="myLinks">
                <div className="item">
                  <Link to={`/login`}>Ажилтанаар нэвтрэх</Link>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

export default LoginOrCreateBanner;
