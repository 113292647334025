import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { LogAction, setPageTitle } from "../../services/main";
import { LogActionReq } from "../../services/Request";
import { Card, CardHeader, CardBody } from "reactstrap";
import { ToastContainer } from "react-toastify";
import CustomBootsrapTable from "../../components/CustomBootsrapTable/CustomBootsrapTable";
import { dateTimeFormat } from "../../components/Utils/Utils";
class logActionFunc extends Component {
  constructor(props) {
    super(props);
    this.getList = this.getList.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onFailed = this.onFailed.bind(this);
    this.customRowFormat = this.customRowFormat.bind(this);

    this.state = {
      sizePerPage: 30,
      PID: 1,
      PTotal: 0,
      loading: false,
      data: [],
      t: this.props.t,
      apierrormsg: "",
      apierror: false,
      employees: [],
    };
  }
  componentDidMount() {
    setPageTitle(this.props.t("ActionTitle"));
    this.getList();
  }

  getList() {
    if (!this.state.loading) {
      this.setState({ loading: true });
    }
    const { PID, sizePerPage } = this.state;
    var req = LogActionReq;
    req.pid = PID;
    req.pSize = sizePerPage;
    LogAction(req, this.onSuccess, this.onFailed, "POST");
  }

  onSuccess(response) {
    this.setState({
      loading: false,
      PTotal: response.pagination.ptotal,
      data: response.mApi_logs,
      employees: response.MEmployees,
    });
  }

  onFailed(error) {
    this.setState({ loading: false, apierror: true, apierrormsg: error });
  }
  onChangePage(pid) {
    if (pid >= 1) {
      this.setState({ PID: pid, loading: true }, function() {
        this.getList();
      });
    }
  }

  customRowFormat(cell, row) {
    const { employees, t } = this.state;
    var name = "";
    employees.map((item) => {
      if (item.id == cell) {
        name = item.fullname;
      }
    });
    return name;
  }

  onChangePage(pid) {
    if (pid >= 1) {
      this.setState({ PID: pid, loading: true }, function () {
        this.getList();
      });
    }
  }

  render() {
    const { t, apierror, apierrormsg,  } = this.state;
    var table = {
      sizePerPage: this.state.sizePerPage, 
      headerKeyColumn: {
     
        title: t("LogID"), // Garchig der haragdana
        dataField: "LogID", // Table-s ireh talbariin ner
        dataSort: true, // Eremlehe eseh shiidne
        dataFormat: this.defaultRowFormat, // yamar formataar helberjihiig zaana
        hidden: true,
        filter: {
          type: "TextFilter",
          placeholder: "...",
        },
      },
      headerOtherColumn: [
  
        {
          title: t("ControllerName"),
          dataField: "ControllerName",
          dataSort: false,
          dataFormat: this.defaultRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("ActionName"),
          dataField: "ActionName",
          dataSort: false,
          dataFormat: this.defaultRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("CreatedAt"),
          dataField: "CreatedAt",
          dataSort: false,
          dataFormat: dateTimeFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("UserID"),
          dataField: "UserID",
          dataSort: false,
          dataFormat: this.customRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("remoteAddress"),
          dataField: "remoteAddress",
          dataSort: false,
          dataFormat: this.defaultRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
      ],
    };
    const containerStyle = {
      zIndex: 1999,
      top: 40,
    };
    return (
      <div className="animated fadeIn">
        <Card className="nopadding">
          <CardHeader>{t("errorTitle")}</CardHeader>
          <CardBody>
            <div>
              {
                apierror && (
                  <div className="help-block text-danger">{apierrormsg}</div>
                ) // serverees aldaa garval aldaanii medeelel haragdana
              }
              <div>
                <ToastContainer
                  position="top-right"
                  autoClose={3000}
                  style={containerStyle}
                />
                <CustomBootsrapTable
                  data={this.state.data} // serverees irsen datag ogno
                  table={table} // tabliin tohirgoog ogno
                  PTotal={this.state.PTotal} // serverees avsan niit heden huudas bgaag ogno
                  pageChange={this.onChangePage} // huudas soligdohod duudah function bn
                  PID={this.state.PID} // huudaslatiin hed deh huudas ve
                  loading={this.state.loading} // end true false damjuulj data avch ireh uid loading haruulna
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withTranslation("log")(logActionFunc);
