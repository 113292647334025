export default {
  items: [
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "fa fa-bar-chart",
      PrivNo: "menu-dashboard",
    },
    {
      name: "Log",
      url: "/log",
      icon: "fa fa-h-square",
      PrivNo: "menu-log",
      children: [
        {
          name: "LogList",
          url: "/log/success",
          PrivNo: "log-action-POST",
        },
        {
          name: "ErrorLogList",
          url: "/log/error",
          PrivNo: "log-error-POST",
        },
      ],
    },
    {
      name: "Privilege",
      url: "/privilege",
      icon: "fa fa-user",
      PrivNo: "menu-priv",
      children: [
        {
          name: "Employee-List",
          url: "/privilege/employeelist",
          icon: "fa fa-code",
          PrivNo: "menu-priv",
        },
        {
          name: "Privilege-Group",
          icon: "fa fa-code",
          url: "/privilege/privgrouplist",
          PrivNo: "menu-priv",
        },
        {
          name: "Privilege",
          url: "/privilege/privlist",
          icon: "fa fa-code",
          PrivNo: "menu-priv",
        },
      ],
    },    
    {
      icon: "fa fa-product-hunt",
      name: "Product",
      url: "/product",
      PrivNo: "product-menu",
      children: [
        {
          name: "ProductTatlaga",
          url: "/product-tatlaga",
          PrivNo: "product_tatlaga-list-POST",
        },
        {
          name: "ProductSambar",
          url: "/product-sambar",
          PrivNo: "product_sambar-list-POST",
        },
        {
          name: "ProductSambarPrice",
          url: "/product-sambar-price",
          PrivNo: "product_sambar_price-list-POST",
        },
        {
          name: "ProductCran",
          url: "/product-cran",
          PrivNo: "product_cran-list-POST",
        }
      ],
    },
    {
      icon: "fa fa-database",
      name: "Bagts",
      url: "/bagts",
      PrivNo: "bagts-menu",
      children: [
        {
          name: "BagtsList",
          url: "/bagts",
          PrivNo: "bagts-list-POST",
        },
      ],
    },
    {
      icon: "fa fa-users",
      name: "Customer",
      url: "/customer",
      PrivNo: "menu-customer",
      children: [
        {
          name: "CustomerList",
          url: "/customer",
          PrivNo: "customer-list-POST",
        },
      ],
    },
    {
      icon: "fa fa-cart-plus",
      name: "Order",
      url: "/orders/all",
      PrivNo: "menu-order",
      children: [
        {
          name: "OrderList",
          url: "/orders/all",
          PrivNo: "order-list-POST",
        },
        {
          name: "CranOrderList",
          url: "/cranorders/all",
          PrivNo: "cran_zahialga-list-POST",
        },
      ],
    },
    {
      icon: "fa fa-clone",
      name: "Page",
      url: "/page",
      PrivNo: "menu-page",
      children: [
        {
          name: "PageList",
          url: "/page",
          PrivNo: "huudas-list-POST",
        },
      ],
    },
    {
      icon: "fa fa-newspaper-o",
      name: "News",
      url: "/news",
      PrivNo: "menu-news",
      children: [
        {
          name: "NewsList",
          url: "/news",
          PrivNo: "news-list-POST",
        },
      ],
    },
    {
      icon: "fa fa-gear",
      name: "Settings",
      url: "/settings",
      PrivNo: "menu-settings",
      children: [
        {
          name: "BannerList",
          url: "/banner",
          PrivNo: "banner-list-POST",
        },
      ],
    }
  ],
};
