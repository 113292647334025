import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody
} from "reactstrap";
import {
  PrivilegeGroupList,
  PrivilegeGroupDelete
} from "../../../services/main";
import {
  BaseRequest,
  PrivilegeGroupDeleteReq
} from "../../../services/Request";
import CustomBootsrapTable from "../../../components/CustomBootsrapTable/CustomBootsrapTable";
import { ModalManager } from "react-dynamic-modal";
import MainModal from "../../../components/Modal/MainModal/MainModal";
import AddPrivGroup from "./AddPrivGroup";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import { IsPrivilege } from "../../../components/PrivilegeComponent/PrivilegeComponent";
import { withTranslation } from "react-i18next";
class PrivGroupList extends Component {
  constructor(props) {
    super(props);
    this.getList = this.getList.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onFailed = this.onFailed.bind(this);
    this.addSuccess = this.addSuccess.bind(this);

    this.customRowFormat = this.customRowFormat.bind(this);
    this.onDeleteFailed = this.onDeleteFailed.bind(this);
    this.onDeleteSuccess = this.onDeleteSuccess.bind(this);
    this.state = {
      sizePerPage: 150,
      loading: true,
      apierror: false,
      data: [],
      apierrormsg: "",
      PID: 1,
      PTotal: 0,
      t: this.props.t,
      popoverOpen: [false, false],
      popoverOpenIndex: 1
    };
  }

  componentDidMount() {
    this.getList();
  }

  addSuccess(text) {
    this.getList();
    return toast.success(text);
  }

  getList() {
    if (!this.state.loading) {
      this.setState({ loading: true });
    }
    var req = BaseRequest;
    req.PID = this.state.PID;
    req.PSIZE = this.state.sizePerPage;
    PrivilegeGroupList(req, this.onSuccess, this.onFailed, "GET");
  }
  onSuccess(response) {
    this.setState({
      loading: false,
      PTotal: 1,
      data: response.mPriv_GroupForLists
    });
  }
  onFailed(error) {
    this.setState({ loading: false, apierror: true, apierrormsg: error });
  }

  onChangePage(pid) {
    if (pid >= 1) {
      this.setState({ PID: pid }, function() {
        this.getList();
      });
    }
  }
  addOrEditPrivGroup(type) {
    const { t } = this.state;
    var title = t("privGroup.addPrivGroup");
    if (type != "new") {
      title = t("privGroup.editPrivGroup");
    }

    ModalManager.open(
      <MainModal
        responsive
        title={title}
        onRequestClose={() => true}
        component={<AddPrivGroup type={type} success={this.addSuccess} t={t} />}
      />
    );
  }
  isUserRowFormat(cell) {
    return cell == "Y" ? "Yes" : "No";
  }

  PopOvertoggle(id) {
    var NewObject = Object.assign([], this.state.popoverOpen);
    NewObject[id] = !NewObject[id];
    this.setState({
      popoverOpen: NewObject
    });
  }

  customRowFormat(cell, row) {
    const { t, popoverOpenIndex } = this.state;
    let edit = (
      <div className="text-center">
        <Link to={`/privilege/privgroupmore/${cell}`}>
          <Button className="button edit-button">
            <i className="fa fa-info" /> {t("common:more")}
          </Button>
        </Link>
        <Button
          onClick={() => {
            this.addOrEditPrivGroup(cell);
          }}
          className="button edit-button"
        >
          <i className="fa fa-edit" /> {t("common:edit")}
        </Button>
        <Button
          onClick={() => this.PopOvertoggle("delete" + cell + popoverOpenIndex)}
          id={"delete" + cell + popoverOpenIndex}
          className="button delete-button"
        >
          {t("common:delete")}
        </Button>

        <Popover
          isOpen={this.state.popoverOpen["delete" + cell + popoverOpenIndex]}
          target={"delete" + cell + popoverOpenIndex}
        >
          <PopoverHeader>{cell + " " + t("common:delete")}</PopoverHeader>
          <PopoverBody>
            {t("common:confirmdelete")}
            <Button
              className="btn-sm"
              color="danger"
              onClick={() => {
                this.onConfirmDelete(cell);
              }}
            >
              {t("common:delete")}
            </Button>
          </PopoverBody>
        </Popover>
      </div>
    );
    return edit;
  }

  onConfirmDelete(deletID) {
    PrivilegeGroupDelete(
      deletID,
      BaseRequest,
      this.onDeleteSuccess,
      this.onDeleteFailed,
      "DELETE"
    );
  }
  onDeleteSuccess(response) {
    const { t } = this.state;
    this.setState({
      popoverOpenIndex: this.state.popoverOpenIndex + 1
    });
    this.getList();
    return toast.error(t("common:SuccessDelete"));
  }
  onDeleteFailed(error) {
    this.setState({ loading: false, apierror: true, apierrormsg: error });
  }
  render() {
    const { loading, t } = this.state;

    var table = {
      sizePerPage: this.state.sizePerPage, // neg huudsand haruulah too
      headerKeyColumn: {
        // end tulhuur baganiig zaana tsor gants bn
        title: "PrivGroupID", // Garchig der haragdana
        dataField: "PrivGroupID", // Table-s ireh talbariin ner
        dataSort: true, // Eremlehe eseh shiidne
        dataFormat: this.defaultRowFormat, // yamar formataar helberjihiig zaana
        hidden: true,
        filter: {
          type: "TextFilter",
          placeholder: "..."
        } // haruulah eseh zaana
      },
      headerOtherColumn: [
        // end busad haruulah baganuudaa zoono
        {
          title: t("privGroup.privGroupName"),
          dataField: "PrivGroupName",
          dataSort: false,
          dataFormat: this.defaultRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "..."
          }
        },
        {
          title: t("privGroup.isUserGroup"),
          dataField: "IsUserGroup",
          dataSort: false,
          dataFormat: this.isUserRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "..."
          }
        },
        {
          title: "",
          dataField: "PrivGroupID",
          dataSort: false,
          dataFormat: this.customRowFormat,
          hidden: false,
          width: "250px",
          filter: {
            type: "TextFilter",
            placeholder: "..."
          }
        }
      ]
    };

    return (
      <div className="animated fadeIn">
        <ToastContainer position="top-right" autoClose={3000} />
        <Card className="nopadding">
          <CardHeader>
            <i className="fa fa-align-justify" />
            {t("privGroup.privGroupList")}
            <div className="float-right">
              <span
                onClick={() => {
                  this.addOrEditPrivGroup("new");
                }}
                className="btn btn-sm btn-primary"
              >
                {t("common:add")}
              </span>
            </div>
          </CardHeader>
          <CardBody>
            <CustomBootsrapTable
              data={this.state.data} // serverees irsen datag ogno
              table={table} // tabliin tohirgoog ogno
              PTotal={this.state.PTotal} // serverees avsan niit heden huudas bgaag ogno
              pageChange={this.onChangePage} // huudas soligdohod duudah function bn
              PID={this.state.PID} // huudaslatiin hed deh huudas ve
              loading={loading} // end true false damjuulj data avch ireh uid loading haruulna
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withTranslation("priv")(PrivGroupList);
