import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  UncontrolledAlert,
} from "reactstrap";
import {
  magicnum,
  ProductSambarUpdateReq,
  BagtsListReq,
} from "../../services/Request";
import {
  ProductSambarUpdate,
  ProductSambarAdd,
  BagtsList,
} from "../../services/main";
import { ModalManager } from "react-dynamic-modal";
import { isNullOrEmpty, getBagtsInfo } from "../../components/Utils/Utils.js";
import { Multiselect } from "multiselect-react-dropdown";
class BurtgelAddFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      item: this.props.item,
      image: "",
      name: "",
      torol: "",
      subtorol: "",
      subtorolarray: [],
      bagts: [],
      brand: 0,
      code: "",
      price: "",
      tolov: "",
      material_id: "",
      hemjee_id: "",
      validate: [],
      selectedProd: null,
      selectedValue: null,
      dataBagts: null,
      bagtsuud: this.props.t("common:choose"),
    };
    this.onSelect = this.onSelect.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  componentDidMount() {
    const { item, dataBagts } = this.state;
    if (item !== null) {
      this.setState(
        {
          name: item.name,
          torol: item.torol,
          subtorol: item.subtorol,
          bagts: item.bagts,
          brand: item.brand,
          code: item.code,
          price: item.price,
          tolov: item.tolov,
          id: item.id,
        },
        function () {
          this.buildSubTorol();
        }
      );
    }
    this.getBagtsList();
  }

  getBagtsList = () => {
    var req = BagtsListReq;
    req.pid = 1;
    req.pSize = 1000;
    BagtsList(req, this.onBagtsSuccess, this.onFailed, "POST");
  };

  onBagtsSuccess = (response) => {
    this.setState(
      {
        dataBagts: response.data,
      },
      function () {
        if (!isNullOrEmpty(this.state.item)) {
          this.bagtsRowFormat();
        }
      }
    );
  };

  bagtsRowFormat = () => {
    var bArray = [],
      names = "",
      bArray = this.state.item.bagts.split("|");
    bArray.map((item) => {
      if (!isNullOrEmpty(item)) {
        var b = getBagtsInfo(this.state.dataBagts, item);
        if (!isNullOrEmpty(b)) {
          if (!isNullOrEmpty(names)) {
            names += " , ";
          }
          names += b.name;
        }
      }
    });
    this.setState({ bagtsuud: names });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, function () {
      if (name == "torol") {
        this.setState({ subtorol: "" });
        this.buildSubTorol();
      }
    });
  };

  save = () => {
    const {
      t,
      name,
      torol,
      subtorol,
      brand,
      bagts,
      code,
      tolov,
      price,
    } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      this.setState({ isSave: true });
      const image = document.querySelector("input[type=file]").files[0];
      var formdata = new FormData();
      formdata.append("image", image);

      var bagtsnuud = "";
      bagts.map((item) => {
        if (isNullOrEmpty(bagtsnuud)) {
          bagtsnuud += "|";
        }
        bagtsnuud += item.id + "|";
      });

      var sambar = {
        name: name,
        torol: torol,
        subtorol: subtorol,
        brand: brand,
        code: code,
        price: price,
        tolov: tolov,
        bagts: bagtsnuud,
      };
      formdata.append("sambar", JSON.stringify(sambar));
      ProductSambarAdd(formdata, this.onSuccess, this.onFailed, "POST", "file");
    }
  };

  onSuccess = (response) => {
    const { t } = this.state;
    this.props.addSuccess(t("common:SuccessSave"));
    ModalManager.close();
  };

  Update = () => {
    const {
      t,
      name,
      subtorol,
      torol,
      brand,
      id,
      bagts,
      code,
      price,
      tolov,
    } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      this.setState({ isSave: true });

      var bagtsnuud = "";
      if (Array.isArray(bagts)) {
        bagts.map((item) => {
          if (isNullOrEmpty(bagtsnuud)) {
            bagtsnuud += "|";
          }
          bagtsnuud += item.id + "|";
        });
      }

      var req = ProductSambarUpdateReq;
      req.sambar.name = name;
      req.sambar.subtorol = subtorol;
      req.sambar.torol = torol;
      req.sambar.brand = brand;
      req.sambar.code = code;
      req.sambar.price = price;
      req.sambar.tolov = tolov;
      req.sambar.bagts = bagtsnuud;
      req.sambar.id = id;
      ProductSambarUpdate(req, this.onUpdatSuccess, this.onFailed, "PUT");
    }
  };

  onUpdatSuccess = (response) => {
    const { t } = this.state;
    this.props.addSuccess(t("common:SuccessEdit"));
    ModalManager.close();
  };

  onFailed = (error) => {
    this.setState({
      loading: false,
      apierror: true,
      Industryloading: false,
      apierrormsg: error,
      isSave: false,
    });
  };

  setValidate = (id, e) => {
    var val = "";
    e.target === undefined ? (val = e) : (val = e.target.value);
    const { validate } = this.state;
    if (val !== "") {
      validate[id] = false;
    } else {
      validate[id] = true;
    }
    this.setState({ validate });
  };

  validate = () => {
    var res = { retType: 0 };
    const {
      validate,
      item,
      name,
      torol,
      subtorol,
      price,
      tolov,
      code
    } = this.state;
    if (isNullOrEmpty(item)) {
      const myFile = document.querySelector("input[type=file]").files[0];
      if (isNullOrEmpty(myFile)) {
        res.retType = 1;
        validate["image"] = true;
      }
    }

    if (name == "") {
      res.retType = 1;
      validate["name"] = true;
    }    
    if (code == "") {
      res.retType = 1;
      validate["code"] = true;
    }
    if (torol == "") {
      res.retType = 1;
      validate["torol"] = true;
    }
    if (subtorol == "") {
      res.retType = 1;
      validate["subtorol"] = true;
    }
    if (torol == 4) {
      if (price == "") {
        res.retType = 1;
        validate["price"] = true;
      }
      if (tolov == "") {
        res.retType = 1;
        validate["tolov"] = true;
      }
    }

    this.setState({ validate });
    return res;
  };

  onSelect(selectedList, selectedItem) {
    this.setState({ bagts: selectedList });
  }

  onRemove(selectedList, removedItem) {
    this.setState({ bagts: selectedList });
  }

  buildSubTorol = () => {
    const { torol } = this.state;
    var subtorolarray = [];
    magicnum.ProductSambarSubTorol.map((item) => {
      if (item.torol == torol) {
        subtorolarray.push(item);
      }
    });
    this.setState({
      subtorolarray,
    });
  };

  render() {
    const {
      t,
      validate,
      apierror,
      apierrormsg,
      item,
      name,
      image,
      torol,
      subtorol,
      brand,
      subtorolarray,
      selectedList,
      dataBagts,
      bagtsuud,
      code,
      price,
      tolov
    } = this.state;

    return (
      <div className="animated fadeIn">
        {apierror && (
          <UncontrolledAlert color="danger" className="alertDiv">
            {apierrormsg}
          </UncontrolledAlert>
        )}
        <div>
          <Row>
            <Col>
              <FormGroup>
                <Label>{t("product.sambar.name")}</Label>
                <Input
                  type="text"
                  name="name"
                  value={name}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("name", event);
                  }}
                  invalid={validate["name"]}
                />
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t("product.code")}</Label>
                <Input
                  type="text"
                  name="code"
                  value={code}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("code", event);
                  }}
                  invalid={validate["code"]}
                />
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
            {isNullOrEmpty(item) ? (
              <Col>
                <FormGroup>
                  <Label>{t("product.sambar.image")}</Label>
                  <div>
                    <label
                      htmlFor="files"
                      className="btn btn-secondary"
                      style={{ width: "100%" }}
                    >
                      {t("common:choose")}
                    </label>
                    <Input
                      type="file"
                      name="image"
                      value={image}
                      id="files"
                      style={{ display: "none" }}
                      accept=".pdf,.jpg,.png,.jpeg"
                      onChange={this.handleChange}
                      alt={t("product.sambar.file")}
                    />
                  </div>
                  {validate["image"] ? (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {t("common:SelectValid")}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
            ) : null}
            <Col>
              <FormGroup>
                <Label>{t("product.sambar.bagts")}</Label>
                {isNullOrEmpty(dataBagts) ? null : (
                  <Multiselect
                    options={dataBagts}
                    selectedValues={selectedList}
                    onSelect={this.onSelect}
                    onRemove={this.onRemove}
                    displayValue="name"
                    placeholder={bagtsuud}
                  />
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>{t("product.sambar.torol")}</Label>
                <Input
                  type="select"
                  name="torol"
                  value={torol}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("torol", event);
                  }}
                  invalid={validate["torol"]}
                >
                  <option value="">{t("common:choose")}</option>
                  {magicnum.ProductSambarTorol.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {t(`common:magicnum.ProductSambarTorol.${item}`)}
                      </option>
                    );
                  })}
                </Input>
                <FormFeedback>{t("common:SelectValid")}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t("product.sambar.subtorol")}</Label>
                <Input
                  type="select"
                  name="subtorol"
                  value={subtorol}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("subtorol", event);
                  }}
                  invalid={validate["subtorol"]}
                >
                  <option value="">{t("common:choose")}</option>
                  {subtorolarray.map((item, index) => {
                    return (
                      <option value={item.torol + "-" + item.id} key={index}>
                        {t(
                          `common:magicnum.ProductSambarSubTorol.${item.torol}-${item.id}`
                        )}
                      </option>
                    );
                  })}
                </Input>
                <FormFeedback>{t("common:SelectValid")}</FormFeedback>
              </FormGroup>
            </Col>

            {torol == 4 ? (
              <>
                <Col>
                  <FormGroup>
                    <Label>{t("product.tolov")}</Label>
                    <Input
                      type="select"
                      name="tolov"
                      value={tolov}
                      onChange={(event) => {
                        this.handleChange(event);
                        this.setValidate("tolov", event);
                      }}
                      invalid={validate["tolov"]}
                    >
                      <option value="">{t("common:choose")}</option>
                      {magicnum.ProductTatlagaTolov.map((item, index) => {
                        return (
                          <option value={item} key={index}>
                            {t(`common:magicnum.ProductTatlagaTolov.${item}`)}
                          </option>
                        );
                      })}
                    </Input>
                    <FormFeedback>{t("common:SelectValid")}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>{t(`product.tatlaga.price.price`)}</Label>
                    <Input
                      type="text"
                      name="price"
                      value={price}
                      onChange={(event) => {
                        this.handleChange(event);
                        this.setValidate("price", event);
                      }}
                      invalid={validate["price"]}
                    />
                  </FormGroup>
                </Col>
              </>
            ) : null}
            <Col>
              <FormGroup>
                <Label>{t("product.brand")}</Label>
                <Input
                  type="select"
                  name="brand"
                  value={brand}
                  onChange={(event) => {
                    this.handleChange(event);
                  }}
                >
                  <option value="0">{t("common:choose")}</option>
                  {magicnum.ProductBrand.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {t(`common:magicnum.ProductBrand.${item}`)}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
            </Col>
          </Row>

          <Row className="action-row">
            <FormGroup className="saveBtn">
              <Button
                color="primary"
                onClick={item != null ? this.Update : this.save}
                disabled={this.state.isSave}
              >
                {t("common:save")}
              </Button>
            </FormGroup>
          </Row>
        </div>
      </div>
    );
  }
}

export default BurtgelAddFunc;
