import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Row,
  Col,
  FormGroup,
  Input,
  FormFeedback,
  NavItem,
  NavLink,
  TabContent,
  Nav,
  TabPane,
  Label,
  Spinner,
} from "reactstrap";
import {
  ChangePass,
  EmployeeChangeInfo,
  EmployeeChangePhoto,
  GetEmployee,
  CDN_URL,
} from "../../../services/main";
import {
  ChangePassReq,
  EmployeeChangeInfoReq,
  BaseRequest,
} from "../../../services/Request";
import { toast, ToastContainer } from "react-toastify";
import { withTranslation } from "react-i18next";
import classnames from "classnames";
import { isNullOrEmpty, getRealData } from "../../../components/Utils/Utils";

class UserDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      apierror: false,
      employee: null,
      validate: [],
      t: this.props.t,
      activeTab: "1",
      LastName: "",
      FirstName: "",
      Photo: "",
      id: "",
      file: "",
      oldPass: "",
      realOldPass: "",
      userData: isNullOrEmpty(getRealData("data2"))
        ? null
        : JSON.parse(getRealData("data2")),
    };
  }
  componentDidMount() {
    const { userData } = this.state;
    if (!isNullOrEmpty(userData)) {
      this.setState(
        { id: userData.id, realOldPass: userData.password },
        function () {
          this.getEmployee();
        }
      );
    }
  }

  getEmployee = () => {
    if (!this.state.loading) {
      this.setState({ loading: true });
    }
    GetEmployee(
      this.state.id,
      BaseRequest,
      this.onGetEmployeeSuccess,
      this.onFailed,
      "GET"
    );
  };

  onGetEmployeeSuccess = (response) => {
    this.setState({
      LastName: response.mEmployees.lastname,
      FirstName: response.mEmployees.firstname,
      Photo: response.mEmployees.photo,
      loading: false,
    });
  };

  onFailed = (error, text) => {
    this.setState({ loading: false, isSave: false });
    return toast.error(
      <div className="toast-section">
        <i className="fa fa-close icon" />
        {" " + text + " (" + error + ")"}
      </div>
    );
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  setValidate = (id, e) => {
    var val = "";
    e.target === undefined ? (val = e) : (val = e.target.value);
    const { validate } = this.state;
    if (val !== "") {
      validate[id] = false;
    } else {
      validate[id] = true;
    }
    this.setState({ validate });
  };

  validate = () => {
    var res = { retType: 0 };
    const { Password, validate, FirstName, LastName, activeTab } = this.state;
    if (activeTab == "1") {
      if (FirstName == "") {
        res.retType = 1;
        validate["FirstName"] = true;
      }
      if (LastName == "") {
        res.retType = 1;
        validate["LastName"] = true;
      }
    } else if (activeTab == "2") {
      const myFile = document.querySelector("input[type=file]").files[0];
      if (!myFile.type.includes("image")) {
        res.retType = 1;
        validate["file"] = true;
      }
    } else if (activeTab == "3") {
      if (Password == "") {
        res.retType = 1;
        validate["Password"] = true;
      }
    }

    this.setState({ validate });
    return res;
  };

  save = () => {
    const { Password, id, oldPass, realOldPass } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      this.setState({ isSave: true });
      var req = ChangePassReq;
      req.EmployeeID = id;
      req.newPassword = Password;
      req.OLdPass = oldPass;
      req.realOldPass = realOldPass;
      ChangePass(req, this.onSuccess, this.onFailed, "POST");
    }
  };

  update = () => {
    const { LastName, FirstName, id } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      this.setState({ isSave: true });
      var req = EmployeeChangeInfoReq;
      req.id = id;
      req.LastName = LastName;
      req.FirstName = FirstName;
      EmployeeChangeInfo(req, this.onSuccess, this.onFailed, "PUT");
    }
  };

  zurag = () => {
    const { Photo, id } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      this.setState({ isSave: true });
      const myFile = document.querySelector("input[type=file]").files[0];
      var formdata = new FormData();
      formdata.append("File", myFile);
      formdata.append("id", id);
      formdata.append("OldPhoto", Photo);
      EmployeeChangePhoto(
        formdata,
        this.onSuccess,
        this.onFailed,
        "PUT",
        "file"
      );
    }
  };

  onSuccess = (response) => {
    const { t } = this.state;
    this.setState({ isSave: false, loading: false, file: "" }, function () {
      this.getEmployee();
    });
    return toast.info(t("common:SuccessEdit"));
  };

  toggle = (tab) => {
    this.setState({ activeTab: tab });
  };

  render() {
    const {
      apierror,
      apierrormsg,
      loading,
      t,
      Password,
      validate,
      activeTab,
      FirstName,
      LastName,
      Photo,
      file,
      oldPass,
    } = this.state;

    return (
      <div className="animated fadeIn more-privgroup-container">
        <Card>
          <CardHeader>{t("employee.detail")}</CardHeader>
          <CardBody>
            {
              apierror && (
                <div className="help-block text-danger">{apierrormsg}</div>
              ) // serverees aldaa garval aldaanii medeelel haragdana
            }
            {loading ? (
              <Spinner />
            ) : (
              <div>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        this.toggle("1");
                      }}
                    >
                      {t("employee.profile")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        this.toggle("2");
                      }}
                    >
                      {t("employee.photo")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "3" })}
                      onClick={() => {
                        this.toggle("3");
                      }}
                    >
                      {t("employee.password")}
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col md={3}>
                        <FormGroup>
                          <Label>{t("employee.LastName")}</Label>
                          <Input
                            type="text"
                            name="LastName"
                            value={LastName}
                            onChange={(event) => {
                              this.handleChange(event);
                              this.setValidate("LastName", event);
                            }}
                            invalid={validate["LastName"]}
                          />
                          <FormFeedback>{t("common:InputValid")}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Label>{t("employee.FirstName")}</Label>
                          <Input
                            type="text"
                            name="FirstName"
                            value={FirstName}
                            onChange={(event) => {
                              this.handleChange(event);
                              this.setValidate("FirstName", event);
                            }}
                            invalid={validate["FirstName"]}
                          />
                          <FormFeedback>{t("common:InputValid")}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup style={{ paddingTop: 26 }}>
                          <Button
                            color="primary"
                            onClick={this.update}
                            disabled={this.state.isSave}
                          >
                            {t("common:change")}
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col md={3}>
                        <img
                          src={CDN_URL + "/file/img/" + Photo}
                          width={60}
                          height={60}
                          style={{ borderRadius: "50%" }}
                          onError={(e) =>
                            (e.target.src = require("../../../img/no-image.png"))
                          }
                        />
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Label>{t("employee.photo1")}</Label>
                          <Input
                            type="file"
                            name="file"
                            value={file}
                            accept=".pdf,.jpg,.png,.jpeg"
                            onChange={(event) => {
                              this.handleChange(event);
                              this.setValidate("LastName", event);
                            }}
                            invalid={validate["file"]}
                          />
                          <FormFeedback>{t("common:InputValid")}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup style={{ paddingTop: 26 }}>
                          <Button
                            color="primary"
                            onClick={this.zurag}
                            disabled={this.state.isSave}
                          >
                            {t("common:change")}
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="3">
                    <Row>
                      <Col md={3}>
                        <FormGroup>
                          <Label>{t("employee.changePlc")}</Label>
                          <Input
                            type="password"
                            name="Password"
                            value={Password}
                            onChange={(event) => {
                              this.handleChange(event);
                              this.setValidate("Password", event);
                            }}
                            invalid={validate["Password"]}
                          />
                          <FormFeedback>{t("common:InputValid")}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Label>{t("employee.oldPass")}</Label>
                          <Input
                            type="password"
                            name="oldPass"
                            value={oldPass}
                            onChange={(event) => {
                              this.handleChange(event);
                              this.setValidate("oldPass", event);
                            }}
                            invalid={validate["oldPass"]}
                          />
                          <FormFeedback>{t("common:InputValid")}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup style={{ paddingTop: 26 }}>
                          <Button
                            color="primary"
                            onClick={this.save}
                            disabled={this.state.isSave}
                          >
                            {t("employee.changeBtn")}
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </div>
            )}
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withTranslation("employee")(UserDetail);
