import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  UncontrolledAlert,
} from "reactstrap";
import { magicnum, AuthReq } from "../../../services/Request";
import { EmployeeAdd } from "../../../services/main";
import { ModalManager } from "react-dynamic-modal";
import { isNullOrEmpty } from "../../../components/Utils/Utils";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";

class EmployeeCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      validate: [],
      isSave: false,
      Username: "",
      Password: "",
      Status: magicnum.Status.Active,
      FirstName: "",
      LastName: "",
      Mobile: "",
      ExpiredDate: moment().add(1, "M").format("YYYY-MM-DD"),
      TypeID: "",
      loading: false,
      datas: [],
      SabID: "",
      file: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.validate = this.validate.bind(this);
    this.save = this.save.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onFailed = this.onFailed.bind(this);
  }

  componentDidMount() {}

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  save() {
    const { Username, Password, Status, FirstName, LastName, TypeID, Mobile } =
      this.state;
    var res = this.validate();
    if (res.retType === 0) {
      const myFile = document.querySelector("input[type=file]").files[0];
      if (isNullOrEmpty(myFile)) {
        return toast.error("Зураг сонгоно уу!!");
      } else {
        var formdata = new FormData();
        formdata.append("File", myFile);
        formdata.append("Username", Username);
        formdata.append("Password", Password);
        formdata.append("Status", Status);
        formdata.append("FirstName", FirstName);
        formdata.append("LastName", LastName);
        formdata.append("TypeID", TypeID);
        formdata.append("Mobile", Mobile);
        EmployeeAdd(formdata, this.onSuccess, this.onFailed, "POST", "file");
      }
    }
  }

  onSuccess(response) {
    const { t } = this.state;
    ModalManager.close();
    this.props.success(t("common:SuccessSave"));
  }
  onFailed(error) {
    this.setState({ loading: false, apierror: true, apierrormsg: error });
  }

  setValidate(id, e) {
    var val = "";
    e.target === undefined ? (val = e) : (val = e.target.value);
    const { validate } = this.state;
    if (val !== "") {
      validate[id] = false;
    } else {
      validate[id] = true;
    }
    this.setState({ validate });
  }

  validate() {
    var res = { retType: 0 };
    const {
      Username,
      Password,
      Status,
      FirstName,
      LastName,
      Mobile,
      TypeID,
      validate,
    } = this.state;
    if (TypeID == "") {
      res.retType = 1;
      validate["TypeID"] = true;
    }
    if (Username == "") {
      res.retType = 1;
      validate["Username"] = true;
    }
    if (Password == "") {
      res.retType = 1;
      validate["Password"] = true;
    }
    if (Status == "") {
      res.retType = 1;
      validate["Status"] = true;
    }
    if (FirstName == "") {
      res.retType = 1;
      validate["FirstName"] = true;
    }
    if (LastName == "") {
      res.retType = 1;
      validate["LastName"] = true;
    }
    if (Mobile == "") {
      res.retType = 1;
      validate["Mobile"] = true;
    }
    this.setState({ validate });
    return res;
  }

  render() {
    const {
      t,
      Username,
      Password,
      Status,
      FirstName,
      LastName,
      Mobile,
      TypeID,
      validate,
      apierror,
      apierrormsg,
      SabID,
      datas,
      file,
    } = this.state;

    return (
      <div className="animated fadeIn">
        {apierror && (
          <UncontrolledAlert color="danger">{apierrormsg}</UncontrolledAlert>
        )}
        <Row>
          <Col md={3}>
            <FormGroup>
              <Label>{t("employee.photo1")}</Label>
              <Input
                type="file"
                name="file"
                value={file}
                accept=".pdf,.jpg,.png,.jpeg"
                onChange={this.handleChange}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label>{t("employee.LastName")}</Label>
              <Input
                type="text"
                name="LastName"
                value={LastName}
                onChange={(event) => {
                  this.handleChange(event);
                  this.setValidate("LastName", event);
                }}
                invalid={validate["LastName"]}
              />
              <FormFeedback>{t("common:InputValid")}</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label>{t("employee.FirstName")}</Label>
              <Input
                type="text"
                name="FirstName"
                value={FirstName}
                onChange={(event) => {
                  this.handleChange(event);
                  this.setValidate("FirstName", event);
                }}
                invalid={validate["FirstName"]}
              />
              <FormFeedback>{t("common:InputValid")}</FormFeedback>
            </FormGroup>
          </Col>

          <Col md={3}>
            <FormGroup>
              <Label>{t("common:status")}</Label>
              <Input
                type="select"
                name="Status"
                value={Status}
                onChange={(event) => {
                  this.handleChange(event);
                  this.setValidate("Status", event);
                }}
                invalid={validate["Status"]}
              >
                <option value="">{t("common:choose")}</option>
                <option value={magicnum.Status.Active}>
                  {t("common:magicnum.Status.Active")}
                </option>
                <option value={magicnum.Status.InActive}>
                  {t("common:magicnum.Status.InActive")}
                </option>
              </Input>
              <FormFeedback>{t("common:SelectValid")}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <FormGroup>
              <Label>{t("employee.userName")}</Label>
              <Input
                type="text"
                name="Username"
                value={Username}
                onChange={(event) => {
                  this.handleChange(event);
                  this.setValidate("Username", event);
                }}
                invalid={validate["Username"]}
              />
              <FormFeedback>{t("common:InputValid")}</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label>{t("employee.password")}</Label>
              <Input
                type="text"
                name="Password"
                value={Password}
                onChange={(event) => {
                  this.handleChange(event);
                  this.setValidate("Password", event);
                }}
                invalid={validate["Password"]}
              />
              <FormFeedback>{t("common:InputValid")}</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label>{t("common:type")}</Label>
              <Input
                type="select"
                name="TypeID"
                value={TypeID}
                onChange={(event) => {
                  this.handleChange(event);
                  this.setValidate("TypeID", event);
                }}
                invalid={validate["TypeID"]}
              >
                <option value="">{t("common:choose")}</option>
                {magicnum.UserType.map((item, index) => {
                  return (
                    <option value={item} key={index}>
                      {" "}
                      {t(`common:magicnum.UserType.${item}`)}
                    </option>
                  );
                })}
              </Input>
              <FormFeedback>{t("common:SelectValid")}</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label>{t("employee.Mobile")}</Label>
              <Input
                type="number"
                name="Mobile"
                value={Mobile}
                onChange={(event) => {
                  this.handleChange(event);
                  this.setValidate("Mobile", event);
                }}
                invalid={validate["Mobile"]}
              />
              <FormFeedback>{t("common:InputValid")}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>

        <Row className="action-row">
          <FormGroup className="saveBtn">
            <Button
              color="primary"
              onClick={this.save}
              disabled={this.state.isSave}
            >
              {t("common:save")}
            </Button>
          </FormGroup>
        </Row>
      </div>
    );
  }
}

export default EmployeeCreate;
