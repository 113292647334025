import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  UncontrolledAlert,
  Card,
  CardHeader,
  CardBody,
  Spinner,
  Table,
} from "reactstrap";
import {
  magicnum,
  ProductTatlagaPriceUpdateReq,
  ProductTatlagaPriceAddReq,
  ProductTatlagaPriceListReq,
  ProductTatlagaPriceDeleteOneReq,
} from "../../../services/Request";
import {
  ProductTatlagaPriceUpdate,
  ProductTatlagaPriceAdd,
  ProductTatlagaPriceList,
  ProductTatlagaPriceDeleteOne,
} from "../../../services/main";
import { ModalManager } from "react-dynamic-modal";
import { isNullOrEmpty, moneyFormat } from "../../../components/Utils/Utils.js";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
class TatlagaUneNemeh extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      item: this.props.item,
      prod: this.props.prod,
      type: this.props.type,
      salaa_id: "",
      jin_id: "",
      urt_id: "",
      price: "",
      tolov: "",
      validate: [],
      loading: false,
      data: [],
      addOrUpdate: true,
    };
    this.onSuccess = this.onSuccess.bind(this);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    const { item } = this.state;
    if (item !== null) {
      this.setState({ jin_id: item }, function () {
        this.getPrice();
      });
    }
  }

  getPrice = () => {
    const { prod, jin_id } = this.state;
    if (!this.state.loading) {
      this.setState({ loading: true });
    }
    var req = ProductTatlagaPriceListReq;
    req.filter.product_id = prod.id;
    req.filter.jin_id = jin_id;
    ProductTatlagaPriceList(req, this.onListSuccess, this.onFailed, "POST");
  };

  onListSuccess = (response) => {
    console.log(response);
    this.setState({
      loading: false,
      data: response.data,
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, function () {
      if (name == "jin_id") {
        this.getPrice();
        this.setState({
          urt_id: "",
          price: "",
          tolov: "",
        });
      }
    });
  };

  save() {
    const { jin_id, prod, urt_id, price, tolov } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      this.setState({ isSave: true });
      var req = ProductTatlagaPriceAddReq;
      req.product_id = prod.id;
      req.jin_id = parseInt(jin_id);
      req.urt_id = parseInt(urt_id);
      req.price = price;
      req.tolov = parseInt(tolov);
      ProductTatlagaPriceAdd(req, this.onSuccess, this.onFailed, "POST");
    }
  }

  onSuccess = (response) => {
    const { t } = this.state;
    this.setState(
      { loading: false, isSave: false, urt_id: "", price: "", tolov: "" },
      function () {
        this.getPrice();
      }
    );
    return toast.success(t("common:SuccessSave"));
  };

  Update = () => {
    const { jin_id, prod, urt_id, price, tolov, id } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      this.setState({ isSave: true });
      var req = ProductTatlagaPriceAddReq;
      req.product_id = prod.id;
      req.jin_id = parseInt(jin_id);
      req.urt_id = parseInt(urt_id);
      req.price = price;
      req.tolov = parseInt(tolov);
      req.id = parseInt(id);
      ProductTatlagaPriceUpdate(req, this.onUpdatSuccess, this.onFailed, "PUT");
    }
  };

  onUpdatSuccess = (response) => {
    const { t } = this.state;
    this.setState(
      {
        loading: false,
        isSave: false,
        urt_id: "",
        price: "",
        tolov: "",
      },
      function () {
        this.getPrice();
      }
    );
    return toast.success(t("common:SuccessEdit"));
  };

  onFailed = (error) => {
    this.setState({
      loading: false,
      apierror: true,
      Industryloading: false,
      apierrormsg: error,
      isSave: false,
    });
  };

  setValidate = (id, e) => {
    var val = "";
    e.target === undefined ? (val = e) : (val = e.target.value);
    const { validate } = this.state;
    if (val !== "") {
      validate[id] = false;
    } else {
      validate[id] = true;
    }
    this.setState({ validate });
  };

  validate = () => {
    var res = { retType: 0 };
    const { validate, jin_id, urt_id, price, tolov } = this.state;
    if (jin_id == "") {
      res.retType = 1;
      validate["jin_id"] = true;
    }
    if (urt_id == "") {
      res.retType = 1;
      validate["urt_id"] = true;
    }
    if (price == "") {
      res.retType = 1;
      validate["price"] = true;
    }
    if (tolov == "") {
      res.retType = 1;
      validate["tolov"] = true;
    }
    this.setState({ validate });
    return res;
  };

  setUpdateData = (row) => {
    this.setState({
      jin_id: row.jin_id,
      urt_id: row.urt_id,
      price: row.price,
      tolov: row.tolov,
      id: row.id,
      addOrUpdate: false,
    });
  };

  actionRowFormat = (row) => {
    const { t } = this.state;
    let edit = (
      <div className="text-center" style={{ marginLeft: -12 }}>
        <Button
          onClick={() => {
            this.setUpdateData(row);
          }}
          className="button edit-button"
          title={t("common:edit")}
          color="success"
          outline
        >
          <i className="fa fa-edit" />
        </Button>
        <Button
          className="button delete-button"
          onClick={() => {
            this.submit(row, "deleteMain");
          }}
          title={t("common:delete")}
          color="success"
          outline
        >
          <i className="fa fa-trash" />
        </Button>
      </div>
    );
    return edit;
  };

  submit = (cell, type) => {
    const { t } = this.state;
    var title =
      t(`common:magicnum.ProductTatlagaJin.${cell.jin_id}`) +
      " " +
      t("product.tatlaga.price.delete");
    var message = t("common:confirm");

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-confirm-ui">
            <h1>{title.substr(0, 30)}...</h1>
            <p>{message}</p>
            <Button outline onClick={onClose}>
              {t("common:no")}
            </Button>
            <Button
              color="danger"
              onClick={() => {
                type == "deleteMain" ? this.delete(cell) : this.Confirm(cell);
                onClose();
              }}
            >
              {t("common:yes")}
            </Button>
          </div>
        );
      },
    });
  };

  delete = (row) => {
    var req = ProductTatlagaPriceDeleteOneReq;
    req.id = row.id;
    ProductTatlagaPriceDeleteOne(
      req,
      this.onDeleteSuccess,
      this.onFailed,
      "POST"
    ); //serverluu huselt ilgeej bn  !!!!!
  };

  onDeleteSuccess = () => {
    const { t } = this.state;
    this.getPrice();
    return toast.error(t("common:SuccessDelete"));
  };

  render() {
    const {
      t,
      validate,
      apierror,
      apierrormsg,
      item,
      jin_id,
      urt_id,
      price,
      tolov,
      loading,
      data,
      addOrUpdate,
    } = this.state;
    return (
      <div className="animated fadeIn">
        {apierror && (
          <UncontrolledAlert color="danger" className="alertDiv">
            {apierrormsg}
          </UncontrolledAlert>
        )}
        <div>
          <Row>
            <Col>
              <FormGroup>
                <Label>{t("product.tatlaga.price.jin_id")}</Label>
                <Input
                  type="select"
                  name="jin_id"
                  value={jin_id}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("jin_id", event);
                  }}
                  invalid={validate["jin_id"]}
                >
                  <option value="">{t("common:choose")}</option>
                  {magicnum.ProductTatlagaJin.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {t(`common:magicnum.ProductTatlagaJin.${item}`)}
                      </option>
                    );
                  })}
                </Input>
                <FormFeedback>{t("common:SelectValid")}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t("product.tatlaga.price.urt_id")}</Label>
                <Input
                  type="select"
                  name="urt_id"
                  value={urt_id}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("urt_id", event);
                  }}
                  invalid={validate["urt_id"]}
                >
                  <option value="">{t("common:choose")}</option>
                  {magicnum.ProductTatlagaUrt.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {t(`common:magicnum.ProductTatlagaUrt.${item}`)}
                      </option>
                    );
                  })}
                </Input>
                <FormFeedback>{t("common:SelectValid")}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t("product.tolov")}</Label>
                <Input
                  type="select"
                  name="tolov"
                  value={tolov}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("tolov", event);
                  }}
                  invalid={validate["tolov"]}
                >
                  <option value="">{t("common:choose")}</option>
                  {magicnum.ProductTatlagaTolov.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {t(`common:magicnum.ProductTatlagaTolov.${item}`)}
                      </option>
                    );
                  })}
                </Input>
                <FormFeedback>{t("common:SelectValid")}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t(`product.tatlaga.price.price`)}</Label>
                <Input
                  type="text"
                  name="price"
                  value={price}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("price", event);
                  }}
                  invalid={validate["price"]}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup
                className="saveBtn"
                style={{ marginTop: 25, width: "100%" }}
              >
                <Button
                  color="primary"
                  onClick={!addOrUpdate ? this.Update : this.save}
                  disabled={this.state.isSave}
                >
                  {t("common:save")}
                </Button>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardHeader>{t("product.tatlaga.price.Title")}</CardHeader>
                <CardBody>
                  <div className="table-responsive">
                    <div className="scroll">
                      <Table>
                        <thead>
                          <tr>
                            <th>№</th>
                            <th>{t("product.tatlaga.price.jin_id")}</th>
                            <th>{t("product.tatlaga.price.urt_id")}</th>
                            <th>{t("product.tolov")}</th>
                            <th>{t(`product.tatlaga.price.price`)}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            <tr>
                              <td colSpan="7">
                                <Spinner />
                              </td>
                            </tr>
                          ) : (
                            data.map((item, index) => {
                              var cl = "btn-success";
                              if (item.tolov == 1) {
                                cl = "btn-success";
                              } else if (item.tolov == 2) {
                                cl = "btn-info";
                              } else if (item.tolov == 3) {
                                cl = "btn-warning";
                              } else if (item.tolov == 4) {
                                cl = "btn-danger";
                              }
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    {t(
                                      `common:magicnum.ProductTatlagaJin.${item.jin_id}`
                                    )}
                                  </td>
                                  <td>
                                    {t(
                                      `common:magicnum.ProductTatlagaUrt.${item.urt_id}`
                                    )}
                                  </td>
                                  <td>
                                    <span
                                      style={{
                                        padding: "3px 10px",
                                        fontSize: 10,
                                        borderRadius: "5px",
                                      }}
                                      className={`${cl}`}
                                    >
                                      {t(
                                        `common:magicnum.ProductTatlagaTolov.${item.tolov}`
                                      )}
                                    </span>
                                  </td>
                                  <td>{moneyFormat(item.price, "MNT")}</td>
                                  <td>{this.actionRowFormat(item)}</td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default TatlagaUneNemeh;
