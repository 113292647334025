import React from "react";
import ReactDOM from "react-dom";

import "font-awesome/css/font-awesome.min.css";
import "./scss/style.scss";
import "react-toastify/dist/ReactToastify.css";

import App from "./App";

import { I18nextProvider } from "react-i18next";
import HttpsRedirect from "react-https-redirect";
import i18next from "i18next";
import "./i18n";
ReactDOM.render(
  <HttpsRedirect>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </HttpsRedirect>,
  document.getElementById("root")
);

