import React, { Component } from "react";
import { Redirect } from 'react-router-dom'
class Logout extends Component {
  constructor(props) {
    super(props);
    this.renderRedirect = this.renderRedirect.bind(this);
    this.state = {
      redirect: true
    };
  }

  componentWillMount(){
    localStorage.clear();
  }

 
  renderRedirect() {
    localStorage.clear();
    if (this.state.redirect) {
      return <Redirect to="/login" />;
    }
  }
  render() {
    return <div>{this.renderRedirect()}</div>;
  }
}

export default Logout;
