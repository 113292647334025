import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Spinner,
  UncontrolledAlert,
  Button,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";

import { setPageTitle, PageGet } from "../../services/main"; //serverluu handah huseltiin ner san   !!!!! end import hine
import { BaseRequest } from "../../services/Request"; //serverluu ilgeeh huselt        !!!!!
import { isNullOrEmpty } from "../../components/Utils/Utils";
import { Link } from "react-router-dom";
import ProductContent from "../Product/ProductContent";

class PageGetFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      loading: false,
      apierrormsg: "",
      apierror: false,
      item: null,
      userData: [],
    };
    this.getMore = this.getMore.bind(this);
    this.onGetSuccess = this.onGetSuccess.bind(this);
    this.onFailed = this.onFailed.bind(this);

    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    this.getMore();
  }

  getMore() {
    this.setState({ loading: true });
    PageGet(
      this.props.match.params.id,
      BaseRequest,
      this.onGetSuccess,
      this.onFailed,
      "GET"
    );
  }

  onGetSuccess(response) {
    this.setState(
      {
        loading: false,
        item: response.data,
      },
      function () {
        setPageTitle(this.state.item.name);
      }
    );
  }

  onFailed(error) {
    this.setState({ loading: false, apierror: true, apierrormsg: error }); //amjiltgui uid aldaanii msg-g onooj bn.
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  render() {
    const { t, loading, item, apierror, apierrormsg } = this.state;
    return (
      <div className="animated fadeIn getorder-container">
        <Card>
          <CardHeader style={{ padding: "10px 20px" }}>
            <i className="fa fa-align-justify" />
            {isNullOrEmpty(item) ? null : item.name} {t("common:more")}
            <div className="float-right">
              <Link to={`/Page`}>
                <Button
                  className="button more-button"
                  color="success"
                  title={t("common:more")}
                  outline
                >
                  {t("common:backList")}
                </Button>
              </Link>
            </div>
          </CardHeader>
          <CardBody>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              style={{ zIndex: 1999, top: 40 }}
            />
            {apierror && (
              <UncontrolledAlert color="danger">
                {apierrormsg}
              </UncontrolledAlert>
            )}
            {loading ? (
              <Spinner />
            ) : isNullOrEmpty(item) ? (
              <div>{t("common:NotFound")}</div>
            ) : (
              <Row>
                <Col xs="12">
                  <ProductContent item={item} t={t} type="page" />
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withTranslation("main")(PageGetFunc);
