import React, { Component } from "react";
import { loginGetToken, setPageTitle } from "../../services/main.js";
import { AuthReq } from "../../services/Request";
import { withTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { FormGroup, Button, Input, FormFeedback, Spinner } from "reactstrap";
import "./Login.scss";
import { ToastContainer, toast } from "react-toastify";
import LoginOrCreateBanner from "./LoginOrCreateBanner/LoginOrCreateBanner";
import CryptoJS from "crypto-js";
import * as config from "../../services/config.json";
import { isNullOrEmpty } from "../../components/Utils/Utils.js";
import logoImg from "../../img/logo.png";
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      userName: "",
      isSuccess: false,
      loading: true,
      validate: [],
      empData: undefined,
      t: this.props.t,
    };

    this.formSubmit = this.formSubmit.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.onFailed = this.onFailed.bind(this);
    this.onhandleChanges = this.onhandleChanges.bind(this);
  }

  componentWillMount() {
    setPageTitle(this.props.t("Container.title"));
    var getSystemToken = localStorage.getItem("SystemToken");
    if (getSystemToken != null) {
      this.setState({
        isSuccess: true,
      });
    }
  }

  //#region [ Event ]

  handleForms(str, e) {
    this.setState({
      [str]: e.target.value,
    });
  }

  formSubmit() {
    const { t, userName, password } = this.state;
    if (this.validate() & !isNullOrEmpty(userName) & !isNullOrEmpty(password)) {
      var request = AuthReq;
      request.Username = CryptoJS.AES.encrypt(
        userName,
        config.engryptPass
      ).toString();
      request.Password = CryptoJS.AES.encrypt(
        password,
        config.engryptPass
      ).toString();
      this.setState({
        loading: false,
      });
      loginGetToken(
        request,
        this.onSuccess,
        (err) => this.onFailed(err, t("common:ErrorLogin")),
        "POST"
      );
    }
  }

  validate() {
    const { userName, password, validate } = this.state;
    var valid = true;
    if (userName.length === 0) {
      validate["userName-"] = true;
      valid = false;
    }
    if (password.length === 0) {
      validate["password-"] = true;
      valid = false;
    }

    this.setState({ validate });
    return valid;
  }

  //#endregion

  //#region [ SignUp Functions ]
  onhandleChanges(stateName, event) {
    this.setState({
      [stateName]: event.target.value,
    });
  }
  //#endregion

  onSuccess(response) {
    var users = CryptoJS.AES.encrypt(
      JSON.stringify(response.users),
      config.engryptPass
    );
    var priv = CryptoJS.AES.encrypt(
      JSON.stringify(response.privs),
      config.engryptPass
    );
    var employeeData = CryptoJS.AES.encrypt(
      JSON.stringify(response.data),
      config.engryptPass
    );

    localStorage.setItem("SystemToken", response.token);
    localStorage.setItem("data1", priv);
    localStorage.setItem("data2", employeeData);
    localStorage.setItem("data3", users);
    localStorage.setItem("EmployeeID", response.data.id);
    localStorage.setItem("EmployeeTypeID", response.data.typeid);

    this.setState({
      empData: response.data,
      isSuccess: true,
      loading: true,
    });
  }

  Redirect() {
    if (this.state.isSuccess && this.state.empData != undefined) {
      return <Redirect to="/dashboard" />;
    }
  }

  onFailed(err, text) {
    this.setState({
      loading: true,
    });
    return toast.error(
      <div className="toast-section">
        <i className="fa fa-close icon" />
        {err}
      </div>
    );
  }

  setValidate(id, e) {
    var val = "";
    e.target === undefined ? (val = e) : (val = e.target.value);
    if (id === "checkCondition") {
      val = e.target.checked;
    }
    const { validate } = this.state;
    if (val !== "") {
      validate[id] = false;
    } else {
      validate[id] = true;
    }
    this.setState({ validate });
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      this.formSubmit();
    }
  }

  render() {
    const { t, i18n } = this.props;
    const { validate, loading } = this.state;

    return (
      <div className="layout-login-container">
        <LoginOrCreateBanner t={t} i18n={i18n} />
        <ToastContainer position="top-right" autoClose={5000} />

        <div className="body">
          {this.Redirect()}
          <div className="content">
            <img
              src={logoImg}
              style={{
                height: 100,
              }}
            />
            <div className="form">
              <div className="text-center">
                <span className="title">{t("common:header.title")}</span>
              </div>
              <FormGroup>
                <Input
                  placeholder={t("Container.email")}
                  onKeyPress={this.handleKeyPress}
                  className="login-input"
                  onChange={(e) => {
                    this.handleForms("userName", e);
                    this.setValidate("userName-", e);
                  }}
                  invalid={validate["userName-"]}
                />
                <FormFeedback>{t("Container.emailValid")}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Input
                  placeholder={t("Container.password")}
                  onKeyPress={this.handleKeyPress}
                  type="password"
                  className="login-input"
                  onChange={(e) => {
                    this.handleForms("password", e);
                    this.setValidate("password-", e);
                  }}
                  invalid={validate["password-"]}
                />
                <FormFeedback>{t("Container.passwordValid")}</FormFeedback>
              </FormGroup>
              <FormGroup className="text-right">
                <div>
                  <Button
                    onKeyPress={(e) => this.handleKeyPress(e)}
                    onClick={this.formSubmit}
                    className="login-btn"
                    color="primary"
                    disabled={!loading}
                  >
                    {!loading ? (
                      <div>
                        {t("Container.loading")}{" "}
                        <Spinner size="sm" color="light" />
                      </div>
                    ) : (
                      t("Container.title")
                    )}
                  </Button>
                </div>
              </FormGroup>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation("login")(Login);
