import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  UncontrolledAlert,
} from "reactstrap";
import { magicnum, NewsUpdateReq } from "../../services/Request";
import { NewsUpdate, NewsAdd } from "../../services/main";
import { ModalManager } from "react-dynamic-modal";
import { isNullOrEmpty } from "../../components/Utils/Utils.js";
class BurtgelAddFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      item: this.props.item,
      image: "",
      name: "",
      desc: "",
      validate: [],
    };
  }

  componentDidMount() {
    const { item } = this.state;
    if (item !== null) {
      this.setState({
        name: item.name,
        desc: item.desc,
        id: item.id,
      });
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  save = () => {
    const { name, desc } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      this.setState({ isSave: true });
      const image = document.querySelector("input[type=file]").files[0];
      var formdata = new FormData();
      formdata.append("image", image);
      var News = {
        name: name,
        desc: desc,
      };
      formdata.append("news", JSON.stringify(News));
      NewsAdd(formdata, this.onSuccess, this.onFailed, "POST", "file");
    }
  };

  onSuccess = (response) => {
    const { t } = this.state;
    this.props.addSuccess(t("common:SuccessSave"));
    ModalManager.close();
  };

  Update = () => {
    const { name, desc, id } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      this.setState({ isSave: true });

      var req = NewsUpdateReq;
      req.News.name = name;
      req.News.desc = desc;
      req.News.id = id;
      NewsUpdate(req, this.onUpdatSuccess, this.onFailed, "PUT");
    }
  };

  onUpdatSuccess = (response) => {
    const { t } = this.state;
    this.props.addSuccess(t("common:SuccessEdit"));
    ModalManager.close();
  };

  onFailed = (error) => {
    this.setState({
      loading: false,
      apierror: true,
      apierrormsg: error,
      isSave: false,
    });
  };

  setValidate = (id, e) => {
    var val = "";
    e.target === undefined ? (val = e) : (val = e.target.value);
    const { validate } = this.state;
    if (val !== "") {
      validate[id] = false;
    } else {
      validate[id] = true;
    }
    this.setState({ validate });
  };

  validate = () => {
    var res = { retType: 0 };
    const { validate, item, name, desc } = this.state;
    if (isNullOrEmpty(item)) {
      const myFile = document.querySelector("input[type=file]").files[0];
      if (isNullOrEmpty(myFile)) {
        res.retType = 1;
        validate["image"] = true;
      }
    }

    if (name == "") {
      res.retType = 1;
      validate["name"] = true;
    }
    if (desc == "") {
      res.retType = 1;
      validate["desc"] = true;
    }

    this.setState({ validate });
    return res;
  };

  render() {
    const { t, validate, apierror, apierrormsg, item, name, image, desc } =
      this.state;
    return (
      <div className="animated fadeIn">
        {apierror && (
          <UncontrolledAlert color="danger" className="alertDiv">
            {apierrormsg}
          </UncontrolledAlert>
        )}
        <div>
          <Row>
            {isNullOrEmpty(item) ? (
              <Col>
                <FormGroup>
                  <Label>{t("News.image")} - (300x300)</Label>
                  <div>
                    <label
                      htmlFor="files"
                      className="btn btn-secondary"
                      style={{ width: "100%" }}
                    >
                      {t("common:choose")}
                    </label>
                    <Input
                      type="file"
                      name="image"
                      value={image}
                      id="files"
                      style={{ display: "none" }}
                      accept=".pdf,.jpg,.png,.jpeg"
                      onChange={this.handleChange}
                      alt={t("News.file")}
                    />
                  </div>
                  {validate["image"] ? (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {t("common:SelectValid")}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
            ) : null}
            <Col>
              <FormGroup>
                <Label>{t("News.name")}</Label>
                <Input
                  type="text"
                  name="name"
                  value={name}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("name", event);
                  }}
                  invalid={validate["name"]}
                />
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>{t("News.desc")}</Label>
                <Input
                  type="textarea"
                  cols={3}
                  rows={5}
                  name="desc"
                  value={desc}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("desc", event);
                  }}
                  invalid={validate["desc"]}
                />
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row className="action-row">
            <FormGroup className="saveBtn">
              <Button
                color="primary"
                onClick={item != null ? this.Update : this.save}
                disabled={this.state.isSave}
              >
                {t("common:save")}
              </Button>
            </FormGroup>
          </Row>
        </div>
      </div>
    );
  }
}

export default BurtgelAddFunc;
