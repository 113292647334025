import React, { Component } from "react";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { Row, Button, FormGroup } from "reactstrap";
import {
  ProductContentAddReq,
  ProductContentGetReq,
} from "../../services/Request";
import {
  ProductContentAdd,
  ProductContentGet,
  ProductContentUpdate,
} from "../../services/main";
import { isNullOrEmpty } from "../../components/Utils/Utils";
import { toast } from "react-toastify";

function uploadImageCallBack(file) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", "https://api.imgur.com/3/image");
    xhr.setRequestHeader("Authorization", "Client-ID 30119699f8544d2");
    const data = new FormData();
    data.append("image", file);
    xhr.send(data);
    xhr.addEventListener("load", () => {
      const response = JSON.parse(xhr.responseText);
      console.log(response);
      resolve(response);
    });
    xhr.addEventListener("error", () => {
      const error = JSON.parse(xhr.responseText);
      console.log(error);
      reject(error);
    });
  });
}

class EditorContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      item: this.props.item,
      type: this.props.type,
      editorState: EditorState.createEmpty(),
      updateOrAdd: "add",
    };
  }

  componentDidMount() {
    this.getContent();
  }

  save = () => {
    const { item, editorState, updateOrAdd, type } = this.state;
    var aa = convertToRaw(editorState.getCurrentContent());
    var req = ProductContentAddReq;
    req.content.relation_id = item.id;
    req.content.type = type;
    req.content.content = JSON.stringify(aa);
    if (updateOrAdd == "add") {
      ProductContentAdd(req, this.onSaveSuccess, this.onFailed, "POST");
    } else {
      req.content.id = updateOrAdd;
      ProductContentUpdate(req, this.onSaveSuccess, this.onFailed, "PUT");
    }
  };

  onSaveSuccess = (response) => {
    const { t } = this.state;
    return toast.success(t("common:SuccessEdit"));
  };

  getContent = () => {
    const { item, type } = this.state;
    var req = ProductContentGetReq;
    req.content.relation_id = item.id;
    req.content.type = type;
    ProductContentGet(req, this.onSuccess, this.onFailed, "POST");
  };

  onSuccess = (response) => {
    if (!isNullOrEmpty(response.data)) {
      var editorState = EditorState.createWithContent(
        convertFromRaw(JSON.parse(response.data.content))
      );
      this.setState({ editorState, updateOrAdd: response.data.id });
    }
  };

  onFailed = (error) => {
    //console.log(error);
    this.setState({
      loading: false,
      apierror: true,
      apierrormsg: error,
      isSave: false,
    });
  };

  onEditorStateChange = (editorState) => {
    //console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    this.setState({
      editorState,
    });
  };

  render() {
    const { editorState, t, item } = this.state;
    return (
      <div className="editor">
        <Editor
          //toolbarOnFocus
          editorState={editorState}
          onEditorStateChange={this.onEditorStateChange}
          toolbar={{
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
            image: {
              uploadCallback: uploadImageCallBack,
              alt: { present: true, mandatory: true },
            },
          }}
        />
        <Row>
          <FormGroup style={{ marginTop: 40, marginLeft: 15 }}>
            <Button
              color="primary"
              onClick={this.save}
              disabled={this.state.isSave}
            >
              {t("common:save")}
            </Button>
          </FormGroup>
        </Row>
      </div>
    );
  }
}

export default EditorContainer;
