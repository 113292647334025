import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  Table,
  UncontrolledAlert,
  Spinner,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import {
  magicnum,
  ProductImagesListReq,
  BaseRequest,
} from "../../../services/Request";
import {
  ProductImagesAdd,
  ProductImagesList,
  CDN_URL,
  ProductImagesDelete,
} from "../../../services/main";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
class ImagesFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      item: this.props.item,
      product_type: this.props.product_type,
      imgCollection: "",
      imageArray: [],
      loading: false,
      isSave: false,
      data: [],
    };
  }

  componentDidMount() {
    this.getList();
  }

  getList = () => {
    if (!this.state.loading) {
      this.setState({ loading: true });
    }

    const { item, product_type } = this.state;
    var req = ProductImagesListReq;
    req.filter.product_id = item.id;
    req.filter.product_type = product_type;
    ProductImagesList(req, this.onListSuccess, this.onFailed, "POST");
  };

  onListSuccess = (response) => {
    this.setState({
      loading: false,
      data: response.data,
    });
  };

  save = () => {
    const { item, t, product_type } = this.state;
    if (this.state.imgCollection.length == 0) {
      return toast.error(t("product.images.reqInfo"));
    }
    this.setState({ isSave: true });
    var formData = new FormData();
    for (const key of Object.keys(this.state.imgCollection)) {
      formData.append("imgCollection", this.state.imgCollection[key]);
    }
    formData.append("product_id", item.id);
    formData.append("product_type", product_type);
    ProductImagesAdd(formData, this.onSuccess, this.onFailed, "POST", "file");
  };

  onSuccess = (response) => {
    const { t } = this.state;
    this.setState({ isSave: false, imageArray: [] });
    this.getList();
    return toast.success(t("common:SuccessSave"));
  };

  onFailed = (error) => {
    this.setState({
      isSave: false,
      loading: false,
      apierror: true,
      apierrormsg: error,
    });
  };

  readURI(e) {
    if (e.target.files) {
      /* Get files in array form */
      const files = Array.from(e.target.files);

      /* Map each file to a promise that resolves to an array of image URI's */
      Promise.all(
        files.map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener("load", (ev) => {
              resolve(ev.target.result);
            });
            reader.addEventListener("error", reject);
            reader.readAsDataURL(file);
          });
        })
      ).then(
        (images) => {
          /* Once all promises are resolved, update state with image URI array */
          this.setState({ imageArray: images });
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  buildImgTag() {
    return (
      <div className="photo-container">
        {this.state.imageArray.map((imageURI, index) => (
          <img
            style={{ height: 100, marginRight: 10 }}
            src={imageURI}
            alt="Photo uploaded"
            key={index}
          />
        ))}
      </div>
    );
  }

  onFileChange = (e) => {
    this.readURI(e);
    this.setState({ imgCollection: e.target.files });
  };

  actionRowFormat = (cell) => {
    const { t } = this.state;
    let edit = (
      <div className="text-center" style={{ marginLeft: -12 }}>
        <Button
          className="button delete-button"
          onClick={() => {
            this.submit(cell);
          }}
          title={t("common:delete")}
          color="success"
          outline
        >
          <i className="fa fa-trash" /> {t("common:delete")}
        </Button>
      </div>
    );
    return edit;
  };

  submit = (cell) => {
    const { t } = this.state;
    var title = t("product.images.delete");
    var message = t("common:confirm");

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-confirm-ui">
            <h1>{title.substr(0, 30)}...</h1>
            <p>{message}</p>
            <Button outline onClick={onClose}>
              {t("common:no")}
            </Button>
            <Button
              color="danger"
              onClick={() => {
                this.delete(cell);
                onClose();
              }}
            >
              {t("common:yes")}
            </Button>
          </div>
        );
      },
    });
  };

  delete = (cell) => {
    ProductImagesDelete(
      cell,
      BaseRequest,
      this.onDeleteSuccess,
      this.onFailed,
      "DELETE"
    ); //serverluu huselt ilgeej bn  !!!!!
  };

  onDeleteSuccess = (response) => {
    const { t } = this.state;
    this.getList();
    return toast.error(t("common:SuccessDelete"));
  };

  imgRowFormat(cell) {
    var newName = cell.split(".");
    return (
      <div>
        <img
          src={CDN_URL + newName[1] + "." + newName[2]}
          height={150}
          width={150}
          style={{ borderRadius: "50%", objectFit: "cover" }}
          onError={(e) => (e.target.src = require("../../../img/no-image.png"))}
        />
      </div>
    );
  }

  render() {
    const { t, apierror, apierrormsg, loading, data } = this.state;
    const imgTag = this.buildImgTag();
    return (
      <div className="animated fadeIn">
        {apierror && (
          <UncontrolledAlert color="danger" className="alertDiv">
            {apierrormsg}
          </UncontrolledAlert>
        )}
        <div>
          <Row>
            <Col>
              <FormGroup>
                <div>
                  <label
                    htmlFor="files"
                    className="btn btn-secondary"
                    style={{ width: "100%" }}
                  >
                    {t("product.imageAdd")}
                  </label>
                  <Input
                    type="file"
                    name="imgCollection"
                    onChange={this.onFileChange}
                    multiple
                    id="files"
                    style={{ display: "none" }}
                    accept=".pdf,.jpg,.png,.jpeg"
                    alt={t("product.imageAdd")}
                  />
                </div>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Button
                  color="primary"
                  onClick={this.save}
                  disabled={this.state.isSave}
                  style={{ width: "100%" }}
                >
                  {t("common:add")}
                </Button>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>{imgTag}</Col>
          </Row>

          <Card>
            <CardHeader>
              {t("product.images.Title")}
              <div className="float-right">
                <button
                  onClick={() => this.getList()}
                  className="btn btn-sm btn-outline-primary"
                >
                  {t("common:refresh")}
                </button>
              </div>
            </CardHeader>
            <CardBody>
              <Table>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="2">
                        <Spinner />
                      </td>
                    </tr>
                  ) : (
                    data.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{this.imgRowFormat(item.file_url)}</td>
                          <td>{this.actionRowFormat(item.id)}</td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

export default ImagesFunc;
