import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {
  OrderDelete,
  OrderList,
  setPageTitle,
  CDN_URL,
  OrderChangeStatus,
  listEmployee,
} from "../../services/main";
import {
  OrderListReq,
  BaseRequest,
  magicnum,
  OrderChangeStatusReq,
  listEmployeeReq,
} from "../../services/Request";
import {
  Card,
  CardHeader,
  CardBody,
  UncontrolledAlert,
  Button,
  Label,
  Input,
  FormGroup,
  Row,
  Col,
} from "reactstrap";
import CustomBootsrapTable from "../../components/CustomBootsrapTable/CustomBootsrapTable";
import $ from "jquery";
import OrderAddOrUpdate from "./OrderAddOrUpdate";
import { ModalManager } from "react-dynamic-modal";
import MainModal from "../../components/Modal/MainModal/MainModal";
import { toast } from "react-toastify";
import {
  dateFormat,
  dateTimeFormat,
  getUser,
  isNullOrEmpty,
  LastAndFirstName,
  moneyFormat,
} from "../../components/Utils/Utils";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
class OrderListFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sizePerPage: 25, //neg huudsand haruulah too
      PID: 1,
      PTotal: 0,
      loading: false,
      data: [],
      t: this.props.t,
      id: "",
      apierrormsg: "",
      apierror: false,
      status:
        this.props.match.params.status == "all"
          ? ""
          : this.props.match.params.status,
      rd: "",
      mobile: "",
      action_type: "",
      huviarlah_hun: "",
      huviarlah_hun_search: "",
      dataEmployee: [],
    };
  }

  componentDidMount() {
    setPageTitle(this.props.t("Order.Title"));
    this.employeeList();
    this.getList();
    console.log("a:", this.props.match.params.status);
  }

  employeeList() {
    var req = listEmployeeReq;
    req.pid = 1;
    req.pSize = 1000;
    listEmployee(req, this.onEmployeeListSuccess, this.onFailed, "POST");
  }

  onEmployeeListSuccess = (response) => {
    this.setState({
      dataEmployee: response.mEmployees,
    });
  };

  getList = () => {
    if (!this.state.loading) {
      this.setState({ loading: true });
    }

    const { PID, sizePerPage, status, id, rd, mobile, huviarlah_hun_search } =
      this.state;
    var req = OrderListReq;
    req.pid = PID;
    req.pSize = sizePerPage;
    req.filter.mobile = mobile;
    req.filter.rd = rd;
    req.filter.orderid = id;
    req.filter.status = status;
    req.filter.hariutssan_hun = huviarlah_hun_search;
    OrderList(req, this.onSuccess, this.onFailed, "POST");
  };

  onSuccess = (response) => {
    this.setState({
      loading: false,
      PTotal: response.pagination.ptotal,
      data: response.data,
    });
  };

  onFailed = (error) => {
    this.setState({ loading: false, apierror: true, apierrormsg: error });
  };

  onChangePage = (pid) => {
    if (pid >= 1) {
      this.setState({ PID: pid, loading: true }, function () {
        this.getList();
      });
    }
  };

  filterToggle() {
    $("#filterTogle").toggle();
    var otherHeight = 160;
    var filterHeight = 0,
      othersapec = 65;
    if ($(".grid-filter").height() !== undefined) {
      filterHeight = $(".grid-filter").height() + othersapec;
    }
    $(".scroll").height(
      $(window).height() -
        $(".app-header").height() -
        otherHeight -
        filterHeight
    );
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  search = () => {
    this.setState({ PID: 1 }, function () {
      this.getList();
    });
  };

  tolovRowFormat = (cell, row) => {
    const { t } = this.state;
    var cl = "";
    if (cell == 1) {
      cl = "btn-secondary";
    } else if (cell == 2) {
      cl = "btn-info";
    } else if (cell == 3) {
      cl = "btn-info";
    } else if (cell == 4) {
      cl = "btn-warning";
    } else if (cell == 5) {
      cl = "btn-success";
    } else if (cell == 6) {
      cl = "btn-danger";
    }

    let edit = (
      <div className="text-center" style={{ marginLeft: -6 }}>
        <Button
          style={{ paddingLeft: 5, paddingRight: 5, borderRadius: 5 }}
          className={`button delete-button ${cl}`}
          onClick={() => {
            this.submit(row, "confirm");
          }}
        >
          {t(`common:magicnum.OrderStatus.${cell}`)}
        </Button>
      </div>
    );
    return edit;
  };

  orderNORowFormat = (cell, row) => {
    let edit = <Link to={`/order/${cell}`}>NO-{cell}</Link>;
    return edit;
  };

  actionRowFormat = (cell, row) => {
    const { t } = this.state;
    let edit = (
      <div className="text-center" style={{ marginLeft: -12 }}>
        <Button
          className="button delete-button"
          onClick={() => {
            this.submit(row, "deleteMain");
          }}
          title={t("common:delete")}
          color="success"
          outline
        >
          <i className="fa fa-trash" />
        </Button>
      </div>
    );
    return row.status == 1 ? edit : null;
  };

  priceRowFormat = (cell, row) => {
    let edit = <div className="text-center">{moneyFormat(cell, "MNT")}</div>;
    return edit;
  };

  submit = (row, type) => {
    const { t, dataEmployee } = this.state;
    var title = row.id + " " + t("common:delete");
    var message = t("common:confirmdelete");
    if (type == "confirm") {
      var title = row.id + " " + t("main:Order.confirm");
      var message = t("common:confirm");
    } else if (type == "delete") {
      var title = row.id + " " + t("main:Order.delete");
      var message = t("common:confirm");
    }

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-confirm-ui">
            <h1>{title.substr(0, 30)}...</h1>
            <p>{message}</p>
            {type == "confirm" ? (
              <>
                <Input
                  type="select"
                  name="action_type"
                  onChange={(event) => {
                    this.handleChange(event);
                  }}
                  style={{ marginTop: 20, marginBottom: 40 }}
                >
                  <option value="">{t("common:choose")}</option>
                  {magicnum.OrderStatus.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {t(`common:magicnum.OrderStatus.${item}`)}
                      </option>
                    );
                  })}
                </Input>
                <Input
                  type="select"
                  name="huviarlah_hun"
                  onChange={(event) => {
                    this.handleChange(event);
                  }}
                  style={{ marginTop: 20, marginBottom: 40 }}
                >
                  <option value="">{t("common:choose")}</option>
                  {dataEmployee.map((item, index) => {
                    return (
                      <option value={item.id} key={index}>
                        {LastAndFirstName(item.lastname, item.firstname)}
                      </option>
                    );
                  })}
                </Input>
              </>
            ) : null}

            <Button outline onClick={onClose}>
              {t("common:no")}
            </Button>
            <Button
              color="danger"
              onClick={() => {
                type == "deleteMain" ? this.delete(row) : this.Confirm(row);
                onClose();
              }}
            >
              {t("common:yes")}
            </Button>
          </div>
        );
      },
    });
  };

  delete = (row) => {
    OrderDelete(
      row.id,
      BaseRequest,
      this.onDeleteSuccess,
      this.onFailed,
      "DELETE"
    ); //serverluu huselt ilgeej bn  !!!!!
  };

  onDeleteSuccess = (response) => {
    const { t } = this.state;
    this.getList();
    return toast.error(t("common:SuccessDelete"));
  };

  Confirm = (row, status) => {
    const { t, huviarlah_hun, action_type } = this.state;
    if (action_type == "2" && isNullOrEmpty(huviarlah_hun)) {
      return toast.error(t("main:Order.selectHuviarlahHun"));
    } else {
      var req = OrderChangeStatusReq;
      req.Order.status = action_type;
      req.Order.id = row.id;
      req.Order.employeeID = huviarlah_hun;
      OrderChangeStatus(req, this.onConfirmSuccess, this.onFailed, "PUT");
    }
  };

  onConfirmSuccess = (response) => {
    const { t } = this.state;
    this.getList();
    return toast.success(t("main:Order.SuccessChangeStatus"));
  };

  huviarlahHunRowFormat = (cell) => {
    const { dataEmployee } = this.state;
    var us = getUser(dataEmployee, cell);
    return isNullOrEmpty(us) || isNullOrEmpty(cell)
      ? ""
      : LastAndFirstName(us.lastname, us.firstname) + "(" + us.mobile + ")";
  };

  render() {
    const {
      t,
      apierror,
      apierrormsg,
      status,
      id,
      rd,
      mobile,
      huviarlah_hun_search,
      dataEmployee,
    } = this.state;
    var table = {
      sizePerPage: this.state.sizePerPage,
      headerKeyColumn: {
        title: "id",
        dataField: "id",
        dataSort: true,
        dataFormat: this.defaultRowFormat,
        hidden: true,
        filter: {
          type: "TextFilter",
          placeholder: "...",
        },
      },
      headerOtherColumn: [
        {
          title: t("Order.id"),
          dataField: "id",
          dataSort: false,
          dataFormat: this.orderNORowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("main:Order.hariutssan_hun"),
          dataField: "hariutssan_hun",
          dataSort: false,
          dataFormat: this.huviarlahHunRowFormat,
          hidden: false,
          width: "140px",
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("main:Order.total_price"),
          dataField: "total_price",
          dataSort: false,
          dataFormat: this.priceRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },

        {
          title: t("main:Customer.rd"),
          dataField: "rd",
          dataSort: false,
          dataFormat: this.defaultRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("main:Customer.mobile"),
          dataField: "mobile",
          dataSort: false,
          dataFormat: this.defaultRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("main:Order.created_at"),
          dataField: "created_at",
          dataSort: false,
          dataFormat: dateFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("main:Order.tolov"),
          dataField: "status",
          dataSort: false,
          dataFormat: this.tolovRowFormat,
          hidden: false,
          width: "210px",
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: "",
          dataField: "id",
          dataSort: false,
          dataFormat: this.actionRowFormat,
          hidden: false,
          width: "60px",
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
      ],
    };
    return (
      <div className="animated fadeIn">
        <Card className="grid-filter">
          <CardHeader>
            <span className="filterToggle" onClick={() => this.filterToggle()}>
              <i className="fa fa-filter" />
              {t("common:filter")}
            </span>
            <div className="float-right">
              <span className="btn btn-sm btn-primary" onClick={this.search}>
                {t("common:search")}
              </span>
            </div>
          </CardHeader>
          <CardBody id="filterTogle" style={{ display: "none" }}>
            <div className="filter">
              <Row>
                <Col>
                  <FormGroup>
                    <Label>{t("Order.hariutssan_hun")}</Label>
                    <Input
                      type="select"
                      name="huviarlah_hun_search"
                      value={huviarlah_hun_search}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    >
                      <option value="">{t("common:choose")}</option>
                      {dataEmployee.map((item, index) => {
                        var us = getUser(dataEmployee, item.id);
                        return (
                          <option value={item.id} key={index}>
                            {LastAndFirstName(us.lastname, us.firstname)}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>{t("Order.id")}</Label>
                    <Input
                      type="text"
                      name="id"
                      value={id}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>{t("Order.tolov")}</Label>
                    <Input
                      type="select"
                      name="status"
                      value={status}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    >
                      <option value="">{t("common:choose")}</option>
                      {magicnum.OrderStatus.map((item, index) => {
                        return (
                          <option value={item} key={index}>
                            {t(`common:magicnum.OrderStatus.${item}`)}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>{t("Customer.rd")}</Label>
                    <Input
                      type="text"
                      name="rd"
                      value={rd}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>{t("Customer.mobile")}</Label>
                    <Input
                      type="text"
                      name="mobile"
                      value={mobile}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>

        <Card className="nopadding">
          <CardHeader>{t("main:Order.Title")}</CardHeader>
          <CardBody>
            <div>
              {apierror && (
                <UncontrolledAlert color="danger">
                  {apierrormsg}
                </UncontrolledAlert>
              )}
              <div>
                <CustomBootsrapTable
                  data={this.state.data}
                  table={table}
                  PTotal={this.state.PTotal}
                  pageChange={this.onChangePage}
                  PID={this.state.PID}
                  loading={this.state.loading}
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withTranslation("main")(OrderListFunc);
