import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  UncontrolledAlert,
  Button,
  Table,
} from "reactstrap";
import classnames from "classnames";
import { withTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";

import { setPageTitle, OrderGet, CDN_URL } from "../../services/main"; //serverluu handah huseltiin ner san   !!!!! end import hine
import { magicnum, BaseRequest } from "../../services/Request"; //serverluu ilgeeh huselt        !!!!!
import {
  isNullOrEmpty,
  getUser,
  LastAndFirstName,
  dateFormat,
  moneyFormat,
} from "../../components/Utils/Utils";
import { Link } from "react-router-dom";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class OrderGetFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      loading: false,
      apierrormsg: "",
      apierror: false,
      activeTab: "1",
      item: null,
      userData: [],
      products: [],
      dataSet1: [],
      dataSet2: [],
    };
    this.getMore = this.getMore.bind(this);
    this.onGetSuccess = this.onGetSuccess.bind(this);
    this.onFailed = this.onFailed.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    this.getMore();
  }

  getMore() {
    this.setState({ loading: true });
    OrderGet(
      this.props.match.params.id,
      BaseRequest,
      this.onGetSuccess,
      this.onFailed,
      "GET"
    );
  }

  onGetSuccess(response) {
    const { t } = this.state;
    console.log(response);
    var dataSet1 = [];
    var dataSet2 = [];
    response.products.map((uu, index) => {
      dataSet1.push({
        name: uu.product_type == "tatlaga" ? uu.tname : uu.sname,
        code: uu.product_type == "tatlaga" ? uu.tcode : uu.scode,
        songolt1:
          uu.product_type == "tatlaga"
            ? t(`common:magicnum.ProductTatlagaJin.${uu.songolt1}`)
            : t(`common:magicnum.ProductSambarHemjee.${uu.songolt1}`),
        songolt2:
          uu.product_type == "tatlaga"
            ? t(`common:magicnum.ProductTatlagaUrt.${uu.songolt2}`)
            : t(`common:magicnum.ProductSambarMaterial.${uu.songolt2}`),
        product_type: uu.product_type,
        price: moneyFormat(uu.price, "MNT"),
        too: uu.too,
        niit: moneyFormat(uu.price * uu.too, "MNT"),
      });
    });
    dataSet2.push({
      id: response.data.id,
      status: t(`common:magicnum.OrderStatus.${response.data.status}`),
      price: moneyFormat(response.data.total_price, "MNT"),
      rd: response.data.rd,
      mobile: response.data.mobile,
      telephone: response.data.telephone,
      holbogdoh_hun: response.data.holbogdoh_hun,
    });
    this.setState(
      {
        loading: false,
        item: response.data,
        products: response.products,
        dataSet1,
        dataSet2,
      },
      function () {
        setPageTitle(this.state.item.id);
      }
    );
  }

  onFailed(error) {
    this.setState({ loading: false, apierror: true, apierrormsg: error }); //amjiltgui uid aldaanii msg-g onooj bn.
  }

  toggle(tab) {
    this.setState({ activeTab: tab });
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  imgRowFormat(row) {
    var newName =
      row.product_type == "tatlaga"
        ? row.timage.split(".")
        : row.simage.split(".");
    return (
      <div className="text-center">
        <img
          src={CDN_URL + newName[1] + "-thumb." + newName[2]}
          height={40}
          width={40}
          style={{ borderRadius: "50%", objectFit: "cover" }}
          onError={(e) => (e.target.src = require("../../img/no-image.png"))}
        />
      </div>
    );
  }

  render() {
    const {
      t,
      loading,
      activeTab,
      item,
      apierror,
      apierrormsg,
      products,
      dataSet1,
      dataSet2,
    } = this.state;
    return (
      <div className="animated fadeIn getorder-container">
        <Card>
          <CardHeader style={{ padding: "10px 20px" }}>
            <i className="fa fa-align-justify" />
            {isNullOrEmpty(item) ? null : item.name} {t("common:more")}
            <div className="float-right">
              <Link to={`/order`}>
                <Button
                  className="button more-button"
                  color="success"
                  title={t("common:more")}
                  outline
                >
                  {t("common:backList")}
                </Button>
              </Link>
            </div>
          </CardHeader>
          <CardBody>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              style={{ zIndex: 1999, top: 40 }}
            />
            {apierror && (
              <UncontrolledAlert color="danger">
                {apierrormsg}
              </UncontrolledAlert>
            )}
            {loading ? (
              <Spinner />
            ) : isNullOrEmpty(item) ? (
              <div>{t("common:NotFound")}</div>
            ) : (
              <Row>
                <Col xs="3">
                  <div>
                    <div className="card card-custom">
                      <div className="card-body py-4">
                        <div className="form-group row my-2">
                          <label className="col-6 col-form-label">
                            {t("Order.id")} :
                          </label>
                          <div className="col-6">
                            <span className="form-control-plaintext">
                              <span className="label label-inline label-danger label-bold">
                                NO-{item.id}
                              </span>
                            </span>
                          </div>
                        </div>

                        <div className="form-group row my-2">
                          <label className="col-6 col-form-label">
                            {t("Order.hariutssan_hun")} :
                          </label>
                          <div className="col-6">
                            <span className="form-control-plaintext">
                              <span className="label label-inline label-danger label-bold">
                                {LastAndFirstName(
                                  item.lastname,
                                  item.firstname
                                )}
                              </span>
                            </span>
                          </div>
                        </div>

                        <div className="form-group row my-2">
                          <label className="col-6 col-form-label">
                            {t("Order.tolov")} :
                          </label>
                          <div className="col-6">
                            <span className="form-control-plaintext">
                              <span className="label label-inline label-danger label-bold">
                                {t(
                                  `common:magicnum.OrderStatus.${item.status}`
                                )}
                              </span>
                            </span>
                          </div>
                        </div>

                        <div className="form-group row my-2">
                          <label className="col-6 col-form-label">
                            {t("Order.total_price")} :
                          </label>
                          <div className="col-6">
                            <span className="form-control-plaintext">
                              <span className="label label-inline label-danger label-bold">
                                {moneyFormat(item.total_price, "MNT")}
                              </span>
                            </span>
                          </div>
                        </div>

                        <div className="form-group row my-2">
                          <label className="col-6 col-form-label">
                            {t("Customer.rd")} :
                          </label>
                          <div className="col-6">
                            <span className="form-control-plaintext">
                              <span className="label label-inline label-danger label-bold">
                                {item.rd}
                              </span>
                            </span>
                          </div>
                        </div>

                        <div className="form-group row my-2">
                          <label className="col-6 col-form-label">
                            {t("Customer.mobile")} :
                          </label>
                          <div className="col-6">
                            <span className="form-control-plaintext">
                              <span className="label label-inline label-danger label-bold">
                                {item.mobile}
                              </span>
                            </span>
                          </div>
                        </div>

                        <div className="form-group row my-2">
                          <label className="col-6 col-form-label">
                            {t("Customer.telephone")} :
                          </label>
                          <div className="col-6">
                            <span className="form-control-plaintext">
                              <span className="label label-inline label-danger label-bold">
                                {item.telephone}
                              </span>
                            </span>
                          </div>
                        </div>

                        <div className="form-group row my-2">
                          <label className="col-6 col-form-label">
                            {t("Customer.holbogdoh_hun")} :
                          </label>
                          <div className="col-6">
                            <span className="form-control-plaintext">
                              <span className="label label-inline label-danger label-bold">
                                {item.holbogdoh_hun}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs="9">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          this.toggle("1");
                        }}
                      >
                        {t("Order.products")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          this.toggle("2");
                        }}
                      >
                        {t("common:excel")}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Table>
                        <thead>
                          <tr>
                            <th>№</th>
                            <th>{t("Order.product.image")}</th>
                            <th>{t("Order.product.name")}</th>
                            <th>{t("product.code")}</th>
                            <th>{t("Order.product.songolt1")}</th>
                            <th>{t("Order.product.songolt2")}</th>
                            <th>{t("Order.product.type")}</th>
                            <th>{t("Order.product.price")}</th>
                            <th>{t("Order.product.too")}</th>
                            <th>{t("Order.product.total_price")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {products.map((uu, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{this.imgRowFormat(uu)}</td>
                                <td>
                                  {uu.product_type == "tatlaga"
                                    ? uu.tname
                                    : uu.sname}
                                </td>
                                <td>
                                  {uu.product_type == "tatlaga"
                                    ? uu.tcode
                                    : uu.scode}
                                </td>
                                <td>
                                  {uu.product_type == "tatlaga" ? (
                                    <span style={{ fontSize: 11 }}>
                                      {t(
                                        `common:magicnum.ProductTatlagaJin.${uu.songolt1}`
                                      )}
                                    </span>
                                  ) : (
                                    <span style={{ fontSize: 11 }}>
                                      {t(
                                        `common:magicnum.ProductSambarHemjee.${uu.songolt1}`
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td>
                                  {uu.product_type == "tatlaga" ? (
                                    <span style={{ fontSize: 11 }}>
                                      {t(
                                        `common:magicnum.ProductTatlagaUrt.${uu.songolt2}`
                                      )}
                                    </span>
                                  ) : (
                                    <span style={{ fontSize: 11 }}>
                                      {t(
                                        `common:magicnum.ProductSambarMaterial.${uu.songolt2}`
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td>{uu.product_type}</td>
                                <td>{moneyFormat(uu.price, "MNT")}</td>
                                <td>{uu.too}</td>
                                <td>{moneyFormat(uu.price * uu.too, "MNT")}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </TabPane>
                    <TabPane tabId="2">
                      <ExcelFile element={<button>Татах</button>}>
                        <ExcelSheet data={dataSet2} name="Захиалгын мэдээлэл">
                          <ExcelColumn label={t("Order.id")} value="id" />
                          <ExcelColumn
                            label={t("Order.tolov")}
                            value="status"
                          />
                          <ExcelColumn
                            label={t("Order.total_price")}
                            value="price"
                          />
                          <ExcelColumn label={t("Customer.rd")} value="rd" />
                          <ExcelColumn
                            label={t("Customer.mobile")}
                            value="mobile"
                          />
                          <ExcelColumn
                            label={t("Customer.telephone")}
                            value="telephone"
                          />
                          <ExcelColumn
                            label={t("Customer.holbogdoh_hun")}
                            value="holbogdoh_hun"
                          />
                        </ExcelSheet>
                        <ExcelSheet data={dataSet1} name="Захиалсан бараанууд">
                          <ExcelColumn label="Барааны нэр" value="name" />
                          <ExcelColumn label="Код" value="code" />
                          <ExcelColumn
                            label={t("Order.product.songolt1")}
                            value="songolt1"
                          />
                          <ExcelColumn
                            label={t("Order.product.songolt2")}
                            value="songolt2"
                          />
                          <ExcelColumn label="Төрөл" value="product_type" />
                          <ExcelColumn label="Үнэ" value="price" />
                          <ExcelColumn label="Тоо" value="too" />
                          <ExcelColumn label="Нийт" value="niit" />
                        </ExcelSheet>
                      </ExcelFile>
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withTranslation("main")(OrderGetFunc);
