import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  UncontrolledAlert,
  Button,
} from "reactstrap";
import classnames from "classnames";
import { withTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";

import { setPageTitle, NewsGet, CDN_URL } from "../../services/main"; //serverluu handah huseltiin ner san   !!!!! end import hine
import { BaseRequest } from "../../services/Request"; //serverluu ilgeeh huselt        !!!!!
import { isNullOrEmpty } from "../../components/Utils/Utils";
import { Link } from "react-router-dom";
import ProductContent from "../Product/ProductContent";
class NewsGetFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      loading: false,
      apierrormsg: "",
      apierror: false,
      activeTab: "1",
      item: null,
      userData: [],
    };
    this.getMore = this.getMore.bind(this);
    this.onGetSuccess = this.onGetSuccess.bind(this);
    this.onFailed = this.onFailed.bind(this);

    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    this.getMore();
  }

  getMore() {
    this.setState({ loading: true });
    NewsGet(
      this.props.match.params.id,
      BaseRequest,
      this.onGetSuccess,
      this.onFailed,
      "GET"
    );
  }

  onGetSuccess(response) {
    this.setState(
      {
        loading: false,
        item: response.data,
      },
      function () {
        setPageTitle(this.state.item.name);
      }
    );
  }

  onFailed(error) {
    this.setState({ loading: false, apierror: true, apierrormsg: error }); //amjiltgui uid aldaanii msg-g onooj bn.
  }

  toggle(tab) {
    this.setState({ activeTab: tab });
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  render() {
    const { t, loading, activeTab, item, apierror, apierrormsg } = this.state;
    var newName = null;
    if (!isNullOrEmpty(item)) {
      newName = item.image_link.split(".");
    }
    return (
      <div className="animated fadeIn getorder-container">
        <Card>
          <CardHeader style={{ padding: "10px 20px" }}>
            <i className="fa fa-align-justify" />
            {isNullOrEmpty(item) ? null : item.name} {t("common:more")}
            <div className="float-right">
              <Link to={`/News`}>
                <Button
                  className="button more-button"
                  color="success"
                  title={t("common:more")}
                  outline
                >
                  {t("common:backList")}
                </Button>
              </Link>
            </div>
          </CardHeader>
          <CardBody>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              style={{ zIndex: 1999, top: 40 }}
            />
            {apierror && (
              <UncontrolledAlert color="danger">
                {apierrormsg}
              </UncontrolledAlert>
            )}
            {loading ? (
              <Spinner />
            ) : isNullOrEmpty(item) ? (
              <div>{t("common:NotFound")}</div>
            ) : (
              <Row>
                <Col xs="3">
                  <div>
                    <div className="card card-custom">
                      <div className="card-body py-4">
                        <div
                          className="form-group row my-2"
                          style={{ margin: "0 auto" }}
                        >
                          {isNullOrEmpty(newName) ? null : (
                            <img
                              src={CDN_URL + newName[1] + "." + newName[2]}
                              // height={250}
                              width="100%"
                              style={{
                                objectFit: "cover",
                              }}
                              onError={(e) =>
                                (e.target.src = require("../../img/no-image.png"))
                              }
                            />
                          )}
                        </div>
                        <div className="form-group row my-2">
                          <label className="col-5 col-form-label">
                            {t("News.name")} :
                          </label>
                          <div className="col-7">
                            <span className="form-control-plaintext">
                              <span className="label label-inline label-danger label-bold">
                                {item.name}
                              </span>
                            </span>
                          </div>
                        </div>

                        <div className="form-group row my-2">
                          <div className="col-7">
                            <span className="form-control-plaintext">
                              <span className="label label-inline label-danger label-bold">
                                {item.description}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs="9">
                  <ProductContent item={item} t={t} type="news" />
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withTranslation("main")(NewsGetFunc);
