import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  UncontrolledAlert,
} from "reactstrap";
import {
  magicnum,
  ProductCranUpdateReq,
} from "../../services/Request";
import {
  ProductCranUpdate,
  ProductCranAdd,
} from "../../services/main";
import { ModalManager } from "react-dynamic-modal";
import { isNullOrEmpty } from "../../components/Utils/Utils.js";
class BurtgelAddFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      item: this.props.item,
      image: "",
      name: "",
      torol: "",
      brand: 0,
      validate: [],
    };
  }

  componentDidMount() {
    const { item } = this.state;
    if (item !== null) {
      this.setState({
        name: item.name,
        torol: item.torol,
        brand: item.brand,
        id: item.id,
      });
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  save = () => {
    const {
      t,
      name,
      torol,
      brand     
    } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      this.setState({ isSave: true });
      const image = document.querySelector("input[type=file]").files[0];
      var formdata = new FormData();
      formdata.append("image", image);    

      var cran = {
        name: name,
        torol: torol,        
        brand: brand        
      };
      formdata.append("cran", JSON.stringify(cran));
      ProductCranAdd(formdata, this.onSuccess, this.onFailed, "POST", "file");
    }
  };

  onSuccess = (response) => {
    const { t } = this.state;
    this.props.addSuccess(t("common:SuccessSave"));
    ModalManager.close();
  };

  Update = () => {
    const {
      t,
      name,      
      torol,
      brand,
      id
    } = this.state;
    var res = this.validate();
    if (res.retType === 0) {
      this.setState({ isSave: true });
      var req = ProductCranUpdateReq;
      req.cran.name = name;
      req.cran.torol = torol;
      req.cran.brand = brand;     
      req.cran.id = id;
      ProductCranUpdate(req, this.onUpdatSuccess, this.onFailed, "PUT");
    }
  };

  onUpdatSuccess = (response) => {
    const { t } = this.state;
    this.props.addSuccess(t("common:SuccessEdit"));
    ModalManager.close();
  };

  onFailed = (error) => {
    this.setState({
      loading: false,
      apierror: true,
      Industryloading: false,
      apierrormsg: error,
      isSave: false,
    });
  };

  setValidate = (id, e) => {
    var val = "";
    e.target === undefined ? (val = e) : (val = e.target.value);
    const { validate } = this.state;
    if (val !== "") {
      validate[id] = false;
    } else {
      validate[id] = true;
    }
    this.setState({ validate });
  };

  validate = () => {
    var res = { retType: 0 };
    const {
      validate,
      item,
      name,
      torol
    } = this.state;
    if (isNullOrEmpty(item)) {
      const myFile = document.querySelector("input[type=file]").files[0];
      if (isNullOrEmpty(myFile)) {
        res.retType = 1;
        validate["image"] = true;
      }
    }

    if (name == "") {
      res.retType = 1;
      validate["name"] = true;
    }    

    if (torol == "") {
      res.retType = 1;
      validate["torol"] = true;
    }    

    this.setState({ validate });
    return res;
  };

  render() {
    const {
      t,
      validate,
      apierror,
      apierrormsg,
      item,
      name,
      image,
      torol,
      brand,
    } = this.state;

    return (
      <div className="animated fadeIn">
        {apierror && (
          <UncontrolledAlert color="danger" className="alertDiv">
            {apierrormsg}
          </UncontrolledAlert>
        )}
        <div>
          <Row>
            <Col>
              <FormGroup>
                <Label>{t("product.cran.torol")}</Label>
                <Input
                  type="select"
                  name="torol"
                  value={torol}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("torol", event);
                  }}
                  invalid={validate["torol"]}
                >
                  <option value="">{t("common:choose")}</option>
                  {magicnum.ProductCranTorol.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {t(`common:magicnum.ProductCranTorol.${item}`)}
                      </option>
                    );
                  })}
                </Input>
                <FormFeedback>{t("common:SelectValid")}</FormFeedback>
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Label>{t("product.cran.name")}</Label>
                <Input
                  type="text"
                  name="name"
                  value={name}
                  onChange={(event) => {
                    this.handleChange(event);
                    this.setValidate("name", event);
                  }}
                  invalid={validate["name"]}
                />
                <FormFeedback>{t("common:InputValid")}</FormFeedback>
              </FormGroup>
            </Col>
            {isNullOrEmpty(item) ? (
              <Col>
                <FormGroup>
                  <Label>{t("product.cran.image")}</Label>
                  <div>
                    <label
                      htmlFor="files"
                      className="btn btn-secondary"
                      style={{ width: "100%" }}
                    >
                      {t("common:choose")}
                    </label>
                    <Input
                      type="file"
                      name="image"
                      value={image}
                      id="files"
                      style={{ display: "none" }}
                      accept=".pdf,.jpg,.png,.jpeg"
                      onChange={this.handleChange}
                      alt={t("product.cran.file")}
                    />
                  </div>
                  {validate["image"] ? (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {t("common:SelectValid")}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
            ) : null}

            <Col>
              <FormGroup>
                <Label>{t("product.brand")}</Label>
                <Input
                  type="select"
                  name="brand"
                  value={brand}
                  onChange={(event) => {
                    this.handleChange(event);
                  }}
                >
                  <option value="0">{t("common:choose")}</option>
                  {magicnum.ProductBrand.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {t(`common:magicnum.ProductBrand.${item}`)}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
            </Col>
          </Row>

          <Row className="action-row">
            <FormGroup className="saveBtn">
              <Button
                color="primary"
                onClick={item != null ? this.Update : this.save}
                disabled={this.state.isSave}
              >
                {t("common:save")}
              </Button>
            </FormGroup>
          </Row>
        </div>
      </div>
    );
  }
}

export default BurtgelAddFunc;
