import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Row,
  Col,
  Label
} from "reactstrap";
import {
  PrivilegeGroupGet,
  PrivilegeList,
  AddMPriv_GroupPriv,
  ListMPriv_GroupPriv,
  UpdateMPriv_GroupPriv
} from "../../../services/main";
import {
  PrivilegeGroupMoreReq,
  BaseRequest,
  AddMPriv_GroupPrivReq,
  ListMPriv_GroupPrivReq
} from "../../../services/Request";
import "./MorePrivGroup.scss";
import { ToastContainer, toast } from "react-toastify";
import { withTranslation } from "react-i18next";
class MorePrivGroup extends Component {
  constructor(props) {
    super(props);
    this.getMore = this.getMore.bind(this);
    this.getAllPrivs = this.getAllPrivs.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onFailed = this.onFailed.bind(this);
    this.onPrivSuccess = this.onPrivSuccess.bind(this);
    this.onPrivFailed = this.onPrivFailed.bind(this);
    this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
    this.save = this.save.bind(this);
    this.onPrivAddSuccess = this.onPrivAddSuccess.bind(this);
    this.onPrivAddFailed = this.onPrivAddFailed.bind(this);
    this.setActivePrivs = this.setActivePrivs.bind(this);
    this.onCheckBox = this.onCheckBox.bind(this);
    this.onGetPGSuccess = this.onGetPGSuccess.bind(this);
    this.onGetPGFailed = this.onGetPGFailed.bind(this);

    this.state = {
      sizePerPage: 500,
      loading: true,
      apierror: false,
      data: [],
      data2: [],
      apierrormsg: "",
      PID: 1,
      PTotal: 0,
      privs: [],
      ckeckedpriv: [],
      isAdd: true,
      t: this.props.t
    };
  }
  componentDidMount() {
    this.getMore();
    this.getAllPrivs();
    this.setActivePrivs();
  }

  setActivePrivs() {
    var req = ListMPriv_GroupPrivReq;
    ListMPriv_GroupPriv(
      this.props.match.params.id,
      req,
      this.onGetPGSuccess,
      this.onGetPGFailed,
      "GET"
    );
  }
  onGetPGSuccess(response) {
    const { privs, ckeckedpriv } = this.state;
    var k = 0;
    response.mPriv_GroupForLists.map((i, index) => {
      var priv = {};
      priv.privNo = i.PrivNo;
      priv.isCheck = i.ischeck;
      privs.push(priv);
      if (i.ischeck == 1) {
        ckeckedpriv.push(priv);
      }
      k++;
    });
    if (k > 0) {
      this.setState({ isAdd: false });
    }
    this.setState({ ckeckedpriv: ckeckedpriv });
  }
  onGetPGFailed(error) {
    this.setState({ apierror: true, apierrormsg: error });
  }
  getMore() {
    var req = PrivilegeGroupMoreReq;
    PrivilegeGroupGet(
      this.props.match.params.id,
      req,
      this.onSuccess,
      this.onFailed,
      "GET"
    );
  }

  getAllPrivs() {
    var req = BaseRequest;
    req.PID = this.state.PID;
    req.PSIZE = this.state.sizePerPage;
    PrivilegeList(req, this.onPrivSuccess, this.onPrivFailed, "GET");
  }
  onSuccess(response) {
    this.setState({
      data: response.mPriv_Group
    });
  }
  onFailed(error) {
    this.setState({ apierror: true, apierrormsg: error });
  }

  onPrivSuccess(response) {
    this.setState({
      data2: response.mPrivs
    });
  }
  onPrivFailed(error) {
    this.setState({ apierror: true, apierrormsg: error });
  }

  onChangeCheckBox(privNo, event) {
    const { ckeckedpriv } = this.state;
    var dd = false;
    if (ckeckedpriv != undefined) {
      ckeckedpriv.map((i, index) => {
        if (i.PrivNo == privNo && i.isCheck == 1) {
          dd = true;
        }
      });
    }
    var priv = {};
    priv.privNo = privNo;
    priv.isCheck = 1;
    if (!dd && event.target.checked) {
      ckeckedpriv.push(priv);
      this.setState({ ckeckedpriv: ckeckedpriv });
    } else {
      if (!event.target.checked) {
        ckeckedpriv.map((pri, index) => {
          if (pri.privNo === privNo) {
            ckeckedpriv.splice(index, 1);
            this.setState({ ckeckedpriv: ckeckedpriv });
            return false;
          }
        });
      }
    }
  }

  onCheckBox(privs, privNo) {
    var dd = false;
    if (privs != undefined) {
      privs.map((i, index) => {
        if (i.privNo == privNo) {
          dd = true;
        }
      });
    }
    return dd;
  }
  save() {
    const { ckeckedpriv, isAdd } = this.state;
    const gid = this.props.match.params.id;
    var req = AddMPriv_GroupPrivReq;
    req.privGroupId = gid;
    req.mPrivs = ckeckedpriv;
    isAdd
      ? AddMPriv_GroupPriv(
          req,
          this.onPrivAddSuccess,
          this.onPrivAddFailed,
          "POST"
        )
      : UpdateMPriv_GroupPriv(
          req,
          this.onPrivAddSuccess,
          this.onPrivAddFailed,
          "PUT"
        );
  }
  onPrivAddSuccess(response) {
    const { t } = this.state;
    return toast.success(t("common:SuccessEdit"));
  }
  onPrivAddFailed(error) {
    this.setState({ apierror: true, apierrormsg: error });
  }
  render() {
    const { data, data2, ckeckedpriv, t } = this.state;

    const containerStyle = {
      zIndex: 1999,
      top: 40
    };
    return (
      <div className="animated fadeIn more-privgroup-container">
        <ToastContainer
          position="top-right"
          autoClose={3000}
          style={containerStyle}
        />
        <Card>
          <CardHeader>{t("privGroup.privGroupMore")}</CardHeader>
          <CardBody>
            <Row>
              <Col md={4}>
                {t("privGroup.privGroupID")} : {data.PrivGroupID}
              </Col>
              <Col md={4}>
                {t("privGroup.privGroupName")} : {data.PrivGroupName}
              </Col>
              <Col md={4}>
                {t("privGroup.isUserGroup")} :{" "}
                {data.IsUserGroup == "Y" ? t("common:yes") : t("common:no")}
              </Col>
            </Row>
            <Row className="priv-container">
              {data2.map((priv, index) => (
                <div key={priv.PrivNo} className="priv">
                  <label className="switch switch-3d switch-primary">
                    <input
                      type="checkbox"
                      className="switch-input"
                      checked={this.onCheckBox(ckeckedpriv, priv.PrivNo)}
                      onChange={e =>
                        this.onChangeCheckBox(priv.PrivNo, e, true)
                      }
                    />
                    <span className="switch-label" />
                    <span className="switch-handle" />
                  </label>
                  <Label className="privNo">({priv.PrivNo})</Label>
                  <Label className="privname">{priv.PrivName}</Label>
                </div>
              ))}
            </Row>
            <Row>
              <Button
                className="custom"
                type="button"
                color="danger"
                onClick={this.save}
              >
                <i className="fa fa-check" /> {t("common:save")}
              </Button>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withTranslation("priv")(MorePrivGroup);
