import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {
  setPageTitle,
  DashboardToonMedeelel,
  DashboardByUser,
  CDN_URL,
  DashboardByHoroo,
  DashboardByStatus,
} from "../../services/main";
import {
  DashboardByUserReq,
  DashboardToonMedeelelReq,
  magicnum,
  DashboardByHorooReq,
  DashboardByStatusReq,
} from "../../services/Request";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Table,
  Spinner,
  Input,
  FormGroup,
} from "reactstrap";
import { isNullOrEmpty } from "../../components/Utils/Utils";
import { CountUp } from "use-count-up";
import { Link } from "react-router-dom";

class DashboardFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      loading: false,
      t: this.props.t,
      apierrormsg: "",
      apierror: false,
      toonData: null,
      years: [],
      year: new Date().getFullYear(),
      mounth: "",
      loadingHoroo: false,
      byStatusData: null,
    };
  }
  componentDidMount() {
    const years = [];
    for (let i = 2021; i <= this.state.year; i += 1) {
      years.push(i);
    }
    this.setState({ years });
    this.getToonMedee();
    this.getByStatus();
    setPageTitle(this.state.t("Dashboard.Title"));
  }

  getToonMedee = () => {
    if (!this.state.loading) {
      this.setState({ loading: true });
    }
    var req = DashboardToonMedeelelReq;
    req.filter.service_id = this.state.service_id;
    req.filter.year = this.state.year;
    req.filter.mounth = this.state.mounth;
    DashboardToonMedeelel(req, this.onTopSuccess, this.onFailed, "POST");
  };

  onTopSuccess = (response) => {
    console.log(response);
    this.setState({
      loading: false,
      toonData: response,
    });
  };

  onFailed = (error) => {
    this.setState({
      loading: false,
      apierror: true,
      apierrormsg: error,
    });
  };

  getByStatus = () => {
    if (!this.state.loadingStatus) {
      this.setState({ loadingStatus: true });
    }
    var req = DashboardByStatusReq;
    req.filter.service_id = this.state.service_id;
    req.filter.year = this.state.year;
    req.filter.mounth = this.state.mounth;
    DashboardByStatus(req, this.onByStatusSuccess, this.onFailed, "POST");
  };

  onByStatusSuccess = (response) => {
    console.log(response);
    this.setState({
      loadingStatus: false,
      byStatusData: response.data,
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, function () {
      if (name == "year" || name == "mounth") {
        this.getByStatus();
      }
    });
  };

  render() {
    const {
      t,
      toonData,
      apierror,
      apierrormsg,
      loading,
      year,
      mounth,
      years,
      loadingStatus,
      byStatusData,
    } = this.state;

    return (
      <div className="animated fadeIn">
        {apierror && (
          <div className="help-block text-danger">{apierrormsg}</div>
        )}
        <Card>
          <CardBody>
            <Row>
              <Col>
                <div className="callout callout-danger">
                  <span
                    className="text-muted"
                    style={{ textTransform: "uppercase" }}
                  >
                    {t("common:magicnum.FirstMenu.1")}
                  </span>
                  <br />
                  <strong className="h4">
                    {loading || isNullOrEmpty(toonData) ? (
                      ".."
                    ) : (
                      <CountUp isCounting end={toonData.sambar} duration={1} />
                    )}
                  </strong>
                </div>
              </Col>
              <Col>
                <div className="callout callout-success">
                  <span
                    className="text-muted"
                    style={{ textTransform: "uppercase" }}
                  >
                    {t("common:magicnum.FirstMenu.2")}
                  </span>
                  <br />
                  <strong className="h4">
                    {loading || isNullOrEmpty(toonData) ? (
                      ".."
                    ) : (
                      <CountUp isCounting end={toonData.tatlaga} duration={1} />
                    )}
                  </strong>
                </div>
              </Col>
              <Col>
                <div className="callout callout-warning">
                  <span
                    className="text-muted"
                    style={{ textTransform: "uppercase" }}
                  >
                    {t("common:magicnum.FirstMenu.3")}
                  </span>
                  <br />
                  <strong className="h4">
                    {loading || isNullOrEmpty(toonData) ? (
                      ".."
                    ) : (
                      <CountUp isCounting end={toonData.cran} duration={1} />
                    )}
                  </strong>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            {t("common:filter")}
            <div
              className="float-right"
              style={{
                marginTop: -6,
                marginBottom: -22,
                width: "80%",
              }}
            >
              <Row>
                <Col>
                  <FormGroup>
                    <Input
                      type="select"
                      name="year"
                      value={year}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    >
                      {years.map((item, index) => {
                        return (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>

                <Col>
                  <FormGroup>
                    <Input
                      type="select"
                      name="mounth"
                      value={mounth}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    >
                      <option value="">{t("common:chooseMounth")}</option>
                      {magicnum.Mounth.map((item, index) => {
                        return (
                          <option value={item} key={index}>
                            {item} {t("common:mounth")}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </CardHeader>
        </Card>
        <Card>
          <CardHeader>{t("common:bystatus")}</CardHeader>
          <CardBody>
            <div className="table-responsive">
              <div className="scroll">
                <Table>
                  <tbody>
                    {isNullOrEmpty(byStatusData) ? (
                      <tr>
                        <td style={{ textAlign: "center" }}>
                          <Spinner />
                        </td>
                      </tr>
                    ) : (
                      byStatusData.map((item, index) => {
                        var cl = "btn-success";

                        if (item.status == 1) {
                          cl = "btn-secondary";
                        } else if (item.status == 2) {
                          cl = "btn-info";
                        } else if (item.status == 3) {
                          cl = "btn-warning";
                        } else if (item.status == 4) {
                          cl = "btn-success";
                        } else if (item.status == 5) {
                          cl = "btn-danger";
                        }
                        return (
                          <tr key={index}>
                            <td>
                              <div
                                className={`${cl}`}
                                style={{
                                  width: "10px",
                                  height: "20px",
                                  float: "left",
                                  marginRight: "10px",
                                }}
                              />
                              <Link to={`/orders/${item.status}`}>
                                <span>
                                  {t(
                                    `common:magicnum.OrderStatus.${item.status}`
                                  )}
                                </span>
                              </Link>
                            </td>
                            <td>{item.too}</td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withTranslation("main")(DashboardFunc);
