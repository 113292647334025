import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {
  BagtsDelete,
  BagtsList,
  setPageTitle,
  CDN_URL,
  BagtsUpdateTolov,
} from "../../services/main";
import {
  BagtsListReq,
  BaseRequest,
  magicnum,
  BagtsUpdateTolovReq,
} from "../../services/Request";
import {
  Card,
  CardHeader,
  CardBody,
  UncontrolledAlert,
  Button,
  Label,
  Input,
  FormGroup,
  Row,
  Col,
} from "reactstrap";
import CustomBootsrapTable from "../../components/CustomBootsrapTable/CustomBootsrapTable";
import $ from "jquery";
import BagtsAddOrUpdate from "./BagtsAddOrUpdate";
import { ModalManager } from "react-dynamic-modal";
import MainModal from "../../components/Modal/MainModal/MainModal";
import { toast } from "react-toastify";
import { isNullOrEmpty } from "../../components/Utils/Utils";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
class BagtsListFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sizePerPage: 25, //neg huudsand haruulah too
      PID: 1,
      PTotal: 0,
      loading: false,
      data: [],
      t: this.props.t,
      name: "",
      apierrormsg: "",
      apierror: false,
      name: "",
      tolov: "",
      type: "",
      action_type: "",
    };
  }

  componentDidMount() {
    setPageTitle(this.props.t("bagts.Title"));
    this.getList();
  }

  getList = () => {
    if (!this.state.loading) {
      this.setState({ loading: true });
    }

    const { PID, sizePerPage, name, tolov, type } = this.state;
    var req = BagtsListReq;
    req.pid = PID;
    req.pSize = sizePerPage;
    req.filter.name = name;
    req.filter.tolov = tolov;
    req.filter.type = type;
    BagtsList(req, this.onSuccess, this.onFailed, "POST");
  };

  onSuccess = (response) => {
    this.setState({
      loading: false,
      PTotal: response.pagination.ptotal,
      data: response.data,
    });
  };

  onFailed = (error) => {
    this.setState({ loading: false, apierror: true, apierrormsg: error });
  };

  onChangePage = (pid) => {
    if (pid >= 1) {
      this.setState({ PID: pid, loading: true }, function () {
        this.getList();
      });
    }
  };

  filterToggle() {
    $("#filterTogle").toggle();
    var otherHeight = 160;
    var filterHeight = 0,
      othersapec = 65;
    if ($(".grid-filter").height() !== undefined) {
      filterHeight = $(".grid-filter").height() + othersapec;
    }
    $(".scroll").height(
      $(window).height() -
        $(".app-header").height() -
        otherHeight -
        filterHeight
    );
  }

  add = (type, row) => {
    const { t } = this.state;
    var title = t("bagts.Add");
    if (type == "update") {
      title = t("bagts.Update");
    }
    ModalManager.open(
      <MainModal
        responsive
        title={title}
        onRequestClose={() => true}
        component={
          <BagtsAddOrUpdate
            t={this.state.t}
            item={row}
            addSuccess={this.addSuccess}
          />
        }
      />
    );
  };

  addSuccess = (text) => {
    this.getList();
    return toast.success(text);
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  search = () => {
    this.setState({ PID: 1 }, function () {
      this.getList();
    });
  };

  tolovRowFormat = (cell, row) => {
    const { t } = this.state;
    var cl = "btn-success";
    var text = t("common:magicnum.Status.Active");
    if (cell == magicnum.Status.InActive) {
      cl = "btn-danger";
      text = t("common:magicnum.Status.InActive");
    }

    let edit = (
      <div className="text-center" style={{ marginLeft: -6 }}>
        <Button
          style={{ paddingLeft: 5, paddingRight: 5, borderRadius: 5 }}
          className={`button delete-button ${cl}`}
          onClick={() => {
            this.submit(row, "confirm");
          }}
          title={t("Service.confirm")}
        >
          {text}
        </Button>
      </div>
    );
    return edit;
  };

  typeRowFormat = (cell, row) => {
    const { t } = this.state;
    var edit = t(`common:magicnum.FirstMenu.${cell}`);
    return edit;
  };

  actionRowFormat = (cell, row) => {
    const { t } = this.state;
    let edit = (
      <div className="text-center" style={{ marginLeft: -12 }}>
        <Button
          onClick={() => {
            this.add("update", row);
          }}
          className="button edit-button"
          title={t("common:edit")}
          color="success"
          outline
        >
          <i className="fa fa-edit" />
        </Button>
        <Button
          className="button delete-button"
          onClick={() => {
            this.submit(row, "deleteMain");
          }}
          title={t("common:delete")}
          color="success"
          outline
        >
          <i className="fa fa-trash" />
        </Button>
      </div>
    );
    return edit;
  };

  nameRowFormat = (cell, row) => {
    let edit = <Link to={`/bagts/${row.id}`}>{cell}</Link>;
    return edit;
  };

  submit = (row, type) => {
    const { t } = this.state;
    var title = row.name + " " + t("common:delete");
    var message = t("common:confirmdelete");
    if (type == "confirm") {
      var title = row.name + " " + t("bagts.confirm");
      var message = t("common:confirm");
    } else if (type == "delete") {
      var title = row.name + " " + t("bagts.delete");
      var message = t("common:confirm");
    }

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-confirm-ui">
            <h1>{title.substr(0, 30)}...</h1>
            <p>{message}</p>
            {type == "confirm" ? (
              <Input
                type="select"
                name="action_type"
                onChange={(event) => {
                  this.handleChange(event);
                }}
                style={{ marginTop: 20, marginBottom: 40 }}
              >
                <option value="">{t("common:choose")}</option>
                <option value={magicnum.Status.Active}>
                  {t("common:magicnum.Status.Active")}
                </option>
                <option value={magicnum.Status.InActive}>
                  {t("common:magicnum.Status.InActive")}
                </option>
              </Input>
            ) : null}
            <Button outline onClick={onClose}>
              {t("common:no")}
            </Button>
            <Button
              color="danger"
              onClick={() => {
                type == "deleteMain" ? this.delete(row) : this.Confirm(row);
                onClose();
              }}
            >
              {t("common:yes")}
            </Button>
          </div>
        );
      },
    });
  };

  delete = (row) => {
    BagtsDelete(
      row.id,
      BaseRequest,
      this.onDeleteSuccess,
      this.onFailed,
      "DELETE"
    ); //serverluu huselt ilgeej bn  !!!!!
  };

  onDeleteSuccess = (response) => {
    const { t } = this.state;
    this.getList();
    return toast.error(t("common:SuccessDelete"));
  };

  Confirm = (row) => {
    var isValidate = true;
    const { action_type, t } = this.state;

    if (isNullOrEmpty(action_type)) {
      isValidate = false;
      return toast.error(t("bagts.SelectActionType"));
    }
    if (isValidate) {
      var req = BagtsUpdateTolovReq;
      req.bagts.tolov = action_type;
      req.bagts.id = row.id;
      BagtsUpdateTolov(req, this.onConfirmSuccess, this.onFailed, "PUT");
    }
  };

  onConfirmSuccess = (response) => {
    const { t } = this.state;
    this.getList();
    return toast.success(t("common:SuccessEdit"));
  };

  imgRowFormat(cell, row) {
    var newName = cell.split(".");
    return (
      <div className="text-center">
        <img
          src={CDN_URL + newName[1] + "-thumb." + newName[2]}
          height={40}
          width={40}
          style={{ borderRadius: "50%", objectFit: "cover" }}
          onError={(e) => (e.target.src = require("../../img/no-image.png"))}
        />
      </div>
    );
  }

  render() {
    const { t, apierror, apierrormsg, type, tolov, name } = this.state;
    var table = {
      sizePerPage: this.state.sizePerPage,
      headerKeyColumn: {
        title: "id",
        dataField: "id",
        dataSort: true,
        dataFormat: this.defaultRowFormat,
        hidden: true,
        filter: {
          type: "TextFilter",
          placeholder: "...",
        },
      },
      headerOtherColumn: [
        {
          title: t("bagts.image"),
          dataField: "image_link",
          dataSort: false,
          dataFormat: this.imgRowFormat,
          hidden: false,
          width: "100px",
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("bagts.name"),
          dataField: "name",
          dataSort: false,
          dataFormat: this.nameRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("bagts.type"),
          dataField: "type",
          dataSort: false,
          dataFormat: this.typeRowFormat,
          hidden: false,
          width: "210px",
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("bagts.tolov"),
          dataField: "tolov",
          dataSort: false,
          dataFormat: this.tolovRowFormat,
          hidden: false,
          width: "130px",
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: "",
          dataField: "id",
          dataSort: false,
          dataFormat: this.actionRowFormat,
          hidden: false,
          width: "120px",
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
      ],
    };
    return (
      <div className="animated fadeIn">
        <Card className="grid-filter">
          <CardHeader>
            <span className="filterToggle" onClick={() => this.filterToggle()}>
              <i className="fa fa-filter" />
              {t("common:filter")}
            </span>
            <div className="float-right">
              <span className="btn btn-sm btn-primary" onClick={this.search}>
                {t("common:search")}
              </span>
            </div>
          </CardHeader>
          <CardBody id="filterTogle" style={{ display: "none" }}>
            <div className="filter">
              <Row>
                <Col>
                  <FormGroup>
                    <Label>{t("bagts.name")}</Label>
                    <Input
                      type="text"
                      name="name"
                      value={name}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>{t("bagts.type")}</Label>
                    <Input
                      type="select"
                      name="type"
                      value={type}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    >
                      <option value="">{t("common:choose")}</option>
                      <option value={magicnum.FirstMenu[0]}>
                        {t("common:magicnum.FirstMenu.1")}
                      </option>
                      <option value={magicnum.FirstMenu[1]}>
                        {t("common:magicnum.FirstMenu.2")}
                      </option>
                      <option value={magicnum.FirstMenu[2]}>
                        {t("common:magicnum.FirstMenu.3")}
                      </option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>{t("bagts.tolov")}</Label>
                    <Input
                      type="select"
                      name="tolov"
                      value={tolov}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    >
                      <option value="">{t("common:choose")}</option>
                      <option value={magicnum.Status.Active}>
                        {t("common:magicnum.Status.Active")}
                      </option>
                      <option value={magicnum.Status.InActive}>
                        {t("common:magicnum.Status.InActive")}
                      </option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>

        <Card className="nopadding">
          <CardHeader>
            {t("bagts.Title")}
            <div className="float-right">
              <button
                onClick={() => {
                  this.add("new", null);
                }}
                className="btn btn-sm btn-primary"
              >
                {t("common:add")}
              </button>
            </div>
          </CardHeader>
          <CardBody>
            <div>
              {apierror && (
                <UncontrolledAlert color="danger">
                  {apierrormsg}
                </UncontrolledAlert>
              )}
              <div>
                <CustomBootsrapTable
                  data={this.state.data}
                  table={table}
                  PTotal={this.state.PTotal}
                  pageChange={this.onChangePage}
                  PID={this.state.PID}
                  loading={this.state.loading}
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withTranslation("main")(BagtsListFunc);
