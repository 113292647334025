import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  UncontrolledAlert,
  Button,
} from "reactstrap";
import classnames from "classnames";
import { withTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";

import { setPageTitle, ProductSambarGet, CDN_URL } from "../../services/main"; //serverluu handah huseltiin ner san   !!!!! end import hine
import { BaseRequest } from "../../services/Request"; //serverluu ilgeeh huselt        !!!!!
import { isNullOrEmpty,moneyFormat } from "../../components/Utils/Utils";
import { Link } from "react-router-dom";
import SambarPriceList from "./Price/SambarPriceAddOrUpdate";
import Images from "./Images/ProductImages";
import ProductContent from "./ProductContent";

class ProductSambarGetFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
      loading: false,
      apierrormsg: "",
      apierror: false,
      activeTab: "1",
      item: null,
      userData: [],
    };
    this.getMore = this.getMore.bind(this);
    this.onGetSuccess = this.onGetSuccess.bind(this);
    this.onFailed = this.onFailed.bind(this);

    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    this.getMore();
  }

  getMore() {
    this.setState({ loading: true });
    ProductSambarGet(
      this.props.match.params.id,
      BaseRequest,
      this.onGetSuccess,
      this.onFailed,
      "GET"
    );
  }

  onGetSuccess(response) {
    this.setState(
      {
        loading: false,
        item: response.data,
      },
      function () {
        setPageTitle(this.state.item.name);
      }
    );
  }

  onFailed(error) {
    this.setState({ loading: false, apierror: true, apierrormsg: error }); //amjiltgui uid aldaanii msg-g onooj bn.
  }

  toggle(tab) {
    this.setState({ activeTab: tab });
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  render() {
    const { t, loading, activeTab, item, apierror, apierrormsg } = this.state;
    var newName = null;
    if (!isNullOrEmpty(item)) {
      newName = item.image_link.split(".");
    }
    return (
      <div className="animated fadeIn getorder-container">
        <Card>
          <CardHeader style={{ padding: "10px 20px" }}>
            <i className="fa fa-align-justify" />
            {isNullOrEmpty(item) ? null : item.name} {t("common:more")}
            <div className="float-right">
              <Link to={`/product-sambar`}>
                <Button
                  className="button more-button"
                  color="success"
                  title={t("common:more")}
                  outline
                >
                  {t("common:backList")}
                </Button>
              </Link>
            </div>
          </CardHeader>
          <CardBody>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              style={{ zIndex: 1999, top: 40 }}
            />
            {apierror && (
              <UncontrolledAlert color="danger">
                {apierrormsg}
              </UncontrolledAlert>
            )}
            {loading ? (
              <Spinner />
            ) : isNullOrEmpty(item) ? (
              <div>{t("common:NotFound")}</div>
            ) : (
              <Row>
                <Col xs="3">
                  <div>
                    <div className="card card-custom">
                      <div className="card-body py-4">
                        <div
                          className="form-group row my-2"
                          style={{ margin: "0 auto" }}
                        >
                          {isNullOrEmpty(newName) ? null : (
                            <img
                              src={CDN_URL + newName[1] + "." + newName[2]}
                              // height={250}
                              width="100%"
                              style={{
                                objectFit: "cover",
                              }}
                              onError={(e) =>
                                (e.target.src = require("../../img/no-image.png"))
                              }
                            />
                          )}
                        </div>
                        <div className="form-group row my-2">
                          <label className="col-5 col-form-label">
                            {t("product.code")} :
                          </label>
                          <div className="col-7">
                            <span className="form-control-plaintext">
                              <span className="label label-inline label-danger label-bold">
                                {item.code}
                              </span>
                            </span>
                          </div>
                        </div>

                        <div className="form-group row my-2">
                          <label className="col-5 col-form-label">
                            {t("product.sambar.name")} :
                          </label>
                          <div className="col-7">
                            <span className="form-control-plaintext">
                              <span className="label label-inline label-danger label-bold">
                                {item.name}
                              </span>
                            </span>
                          </div>
                        </div>

                        <div className="form-group row my-2">
                          <label className="col-5 col-form-label">
                            {t("product.brand")} :
                          </label>
                          <div className="col-7">
                            <span className="form-control-plaintext">
                              <span className="label label-inline label-danger label-bold">
                                {t(
                                  `common:magicnum.ProductBrand.${item.brand}`
                                )}
                              </span>
                            </span>
                          </div>
                        </div>

                        <div className="form-group row my-2">
                          <label className="col-5 col-form-label">
                            {t("product.sambar.torol")} :
                          </label>
                          <div className="col-7">
                            <span className="form-control-plaintext">
                              <span className="label label-inline label-danger label-bold">
                                {t(
                                  `common:magicnum.ProductSambarTorol.${item.torol}`
                                )}
                              </span>
                            </span>
                          </div>
                        </div>

                        <div className="form-group row my-2">
                          <label className="col-5 col-form-label">
                            {t("product.sambar.subtorol")} :
                          </label>
                          <div className="col-7">
                            <span className="form-control-plaintext">
                              <span className="label label-inline label-danger label-bold">
                                {t(
                                  `common:magicnum.ProductSambarSubTorol.${item.subtorol}`
                                )}
                              </span>
                            </span>
                          </div>
                        </div>

                        {item.torol == 4 ? (
                          <div className="form-group row my-2">
                            <label className="col-5 col-form-label">
                              {t("product.sambar.tolov")} :
                            </label>
                            <div className="col-7">
                              <span className="form-control-plaintext">
                                <span className="label label-inline label-danger label-bold">
                                  {t(
                                    `common:magicnum.ProductTatlagaTolov.${item.tolov}`
                                  )}
                                </span>
                              </span>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs="9">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          this.toggle("1");
                        }}
                      >
                        {t("product.sambar.price.Title")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          this.toggle("2");
                        }}
                      >
                        {t("product.images.Title")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "3" })}
                        onClick={() => {
                          this.toggle("3");
                        }}
                      >
                        {t("common:tailbar")}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      {activeTab == "1" && item.torol !== 4 ? (
                        <SambarPriceList prod={item} t={t}/>
                      ) : (
                        <span style={{ fontSize: 16 }}>
                          {moneyFormat(item.price, "MNT")}
                        </span>
                      )}
                    </TabPane>
                    <TabPane tabId="2">
                      {activeTab == "2" ? (
                        <Images item={item} t={t} product_type={1} />
                      ) : null}
                    </TabPane>
                    <TabPane tabId="3">
                      {activeTab == "3" ? (
                        <ProductContent item={item} t={t} type="sambar" />
                      ) : null}
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withTranslation("main")(ProductSambarGetFunc);
