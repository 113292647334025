import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody
} from "reactstrap";
import { PrivilegeList, PrivilegeDelete } from "../../../services/main";
import { BaseRequest, PrivilegeDeleteReq } from "../../../services/Request";
import CustomBootsrapTable from "../../../components/CustomBootsrapTable/CustomBootsrapTable";
import { ModalManager } from "react-dynamic-modal";
import MainModal from "../../../components/Modal/MainModal/MainModal";
import { ToastContainer, toast } from "react-toastify";
import AddPriv from "./AddPriv";
import { withTranslation } from "react-i18next";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
class PrivList extends Component {
  constructor(props) {
    super(props);
    this.getList = this.getList.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onFailed = this.onFailed.bind(this);
    this.addSuccess = this.addSuccess.bind(this);

    this.customRowFormat = this.customRowFormat.bind(this);
    this.onDeleteFailed = this.onDeleteFailed.bind(this);
    this.onDeleteSuccess = this.onDeleteSuccess.bind(this);
    this.state = {
      sizePerPage: 500,
      loading: true,
      apierror: false,
      data: [],
      t: this.props.t,
      apierrormsg: "",
      PID: 1,
      PTotal: 0,
      popoverOpen: [false, false],
      popoverOpenIndex: 1
    };
  }
  componentDidMount() {
    this.getList();
  }

  //#region API
  getList() {
    if (!this.state.loading) {
      this.setState({ loading: true });
    }
    var req = BaseRequest;
    req.PID = this.state.PID;
    req.PSIZE = this.state.sizePerPage;
    PrivilegeList(req, this.onSuccess, this.onFailed, "GET");
  }

  delete(row) {
    PrivilegeDelete(
      row.PrivNo,
      BaseRequest,
      this.onDeleteSuccess,
      this.onFailed,
      "DELETE"
    ); //serverluu huselt ilgeej bn  !!!!!
  }

  onDeleteSuccess(response) {
    const { t } = this.state;
    this.getList();
    return toast.error(t("common:SuccessDelete"));
  }

  onSuccess(response) {
    this.setState({
      loading: false,
      PTotal: 1,
      data: response.mPrivs
    });
  }
  //#endregion

  onFailed(error) {
    this.setState({ loading: false, apierror: true, apierrormsg: error });
  }

  onChangePage(pid) {
    if (pid >= 1) {
      this.setState({ PID: pid }, function () {
        this.getList();
      });
    }
  }
  addSuccess(text) {
    this.getList();
    return toast.success(text);
  }
  submit(row) {
    const { t } = this.state;
    confirmAlert({
      title: row.PrivName + " " + t("common:delete"),
      message: t("common:confirmdelete"),
      buttons: [
        {
          label: t("common:yes"),
          onClick: () => this.delete(row)
        },
        {
          label: t("common:no")
        }
      ]
    });
  }
  customRowFormat(cell, row) {
    const { t, popoverOpenIndex } = this.state;
    let edit = (
      <div className="text-center">
        <Button
          onClick={() => {
            this.addOrEditPriv(row);
          }}
          className="button edit-button"
        >
          <i className="fa fa-edit" /> {t("common:edit")}
        </Button>
        <Button
          className="button delete-button"
          onClick={() => {
            this.submit(row);
          }}
        >
          <i className="fa fa-trash" /> {t("common:delete")}
        </Button>
      </div>
    );
    return edit;
  }

  PopOvertoggle(id) {
    var NewObject = Object.assign([], this.state.popoverOpen);
    NewObject[id] = !NewObject[id];
    this.setState({
      popoverOpen: NewObject
    });
  }

  addOrEditPriv(type) {
    const { t } = this.state;
    var title = t("addPriv");
    if (type != "new") {
      title = t("editPriv");
    }
    ModalManager.open(
      <MainModal
        responsive
        title={title}
        onRequestClose={() => true}
        component={
          <AddPriv success={this.addSuccess} type={type} t={this.state.t} />
        }
      />
    );
  }

  onConfirmDelete(deletID) {
    PrivilegeDelete(
      deletID,
      BaseRequest,
      this.onDeleteSuccess,
      this.onDeleteFailed,
      "DELETE"
    );
  }

  onDeleteSuccess(response) {
    const { t } = this.state;
    this.getList();
    return toast.error(t("common:SuccessDelete"));
  }

  onDeleteFailed(error) {
    this.setState({ loading: false, apierror: true, apierrormsg: error });
  }
  render() {
    const { t } = this.state;
    var table = {
      sizePerPage: this.state.sizePerPage, // neg huudsand haruulah too
      headerKeyColumn: {
        // end tulhuur baganiig zaana tsor gants bn
        title: t("privNo"), // Garchig der haragdana
        dataField: "PrivNo", // Table-s ireh talbariin ner
        dataSort: true, // Eremlehe eseh shiidne
        dataFormat: this.defaultRowFormat, // yamar formataar helberjihiig zaana
        hidden: false,
        filter: {
          type: "TextFilter",
          placeholder: "..."
        }
      },
      headerOtherColumn: [
        // end busad haruulah baganuudaa zoono
        {
          title: t("privName"),
          dataField: "PrivName",
          dataSort: false,
          dataFormat: this.defaultRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "..."
          }
        },
        {
          title: "",
          dataField: "PrivNo",
          dataSort: false,
          dataFormat: this.customRowFormat,
          hidden: false,
          width: "150px",
          filter: {
            type: "TextFilter",
            placeholder: "..."
          }
        }
      ]
    };
    const { apierror, apierrormsg, loading } = this.state;
    var bodyHere = "";
    const containerStyle = {
      zIndex: 1999,
      top: 40
    };
    bodyHere = (
      <div>
        {apierror && (
          <div className="help-block text-danger">{apierrormsg}</div>
        ) // serverees aldaa garval aldaanii medeelel haragdana
        }
        <div>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            style={containerStyle}
          />
          <CustomBootsrapTable
            data={this.state.data} // serverees irsen datag ogno
            table={table} // tabliin tohirgoog ogno
            PTotal={this.state.PTotal} // serverees avsan niit heden huudas bgaag ogno
            pageChange={this.onChangePage} // huudas soligdohod duudah function bn
            PID={this.state.PID} // huudaslatiin hed deh huudas ve
            loading={loading} // end true false damjuulj data avch ireh uid loading haruulna
          />
        </div>
      </div>
    );
    return (
      <div className="animated fadeIn">
        <Card className="nopadding">
          <CardHeader>
            {t("privList")}
            <div className="float-right">
              <button
                onClick={() => {
                  this.addOrEditPriv("new");
                }}
                className="btn btn-sm btn-primary"
              >
                {t("common:add")}
              </button>
            </div>
          </CardHeader>
          <CardBody>{bodyHere}</CardBody>
        </Card>
      </div>
    );
  }
}

export default withTranslation("priv")(PrivList);
