import React, { Component } from 'react'
import Login from './views/Login/Login.jsx';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Roting from './services/routing';
class App extends Component {
  render() {
    return (
      <div className='App'>
        <HashRouter>
          <Switch>
            <Route path='/login' name='Login' component={Login} />
            <Route path='/' name='Home' component={Roting} />
          </Switch>
        </HashRouter>
      </div>
    )
  }
}
export default App
