import React, { Component } from "react";
import { Nav, NavItem, NavbarToggler, Badge, Button, Input } from "reactstrap";
import { getRealData, isNullOrEmpty, TimeFormat } from "../Utils/Utils";
import { socket, CDN_URL, ChatAdd, ChatList } from "../../services/main";
import { ChatAddReq, ChatListReq } from "../../services/Request";
import sound from "./chat_alart.mp3";
import "./Chat.scss";
class ChatSystem extends Component {
  chatContainer = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      messageList: [],
      isOpen: false,
      t: this.props.t,
      message: "",
      newNotfiCount: 0,
      name: !isNullOrEmpty(getRealData("data2"))
        ? JSON.parse(getRealData("data2")).firstname.substr(0, 1) +
          "." +
          JSON.parse(getRealData("data2")).firstname
        : null,
    };
    this.audio = new Audio(sound);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    socket.on("chat", (message) => {
      const { messageList, isOpen, newNotfiCount } = this.state;
      var newNotfi = newNotfiCount;
      if (!isOpen) {
        newNotfi++;
      }
      messageList.push(message.message);
      this.setState({ messageList, newNotfiCount: newNotfi }, function () {
        this.audio.play();
        this.scrollToMyRef();
      });
    });
  }

  _onMessageWasSent(message) {
    this.setState(
      {
        messageList: [...this.state.messageList, message],
      },
      function () {
        socket.emit("chat", message);
        this.scrollToMyRef();
      }
    );
  }

  chatHandleClick = (e) => {
    this.setState({ isOpen: !this.state.isOpen, newNotfiCount: 0 });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit(e) {
    var text = this.state.message;
    var date = new Date();
    if (e.keyCode == 13 && text.length > 0) {
      this.setState({ message: "" }, function () {
        this._onMessageWasSent({
          sender: localStorage.getItem("EmployeeID"),
          sender_name: this.state.name,
          body: text,
          created_at: date.getHours() + ":" + date.getMinutes(),
        });
      });
    }
  }

  scrollToMyRef() {
    const scroll =
      this.chatContainer.current.scrollHeight -
      this.chatContainer.current.clientHeight;
    this.chatContainer.current.scrollTo(0, scroll);
  }

  render() {
    const { isOpen, message, t, messageList, newNotfiCount } = this.state;
    return (
      <div>
        <div className="chatOpenButton" onClick={this.chatHandleClick}>
          {isOpen ? (
            <span className="closeBtn fa fa-close" />
          ) : (
            <span className="chatBtn fa fa-send" />
          )}
          {newNotfiCount > 0 ? (
            <div className="newNotfi">
              <span>{newNotfiCount}</span>
            </div>
          ) : null}
        </div>
        <div id="chatbox" className={`${isOpen ? "show" : "hide"}`}>
          <div id="chatview" className="p1">
            <div id="chat-messages" ref={this.chatContainer}>
              {messageList.map((item, index) => {
                return (
                  <div
                    className={`${
                      item.sender === localStorage.getItem("EmployeeID")
                        ? "message right"
                        : "message"
                    }`}
                    key={index}
                  >
                    <img
                      src={CDN_URL + "/file/img/" + item.sender + ".png"}
                      style={{ width: 30 }}
                      onError={(e) =>
                        (e.target.src = require("../../img/no-image.png"))
                      }
                    />
                    <span className="name">{item.sender_name}</span>
                    <div className="bubble">
                      {item.body}
                      <div className="corner"></div>
                      <span>{item.created_at}</span>
                    </div>
                  </div>
                );
              })}
            </div>

            <div id="sendmessage">
              <Input
                type="text"
                placeholder="энд бичнэ үү..."
                name="message"
                value={message}
                onChange={(event) => {
                  this.handleChange(event);
                }}
                onKeyDown={this.handleSubmit}
                autoComplete="off"
              />
              <Button id="send"></Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ChatSystem;
