import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {
  ProductCranDelete,
  ProductCranList,
  setPageTitle,
  CDN_URL,
} from "../../services/main";
import {
  ProductCranListReq,
  BaseRequest,
  magicnum,
} from "../../services/Request";
import {
  Card,
  CardHeader,
  CardBody,
  UncontrolledAlert,
  Button,
  Label,
  Input,
  FormGroup,
  Row,
  Col,
} from "reactstrap";
import CustomBootsrapTable from "../../components/CustomBootsrapTable/CustomBootsrapTable";
import $ from "jquery";
import CranAddOrUpdate from "./CranAddOrUpdate";
import { ModalManager } from "react-dynamic-modal";
import MainModal from "../../components/Modal/MainModal/MainModal";
import { toast } from "react-toastify";
import { isNullOrEmpty, moneyFormat } from "../../components/Utils/Utils";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
class ProductCranListFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sizePerPage: 25, //neg huudsand haruulah too
      PID: 1,
      PTotal: 0,
      loading: false,
      data: [],
      t: this.props.t,
      name: "",
      apierrormsg: "",
      apierror: false,
      name: "",
      torol: "",
      code: "",
      brand: "",
      action_type: "",
      subtorolarray: [],
      subtorol: "",
    };
  }

  componentDidMount() {
    setPageTitle(this.props.t("product.cran.Title"));
    this.getList();
  }

  getList = () => {
    if (!this.state.loading) {
      this.setState({ loading: true });
    }

    const { PID, sizePerPage, name, code, brand, torol, subtorol } = this.state;
    var req = ProductCranListReq;
    req.pid = PID;
    req.pSize = sizePerPage;
    req.filter.name = name;
    req.filter.torol = torol;
    req.filter.code = code;
    req.filter.brand = brand;
    req.filter.subtorol = subtorol;
    ProductCranList(req, this.onSuccess, this.onFailed, "POST");
  };

  onSuccess = (response) => {
    this.setState({
      loading: false,
      PTotal: response.pagination.ptotal,
      data: response.data,
    });
  };

  onFailed = (error) => {
    this.setState({ loading: false, apierror: true, apierrormsg: error });
  };

  onChangePage = (pid) => {
    if (pid >= 1) {
      this.setState({ PID: pid, loading: true }, function () {
        this.getList();
      });
    }
  };

  filterToggle() {
    $("#filterTogle").toggle();
    var otherHeight = 160;
    var filterHeight = 0,
      othersapec = 65;
    if ($(".grid-filter").height() !== undefined) {
      filterHeight = $(".grid-filter").height() + othersapec;
    }
    $(".scroll").height(
      $(window).height() -
        $(".app-header").height() -
        otherHeight -
        filterHeight
    );
  }

  add = (type, row) => {
    const { t } = this.state;
    var title = t("product.cran.Add");
    if (type == "update") {
      title = t("product.cran.Update");
    }
    ModalManager.open(
      <MainModal
        responsive
        title={title}
        onRequestClose={() => true}
        component={
          <CranAddOrUpdate
            t={this.state.t}
            item={row}
            addSuccess={this.addSuccess}
          />
        }
      />
    );
  };

  addSuccess = (text) => {
    this.getList();
    return toast.success(text);
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, function () {
      if (name == "torol") {
        this.buildSubTorol();
      }
    });
  };

  search = () => {
    this.setState({ PID: 1 }, function () {
      this.getList();
    });
  };

  torolRowFormat = (cell, row) => {
    const { t } = this.state;
    return <span>{t(`common:magicnum.ProductCranTorol.${cell}`)}</span>;
  };

  actionRowFormat = (cell, row) => {
    const { t } = this.state;
    let edit = (
      <div className="text-center" style={{ marginLeft: -12 }}>
        <Button
          onClick={() => {
            this.add("update", row);
          }}
          className="button edit-button"
          title={t("common:edit")}
          color="success"
          outline
        >
          <i className="fa fa-edit" />
        </Button>
        <Button
          className="button delete-button"
          onClick={() => {
            this.submit(row, "deleteMain");
          }}
          title={t("common:delete")}
          color="success"
          outline
        >
          <i className="fa fa-trash" />
        </Button>
      </div>
    );
    return edit;
  };

  nameRowFormat = (cell, row) => {
    let edit = (
      <Link to={`/product-cran/${row.id}`}>
        {cell}
        {isNullOrEmpty(row.price) || row.price == 0
          ? ""
          : " - (" + moneyFormat(row.price, "MNT") + ")"}
      </Link>
    );
    return edit;
  };

  submit = (row, type) => {
    const { t } = this.state;
    var title = row.name + " " + t("common:delete");
    var message = t("common:confirmdelete");
    if (type == "confirm") {
      var title = row.name + " " + t("product.cran.confirm");
      var message = t("common:confirm");
    } else if (type == "delete") {
      var title = row.name + " " + t("product.cran.delete");
      var message = t("common:confirm");
    }

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-confirm-ui">
            <h1>{title.substr(0, 30)}...</h1>
            <p>{message}</p>
            {type == "confirm" ? (
              <Input
                type="select"
                name="action_type"
                onChange={(event) => {
                  this.handleChange(event);
                }}
                style={{ marginTop: 20, marginBottom: 40 }}
              >
                <option value="">{t("common:choose")}</option>
                {magicnum.ProductTatlagaTolov.map((item, index) => {
                  return (
                    <option value={item} key={index}>
                      {t(`common:magicnum.ProductTatlagaTolov.${item}`)}
                    </option>
                  );
                })}
              </Input>
            ) : null}
            <Button outline onClick={onClose}>
              {t("common:no")}
            </Button>
            <Button
              color="danger"
              onClick={() => {
                type == "deleteMain" ? this.delete(row) : this.Confirm(row);
                onClose();
              }}
            >
              {t("common:yes")}
            </Button>
          </div>
        );
      },
    });
  };

  delete = (row) => {
    ProductCranDelete(
      row.id,
      BaseRequest,
      this.onDeleteSuccess,
      this.onFailed,
      "DELETE"
    ); //serverluu huselt ilgeej bn  !!!!!
  };

  onDeleteSuccess = (response) => {
    const { t } = this.state;
    this.getList();
    return toast.error(t("common:SuccessDelete"));
  };

  imgRowFormat(cell, row) {
    var newName = cell.split(".");
    return (
      <div className="text-center">
        <img
          src={CDN_URL + newName[1] + "-thumb." + newName[2]}
          height={40}
          width={40}
          style={{ objectFit: "cover" }}
          onError={(e) => (e.target.src = require("../../img/no-image.png"))}
        />
      </div>
    );
  }

  brandRowFormat = (cell, row) => {
    const { t } = this.state;
    let edit = (
      <div>
        <span>{t(`common:magicnum.ProductBrand.${cell}`)} </span>
      </div>
    );
    return edit;
  };

  render() {
    const {
      t,
      apierror,
      apierrormsg,
      torol,
      brand,
      name
    } = this.state;
    var table = {
      sizePerPage: this.state.sizePerPage,
      headerKeyColumn: {
        title: "id",
        dataField: "id",
        dataSort: true,
        dataFormat: this.defaultRowFormat,
        hidden: true,
        filter: {
          type: "TextFilter",
          placeholder: "...",
        },
      },
      headerOtherColumn: [
        {
          title: t("product.cran.image"),
          dataField: "image_link",
          dataSort: false,
          dataFormat: this.imgRowFormat,
          hidden: false,
          width: "100px",
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("product.cran.name"),
          dataField: "name",
          dataSort: false,
          dataFormat: this.nameRowFormat,
          hidden: false,
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("product.cran.torol"),
          dataField: "torol",
          dataSort: false,
          dataFormat: this.torolRowFormat,
          hidden: false,
          width: "150px",
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: t("product.brand"),
          dataField: "brand",
          dataSort: false,
          dataFormat: this.brandRowFormat,
          hidden: false,
          width: "230px",
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
        {
          title: "",
          dataField: "id",
          dataSort: false,
          dataFormat: this.actionRowFormat,
          hidden: false,
          width: "120px",
          filter: {
            type: "TextFilter",
            placeholder: "...",
          },
        },
      ],
    };
    return (
      <div className="animated fadeIn">
        <Card className="grid-filter">
          <CardHeader>
            <span className="filterToggle" onClick={() => this.filterToggle()}>
              <i className="fa fa-filter" />
              {t("common:filter")}
            </span>
            <div className="float-right">
              <span className="btn btn-sm btn-primary" onClick={this.search}>
                {t("common:search")}
              </span>
            </div>
          </CardHeader>
          <CardBody id="filterTogle" style={{ display: "none" }}>
            <div className="filter">
              <Row>
                <Col>
                  <FormGroup>
                    <Label>{t("product.cran.name")}</Label>
                    <Input
                      type="text"
                      name="name"
                      value={name}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col>
                  <FormGroup>
                    <Label>{t("product.cran.torol")}</Label>
                    <Input
                      type="select"
                      name="torol"
                      value={torol}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    >
                      <option value="">{t("common:choose")}</option>
                      {magicnum.ProductCranTorol.map((item, index) => {
                        return (
                          <option value={item} key={index}>
                            {t(`common:magicnum.ProductCranTorol.${item}`)}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>

                <Col>
                  <FormGroup>
                    <Label>{t("product.brand")}</Label>
                    <Input
                      type="select"
                      name="brand"
                      value={brand}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    >
                      <option value="">{t("common:choose")}</option>
                      {magicnum.ProductBrand.map((item, index) => {
                        return (
                          <option value={item} key={index}>
                            {t(`common:magicnum.ProductBrand.${item}`)}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>

        <Card className="nopadding">
          <CardHeader>
            {t("product.cran.Title")}
            <div className="float-right">
              <button
                onClick={() => {
                  this.add("new", null);
                }}
                className="btn btn-sm btn-primary"
              >
                {t("common:add")}
              </button>
            </div>
          </CardHeader>
          <CardBody>
            <div>
              {apierror && (
                <UncontrolledAlert color="danger">
                  {apierrormsg}
                </UncontrolledAlert>
              )}
              <div>
                <CustomBootsrapTable
                  data={this.state.data}
                  table={table}
                  PTotal={this.state.PTotal}
                  pageChange={this.onChangePage}
                  PID={this.state.PID}
                  loading={this.state.loading}
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withTranslation("main")(ProductCranListFunc);
